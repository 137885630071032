import {
  getProtectiveMeasuresRequesteds,
  getProtectiveMeasureRequestedById,
  createProtectiveMeasureRequested,
  updateProtectiveMeasureRequested,
  destroyProtectiveMeasureRequested,
} from "../../service/ProtectiveMeasuresRequestedsApi";
import { getProtectiveMeasuresTypes } from "../../service/ProtectiveMeasureTypesApi";
import { getProtectiveMeasures } from "../../service/ProtectiveMeasuresApi";
import Vue from "vue";

const state = {
  id: null,
  protective_measure_id: 0,
  protectiveMeasureRequested: [],
  protectiveMeasureRequestedId: 0,
  protective_measure: null,
  protective_measure_type: null,
  protective_measures: [],
  protective_measures_types: [],
  protective_measures_requesteds: [],
  filterProtectiveMeasuresRequesteds: [],
  protectiveMeasureRequestedToUpdate: {
    id: null,
    proctetive_measure: null,
    incident_report: null,
    protective_measure_type: null,
    requested: false,
  },
  modalToggleProtectiveMeasuresRequesteds: false,
  modalToggleEditProtectiveMeasuresRequesteds: false,
  modalConfirmDeleteProtectiveMeasureRequested: false,
  params: {
    protective_measure_number: null,
    incident_report_number: "",
    order_by: "",
    limit: "",
    offset: "",
    page: "",
    paginate: true,
  },
  // - PARA PAGINACAO
  totalFilter: 0,
  totalPerPage: 10,
  offsetFilter: 0,
  totalFilterPages: 0,
  pagesFilter: [],
  actualPageFilter: 1,
};

const mutations = {
  // - PARA PAGINACAO --->
  setTotalFilterMutation: (state, total) => {
    state.totalFilter = total;
    let nrPages = total / state.totalPerPage;
    if (total % state.totalPerPage > 0) {
      nrPages = parseInt(nrPages) + 1;
    }
    state.totalFilterPages = nrPages;
    let offset = 0;
    state.pagesFilter = [];
    for (let n = 1; n <= nrPages; n++) {
      let pagina = {
        id: n - 1,
        name: "Página " + n + " de " + nrPages,
        number: n,
        offset: offset,
        page: n,
      };
      state.pagesFilter.push(pagina);
      offset += state.totalPerPage;
    }
  },
  setActualPageFilterMutation: (state, page) => {
    state.actualPageFilter = page;
  },
  setOffsetFilterMutation: (state, offset) => {
    state.offsetFilter = offset;
  },
  // <--- PARA PAGINACAO - FIM

  setProtectiveMeasuresRequestedsMutation: (state, protective_measures_requesteds) => {
    state.protective_measures_requesteds = protective_measures_requesteds;
    state.filterProtectiveMeasuresRequesteds = protective_measures_requesteds;
  },
  setProtectiveMeasuresTypesMutation: (state, protective_measures_types) => {
    state.protective_measures_types = protective_measures_types;
  },
  setProtectiveMeasuresMutation: (state, protective_measures) => {
    state.protective_measures = protective_measures;
  },
  filterListByNumberProtectiveMeasure: (state, input) => {
    // let protective_measures_requesteds = state.protective_measures_requesteds;
    // state.filterProtectiveMeasuresRequesteds = protective_measures_requesteds.filter((obj) => {
    //   let number = obj.protective_measure.number.toLowerCase().indexOf(input.value.toLowerCase());
    //   return number > -1;
    // });
    state.params.protective_measure_number = input.value;
  },
  filterListByNumberIncidentReport: (state, input) => {
    // let protective_measures_requesteds = state.protective_measures_requesteds;
    // state.filterProtectiveMeasuresRequesteds = protective_measures_requesteds.filter((obj) => {
    //   let number = obj.incident_report.number.toLowerCase().indexOf(input.value.toLowerCase());
    //   return number > -1;
    // });
    state.params.incident_report_number = input.value;
  },
  toggleModalProtectiveMeasuresRequesteds: (state) => {
    state.modalToggleProtectiveMeasuresRequesteds = !state.modalToggleProtectiveMeasuresRequesteds;
  },
  toggleModalDeleteProtectiveMeasureRequested: (state) => {
    state.modalConfirmDeleteProtectiveMeasureRequested = !state.modalConfirmDeleteProtectiveMeasureRequested;
  },
  toggleModalEditProtectiveMeasureRequested: (state) => {
    state.modalToggleEditProtectiveMeasuresRequesteds = !state.modalToggleEditProtectiveMeasuresRequesteds;
  },
  protectiveMeasureRequestedForDeletion: (state, payload) => {
    state.protectiveMeasureRequestedId = payload.id;
  },
  setIdMutation: (state, id) => {
    state.id = id;
  },
  setProtectiveMeasureMutation: (state, protective_measure) => {
    state.protectiveMeasureRequested = [];
    state.protective_measure_id = protective_measure.id;
    state.protective_measure = protective_measure;
  },
  setProtectiveMeasureTypeMutation: (state, protective_measure_type) => {
    state.protectiveMeasureRequested.protective_measure_type_id = protective_measure_type.id;
    state.protective_measure_type = protective_measure_type;
  },
  setprotectiveMeasureRequestedMutation: (state, protectiveMeasureRequested) => {
    state.protectiveMeasureRequested = protectiveMeasureRequested;
  },
  setProtectiveMeasureRequestedArrayMutation: (state, payload) => {
    if (payload.requested) {
      state.protectiveMeasureRequested.forEach((item) => {
        if (item.protective_measure_type_id == payload.data.id) {
          item.requested = true;
        }
      });
    } else {
      state.protectiveMeasureRequested.forEach((item) => {
        if (item.protective_measure_type_id == payload.data.id) {
          item.requested = false;
        }
      });
    }
    // console.log("array resultado", state.protectiveMeasureRequested);
  },
  setIdToUpdateMutation: (state, id) => {
    state.protectiveMeasureRequestedToUpdate.id = id;
  },
  setProctetiveMeasureToUpdateMutation: (state, protective_measure) => {
    state.protectiveMeasureRequestedToUpdate.protective_measure = protective_measure;
  },
  setIncidentReportToUpdateMutation: (state, incident_report) => {
    state.protectiveMeasureRequestedToUpdate.incident_report = incident_report;
  },
  setProtectiveMeasureTypeToUpdateMutation: (state, protective_measure_type) => {
    state.protectiveMeasureRequestedToUpdate.protective_measure_type = protective_measure_type;
  },
  setRequestedToUpdateMutation: (state, requested) => {
    state.protectiveMeasureRequestedToUpdate.requested = requested;
  },
  clearQuery: (state) => {
    state.protective_measures_requesteds = [];
    state.filterProtectiveMeasuresRequesteds = [];
    state.params.protective_measure_number = null;
    state.params.incident_report_number = null;

    state.params.order_by = "";
    state.params.limit = "";
    state.params.offset = "";

    state.params.page = "";

    state.totalFilter = 0;
    state.totalPerPage = 10;
    state.offsetFilter = 0;
    state.totalFilterPages = 0;
    state.pagesFilter = [];
    state.actualPageFilter = 1;
  },
  clearFields: (state) => {
    state.id = null;
    state.protectiveMeasureRequested = [];
    state.protectiveMeasureRequestedId = 0;
    state.protective_measure = null;
    state.protective_measure_type = null;
  },
};

const actions = {
  // --- PAGINAÇÃO >>>>>>>>>
  getPageFilter: ({ state, commit }, payload) => {
    if (payload != null) {
      const params = {
        protective_measure_number: state.params.protective_measure_number,
        incident_report_number: state.params.incident_report_number,
        order_by: "incident_reports.number DESC",
        limit: state.totalPerPage,
        offset: payload.offset,
        page: payload.page,
        paginate: true,
      };
      getProtectiveMeasuresRequesteds(params)
        .then((response) => {
          commit("setProtectiveMeasuresRequestedsMutation", response.data.protective_measure_requesteds);
          commit("setTotalFilterMutation", response.data.paginate.total_count);
          commit("setActualPageFilterMutation", payload.number);
        })
        .catch((e) => {
          console.log("error", e.response); //TODO: tratar esse error
        });
    }
  },
  setOffsetFilter: ({ commit }, payload) => {
    commit("setOffsetFilterMutation", payload);
  },
  // --- PAGINAÇÃO <<<<<<<<<

  loadProtectiveMeasuresRequesteds: ({ commit }, from) => {
    commit("setOffsetFilterMutation", 0);
    const params = {
      protective_measure_number: state.params.protective_measure_number,
      incident_report_number: state.params.incident_report_number,
      order_by: "incident_reports.number DESC",
      limit: state.totalPerPage,
      offset: state.offsetFilter,
      paginate: true,
    };
    getProtectiveMeasuresRequesteds(params)
      .then((response) => {
        // console.log("loadProtectiveMeasuresRequesteds", response);
        commit("setProtectiveMeasuresRequestedsMutation", response.data.protective_measure_requesteds);
        commit("setTotalFilterMutation", response.data.paginate.total_count);
        commit("setActualPageFilterMutation", 1);
        if (parseInt(response.data.total, 10) < 1 && from != "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadProtectiveMeasuresTypes: ({ commit }) => {
    getProtectiveMeasuresTypes()
      .then((response) => {
        let arr = [];
        let types = response.data.protective_measure_types;
        types.forEach((item) => {
          let js = {
            protective_measure_id: state.protective_measure_id,
            protective_measure_type_id: item.id,
            requested: false,
          };
          arr.push(js);
        });
        commit("setProtectiveMeasuresTypesMutation", response.data.protective_measure_types);
        commit("setprotectiveMeasureRequestedMutation", arr);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadProtectiveMeasures: ({ commit }) => {
    const params = null;
    getProtectiveMeasures(params)
      .then((response) => {
        commit("setProtectiveMeasuresMutation", response.data.protective_measures);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadDataToUpdate: ({ commit }, payload) => {
    commit("setIdToUpdateMutation", payload.id);
    commit("setProctetiveMeasureToUpdateMutation", payload.protective_measure);
    commit("setIncidentReportToUpdateMutation", payload.incident_report);
    commit("setProtectiveMeasureTypeToUpdateMutation", payload.protective_measure_type);
    commit("setRequestedToUpdateMutation", payload.setRequestedToUpdateMutation);
  },
  filterListByNumberProtectiveMeasure: ({ commit }, payload) => {
    commit("filterListByNumberProtectiveMeasure", payload);
  },
  filterListByNumberIncidentReport: ({ commit }, payload) => {
    commit("filterListByNumberIncidentReport", payload);
  },
  toggleModalProtectiveMeasuresRequesteds: ({ commit }) => {
    commit("toggleModalProtectiveMeasuresRequesteds");
  },
  openEditModal: ({ commit, dispatch }, payload) => {
    getProtectiveMeasureRequestedById(payload.id)
      .then((response) => {
        console.log("response", response);
        dispatch("loadDataToUpdate", response.data.protective_measure_requested);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
    commit("toggleModalEditProtectiveMeasureRequested");
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  closeEditModal: ({ commit }) => {
    commit("toggleModalEditProtectiveMeasureRequested");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalProtectiveMeasuresRequesteds");
    commit("clearFields");
  },
  setId: ({ commit }, payload) => {
    commit("setIdMutation", payload);
  },
  setProtectiveMeasure: ({ commit }, payload) => {
    commit("setProtectiveMeasureMutation", payload);
  },
  setProtectiveMeasureType: ({ commit }, payload) => {
    commit("setProtectiveMeasureTypeMutation", payload);
  },
  setProtectiveMeasureRequestedArray: ({ commit }, payload) => {
    commit("setProtectiveMeasureRequestedArrayMutation", payload);
  },
  setRequestedToUpdate: ({ commit }, payload) => {
    commit("setRequestedToUpdateMutation", payload.value);
  },
  toggleModalDeleteProtectiveMeasureRequested: ({ commit }, payload) => {
    commit("toggleModalDeleteProtectiveMeasureRequested");
    if (state.modalConfirmDeleteProtectiveMeasureRequested) {
      commit("protectiveMeasureRequestedForDeletion", payload);
    } else {
      commit("protectiveMeasureRequestedForDeletion", null);
    }
  },
  save: ({ commit, dispatch }) => {
    // console.log("this.enviando", state.protectiveMeasureRequested);
    createProtectiveMeasureRequested(state.protectiveMeasureRequested)
      .then(() => {
        // console.log("Create Incident Report", response);
        Vue.$toast.success("Medida Protetiva Requerida cadastrada com sucesso!");
        commit("toggleModalProtectiveMeasuresRequesteds");
        commit("clearFields");
        dispatch("ProtectiveMeasuresRequesteds/loadProtectiveMeasuresRequesteds", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  update: ({ dispatch, commit }) => {
    updateProtectiveMeasureRequested(
      state.protectiveMeasureRequestedToUpdate.id,
      state.protectiveMeasureRequestedToUpdate
    )
      .then(() => {
        Vue.$toast.success("Medida Protetiva Requerida atualizada com sucesso!");
        commit("toggleModalEditProtectiveMeasureRequested");
        dispatch("ProtectiveMeasuresRequesteds/loadProtectiveMeasuresRequesteds", null, { root: true });
        commit("clearFields");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyProtectiveMeasureRequested(state.protectiveMeasureRequestedId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Medida Protetiva Requerida foi deletada com sucesso");
        commit("toggleModalDeleteProtectiveMeasureRequested");
        dispatch("ProtectiveMeasuresRequesteds/loadProtectiveMeasuresRequesteds", from, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
