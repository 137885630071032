import { getHandcaps } from "../../service/HandcapsApi";

const state = {
  item: null,
  list: null,
};

const mutations = {
  setHandcapsMutation: (state, list) => {
    state.list = list;
  },
  setHandcapMutation: (state, item) => {
    state.item = item;
  },
};

const actions = {
  loadHandcaps: ({ commit }) => {
    getHandcaps()
      .then((response) => {
        console.log("handcaps", response);
        commit("setHandcapsMutation", response.data.handicap_types);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  setHandcaps: ({ commit }, payload) => {
    commit("setHandcapMutation", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
