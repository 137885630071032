<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body {
  height: auto;
  font-family: "Ubuntu";
}
</style>
