import {
  getAccompanimentTypeFacilities,
  getAccompanimentTypeFacilityById,
  createAccompanimentTypeFacility,
  updateAccompanimentTypeFacility,
  destroyAccompanimentTypeFacility,
} from "../../service/AccompanimentTypeFacilitiesApi";
import { getAccompanimentTypes } from "../../service/AccompanimentTypesApi";
import Vue from "vue";

const state = {
  accompanimentTypeFacility: {
    id: null,
    facility_id: 0,
    accompaniment_type_id: 0,
  },
  // filters: {
  //   name_facility: "",
  //   accompaniment_type: "",
  // },
  facility: null,
  accompaniment_type_facilities: [],
  accompaniment_types: [],
  accompaniment_type: null,
  filterAccompanimentTypeFacilities: [],
  accompanimentTypeFacilityId: 0,
  modalToggleAccompanimentTypeFacilities: false,
  modalConfirmDeleteAccompanimentTypeFacility: false,
  modalViewAccompanimentTypeFacility: false,

  params: {
    facility_name: "",
    accompaniment_type_name: "",
    order_by: "",
    limit: "",
    offset: "",
    page: "",
    paginate: true,
  },

  // - PARA PAGINACAO
  totalFilter: 0,
  totalPerPage: 10,
  offsetFilter: 0,
  totalFilterPages: 0,
  pagesFilter: [],
  actualPageFilter: 1,
};

const mutations = {
  // - PARA PAGINACAO --->
  setTotalFilterMutation: (state, total) => {
    state.totalFilter = total;
    let nrPages = total / state.totalPerPage;
    if (total % state.totalPerPage > 0) {
      nrPages = parseInt(nrPages) + 1;
    }
    state.totalFilterPages = nrPages;
    let offset = 0;
    state.pagesFilter = [];
    for (let n = 1; n <= nrPages; n++) {
      let pagina = {
        id: n - 1,
        name: "Página " + n + " de " + nrPages,
        number: n,
        page: n,
        offset: offset,
      };
      state.pagesFilter.push(pagina);
      offset += state.totalPerPage;
    }
  },
  setActualPageFilterMutation: (state, page) => {
    state.actualPageFilter = page;
  },
  setOffsetFilterMutation: (state, offset) => {
    state.offsetFilter = offset;
  },
  // <--- PARA PAGINACAO - FIM

  setAccompanimentTypeFacilitiesMutation: (state, accompaniment_type_facilities) => {
    state.accompaniment_type_facilities = accompaniment_type_facilities;
    state.filterAccompanimentTypeFacilities = accompaniment_type_facilities;
  },
  setAccompanimentTypesMutation: (state, accompaniment_types) => {
    state.accompaniment_types = accompaniment_types;
  },
  filterListByNameFacility: (state, input) => {
    // let accompaniment_type_facilities = state.accompaniment_type_facilities;
    // state.filterAccompanimentTypeFacilities = accompaniment_type_facilities.filter((obj) => {
    //   let name = obj.facility.name.toLowerCase().indexOf(input.value.toLowerCase());
    //   return name > -1;
    // });
    state.params.facility_name = input.value;
  },
  filterListByAccompanimentType: (state, input) => {
    // let accompaniment_type_facilities = state.accompaniment_type_facilities;
    // state.filterAccompanimentTypeFacilities = accompaniment_type_facilities.filter((obj) => {
    //   let name = obj.accompaniment_type.name.toLowerCase().indexOf(input.value.toLowerCase());
    //   return name > -1;
    // });
    state.params.accompaniment_type_name = input.value;
  },
  toggleModalToggleAccompanimentTypeFacilities: (state) => {
    state.modalToggleAccompanimentTypeFacilities = !state.modalToggleAccompanimentTypeFacilities;
  },
  toggleModalDeleteAccompanimentTypeFacility: (state) => {
    state.modalConfirmDeleteAccompanimentTypeFacility = !state.modalConfirmDeleteAccompanimentTypeFacility;
  },
  toggleModalViewAccompanimentTypeFacility: (state) => {
    state.modalViewAccompanimentTypeFacility = !state.modalViewAccompanimentTypeFacility;
  },
  accompanimentTypeFacilityForDeletion: (state, payload) => {
    state.accompanimentTypeFacilityId = payload.id;
  },
  setIdMutation: (state, id) => {
    state.accompanimentTypeFacility.id = id;
  },
  setFacilityMutation: (state, facility) => {
    state.accompanimentTypeFacility.facility_id = facility.id;
    state.facility = facility;
  },
  setAccompanimentTypeMutation: (state, accompaniment_type) => {
    state.accompanimentTypeFacility.accompaniment_type_id = accompaniment_type.id;
    state.accompaniment_type = accompaniment_type;
  },
  clearQuery: (state) => {
    state.accompaniment_type_facilities = [];
    state.filterAccompanimentTypeFacilities = [];
    state.params.facility_name = "";
    state.params.accompaniment_type_name = "";

    state.params.order_by = "";
    state.params.limit = "";
    state.params.offset = "";

    state.params.page = "";

    state.totalFilter = 0;
    state.totalPerPage = 10;
    state.offsetFilter = 0;
    state.totalFilterPages = 0;
    state.pagesFilter = [];
    state.actualPageFilter = 1;
  },
  clearFields: (state) => {
    state.accompanimentTypeFacility.id = null;
    state.facility = null;
    state.accompaniment_type = null;
  },
};

const actions = {
  // --- PAGINAÇÃO >>>>>>>>>
  getPageFilter: ({ state, commit }, payload) => {
    if (payload != null) {
      const params = {
        facility_name: state.params.facility_name,
        accompaniment_type_name: state.params.accompaniment_type_name,
        order_by: "facilities.name DESC",
        limit: state.totalPerPage,
        offset: payload.offset,
        page: payload.page,
        paginate: true,
      };
      getAccompanimentTypeFacilities(params)
        .then((response) => {
          commit("setAccompanimentTypeFacilitiesMutation", response.data.accompaniment_type_facilities);
          commit("setTotalFilterMutation", response.data.paginate.total_count);
          commit("setActualPageFilterMutation", payload.number);
        })
        .catch((e) => {
          console.log("error", e.response); //TODO: tratar esse error
        });
    }
  },
  setOffsetFilter: ({ commit }, payload) => {
    commit("setOffsetFilterMutation", payload);
  },
  // --- PAGINAÇÃO <<<<<<<<<

  loadAccompanimentTypeFacilities: ({ commit }, from) => {
    commit("setOffsetFilterMutation", 0);
    const params = {
      facility_name: state.params.facility_name,
      accompaniment_type_name: state.params.accompaniment_type_name,
      order_by: "facilities.name DESC",
      limit: state.totalPerPage,
      offset: state.offsetFilter,
      paginate: true,
    };
    getAccompanimentTypeFacilities(params)
      .then((response) => {
        commit("setAccompanimentTypeFacilitiesMutation", response.data.accompaniment_type_facilities);
        commit("setTotalFilterMutation", response.data.paginate.total_count);
        commit("setActualPageFilterMutation", 1);
        if (parseInt(response.data.total, 10) < 1 && from != "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadAccompanimentTypes: ({ commit }) => {
    getAccompanimentTypes()
      .then((response) => {
        // console.log("getAccompanimentTypeFacilities", response);
        commit("setAccompanimentTypesMutation", response.data.accompaniment_types);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  filterListByNameFacility: ({ commit }, payload) => {
    commit("filterListByNameFacility", payload);
  },
  filterListByAccompanimentType: ({ commit }, payload) => {
    commit("filterListByAccompanimentType", payload);
  },
  toggleModalToggleAccompanimentTypeFacilities: ({ commit }) => {
    commit("toggleModalToggleAccompanimentTypeFacilities");
  },
  loadData: ({ commit }, payload) => {
    commit("setIdMutation", payload.id);
    commit("setFacilityMutation", payload.facility);
    commit("setAccompanimentTypeMutation", payload.accompaniment_type);
  },
  openViewModal: ({ commit, dispatch }, payload) => {
    getAccompanimentTypeFacilityById(payload.id)
      .then((response) => {
        dispatch("loadData", response.data.accompaniment_type_facility);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
    commit("toggleModalViewAccompanimentTypeFacility");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewAccompanimentTypeFacility");
    commit("clearFields");
  },
  openEditModal: ({ commit, dispatch }, payload) => {
    getAccompanimentTypeFacilityById(payload.id)
      .then((response) => {
        dispatch("loadData", response.data.accompaniment_type_facility);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
    commit("toggleModalToggleAccompanimentTypeFacilities");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalToggleAccompanimentTypeFacilities");
    commit("clearFields");
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  toggleModalDeleteAccompanimentTypeFacility: ({ commit }, payload) => {
    commit("toggleModalDeleteAccompanimentTypeFacility");
    if (state.modalConfirmDeleteAccompanimentTypeFacility) {
      commit("accompanimentTypeFacilityForDeletion", payload);
    } else {
      commit("accompanimentTypeFacilityForDeletion", null);
    }
  },
  setId: ({ commit }, payload) => {
    commit("setIdMutation", payload);
  },
  setFacility: ({ commit }, payload) => {
    commit("setFacilityMutation", payload);
  },
  setAccompanimentType: ({ commit }, payload) => {
    commit("setAccompanimentTypeMutation", payload);
  },
  save: ({ commit, dispatch }) => {
    createAccompanimentTypeFacility(state.accompanimentTypeFacility)
      .then(() => {
        // console.log("Create Incident Report", response);
        Vue.$toast.success("Tipo de Acompanhamento por Equipamento cadastrado com sucesso!");
        commit("toggleModalToggleAccompanimentTypeFacilities");
        commit("clearFields");
        dispatch("AccompanimentTypeFacilities/loadAccompanimentTypeFacilities", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar o Tipo de Acompanhamento por Equipamento" +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );
        console.log(e);
      });
  },
  update: ({ dispatch, commit }) => {
    updateAccompanimentTypeFacility(state.accompanimentTypeFacility.id, state.accompanimentTypeFacility)
      .then(() => {
        Vue.$toast.success("Tipo de Acompanhamento por Equipamento cadastrado com sucesso!");
        commit("toggleModalToggleAccompanimentTypeFacilities");
        dispatch("AccompanimentTypeFacilities/loadAccompanimentTypeFacilities", null, { root: true });
        commit("clearFields");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyAccompanimentTypeFacility(state.accompanimentTypeFacilityId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Tipo de Acompanhamento por Equipamento deletado com sucesso");
        commit("toggleModalDeleteAccompanimentTypeFacility");
        dispatch("AccompanimentTypeFacilities/loadAccompanimentTypeFacilities", from, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
