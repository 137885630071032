import {
  getForwardingProtocols,
  getForwardingProtocolId,
  createForwardingProtocol,
  updateForwardingProtocol,
  destroyForwardingProtocol,
} from "../../service/ForwardingProtocolsApi";
import { getAccompanimentTypes } from "../../service/AccompanimentTypesApi";
import { getAccompanimentTypeFacilityByType } from "../../service/AccompanimentTypeFacilitiesApi";
import { getSourceSystems } from "../../service/SourceSystemsApi";
import { getStates } from "../../service/StatesApi";
import { getCitiesByStateId } from "../../service/CityApi";
import { getNeighborhoodsByCityId } from "../../service/NeighborhoodsApi";
import Vue from "vue";

const state = {
  accompaniment_type_facility_id: null,
  forwardingProtocol: {
    id: null,
    person_id: 0,
    facility_id: 0,
    accompaniment_type_id: 0,
    source_system_id: 0,
    observation: "",
    forwarding_date: "",
  },
  params: {
    person_name: "",
    facility_name: "",
    accompaniment_type_name: "",
    order_by: "",
    limit: "",
    offset: "",
    page: "",
    paginate: true,
  },
  states: [],
  cities: [],
  neighborhoods: [],
  stateFiltered: null,
  cityFiltered: null,
  neighborhoodFiltered: null,
  accompaniment_type: null,
  accompaniment_types: null,
  person: null,
  accompaniment_type_facility: null,
  source_system: null,
  source_systems: [],
  accompaniment_types_facilities: [],
  forwarding_protocols: [],
  filterForwardingProtocols: [],
  forwardingProtocolId: 0,
  modalToggleForwardingProtocols: false,
  modalConfirmDeleteForwardingProtocol: false,
  modalViewForwardingProtocol: false,
  // - PARA PAGINACAO
  totalFilter: 0,
  totalPerPage: 10,
  offsetFilter: 0,
  totalFilterPages: 0,
  pagesFilter: [],
  actualPageFilter: 1,
};

const mutations = {
  updateFilterByIdMutation: (state, mutation) => {
    let key = state.filterForwardingProtocols.findIndex((item) => {
      return mutation.id === item.id;
    });
    state.filterForwardingProtocols.splice(key, 1);
    state.filterForwardingProtocols.splice(key, 0, mutation);
    //commit("clearFields");
  },
  // PAGINAÇÃO VERSÃO 2
  setPaginateMutation: (state, paginate) => {
    state.paginate = paginate;
  },
  // --- PAGINAÇÃO >>>>>>>>>
  setTotalFilterMutation: (state, total) => {
    state.totalFilter = total;
    let nrPages = total / state.totalPerPage;
    if (total % state.totalPerPage > 0) {
      nrPages = parseInt(nrPages) + 1;
    }
    state.totalFilterPages = nrPages;
    let offset = 0;
    state.pagesFilter = [];
    for (let n = 1; n <= nrPages; n++) {
      let pagina = {
        id: n - 1,
        name: "Página " + n + " de " + nrPages,
        number: n,
        offset: offset,
        page: n,
      };
      state.pagesFilter.push(pagina);
      offset += state.totalPerPage;
    }
  },
  setActualPageFilterMutation: (state, page) => {
    state.actualPageFilter = page;
  },
  setOffsetFilterMutation: (state, offset) => {
    state.offsetFilter = offset;
  },
  // --- PAGINAÇÃO <<<<<<<<<
  setForwardingProtocolsMutation: (state, forwarding_protocols) => {
    state.forwarding_protocols = forwarding_protocols;
    state.filterForwardingProtocols = forwarding_protocols;
  },
  setAccompanimentTypesMutation: (state, accompaniment_types) => {
    state.accompaniment_types = accompaniment_types;
  },
  setAccompanimentTypeFacilitiesMutation: (state, accompaniment_types_facilities) => {
    state.accompaniment_types_facilities = accompaniment_types_facilities;
  },
  setSourceSystemsMutation: (state, source_systems) => {
    state.source_systems = source_systems;
  },
  setForwardingDateMutation: (state, forwarding_date) => {
    state.forwardingProtocol.forwarding_date = forwarding_date;
  },
  setStatesMutation: (state, states) => {
    state.states = states;
  },
  setStateMutation: (state, stateFiltered) => {
    state.stateFiltered = stateFiltered;
  },
  setCitiesMutation: (state, cities) => {
    state.cities = cities;
  },
  setCityMutation: (state, cityFiltered) => {
    state.cityFiltered = cityFiltered;
  },
  setNeighborhoodsMutation: (state, neighborhoods) => {
    state.neighborhoods = neighborhoods;
  },
  setNeighborhoodMutation: (state, neighborhood) => {
    state.neighborhoodFiltered = neighborhood;
  },
  filterListByNamePerson: (state, input) => {
    // let forwarding_protocols = state.forwarding_protocols;
    // state.filterForwardingProtocols = forwarding_protocols.filter((obj) => {
    //   let name = obj.person.name.toLowerCase().indexOf(input.value.toLowerCase());
    //   return name > -1;
    // });
    state.params.person_name = input.value;
  },
  filterListByFacilityName: (state, input) => {
    // let forwarding_protocols = state.forwarding_protocols;
    // state.filterForwardingProtocols = forwarding_protocols.filter((obj) => {
    //   let name = obj.facility.name.toLowerCase().indexOf(input.value.toLowerCase());
    //   return name > -1;
    // });
    state.params.facility_name = input.value;
  },
  filterListByAccompanimentType: (state, input) => {
    // let forwarding_protocols = state.forwarding_protocols;
    // state.filterForwardingProtocols = forwarding_protocols.filter((obj) => {
    //   let name = obj.accompaniment_type.name.toLowerCase().indexOf(input.value.toLowerCase());
    //   return name > -1;
    // });
    state.params.accompaniment_type_name = input.value;
  },
  toggleModalToggleForwardingProtocols: (state) => {
    state.modalToggleForwardingProtocols = !state.modalToggleForwardingProtocols;
  },
  toggleModalDeleteForwardingProtocol: (state) => {
    state.modalConfirmDeleteForwardingProtocol = !state.modalConfirmDeleteForwardingProtocol;
  },
  toggleModalViewForwardingProtocol: (state) => {
    state.modalViewForwardingProtocol = !state.modalViewForwardingProtocol;
  },
  forwardingProtocoForDeletion: (state, payload) => {
    state.forwardingProtocolId = payload.id;
  },
  setIdMutation: (state, id) => {
    state.forwardingProtocol.id = id;
  },
  setObservationMutation: (state, observation) => {
    state.forwardingProtocol.observation = observation;
  },
  setAccompanimentTypeMutation: (state, accompaniment_type) => {
    state.forwardingProtocol.accompaniment_type_id = accompaniment_type.id;
    state.accompaniment_type = accompaniment_type;
  },
  setPersonMutation: (state, person) => {
    state.forwardingProtocol.person_id = person.id;
    state.person = person;
  },
  setAccompanimentTypeFacilityMutation: (state, accompaniment_type_facility) => {
    state.accompaniment_type_facility = accompaniment_type_facility;
    state.forwardingProtocol.facility_id = accompaniment_type_facility.id;
  },
  setSourceSystemMutation: (state, source_system) => {
    state.forwardingProtocol.source_system_id = source_system.id;
    state.source_system = source_system;
  },
  clearQuery: (state) => {
    state.forwarding_protocols = [];
    state.filterForwardingProtocols = [];
    state.params.person_name = null;
    state.params.facility_name = null;
    state.params.accompaniment_type = null;

    state.params.order_by = "";
    state.params.limit = "";
    state.params.offset = "";

    state.params.page = "";

    state.totalFilter = 0;
    state.totalPerPage = 10;
    state.offsetFilter = 0;
    state.totalFilterPages = 0;
    state.pagesFilter = [];
    state.actualPageFilter = 1;
  },
  clearFields: (state) => {
    state.forwardingProtocol.id = null;
    state.stateFiltered = null;
    state.cityFiltered = null;
    state.neighborhoodFiltered = null;
    state.forwardingProtocol.observation = "";
    state.forwardingProtocol.forwarding_date = "";
    state.person = null;
    state.source_system = null;
    state.accompaniment_type_facility = null;
    state.accompaniment_type = null;
  },
};

const actions = {
  // PEGAR PÁGINA ESPECÍFICA
  getPageFilter: ({ state, commit }, payload) => {
    if (payload != null) {
      const params = {
        person_name: state.params.person_name,
        facility_name: state.params.facility_name,
        accompaniment_type_name: state.params.accompaniment_type_name,
        order_by: "people.name",
        limit: state.totalPerPage,
        offset: payload.offset,
        page: payload.page,
        paginate: true,
      };
      getForwardingProtocols(params)
        .then((response) => {
          commit("setForwardingProtocolsMutation", response.data.forwarding_protocols);
          commit("setPaginateMutation", response.data.paginate);
          commit("setTotalFilterMutation", response.data.paginate.total_count);
          commit("setActualPageFilterMutation", payload.number);
        })
        .catch((e) => {
          console.log("error", e.response); //TODO: tratar esse error
        });
    }
  },
  loadForwardingProtocols: ({ commit }, from) => {
    commit("setOffsetFilterMutation", 0);
    const params = {
      person_name: state.params.person_name,
      facility_name: state.params.facility_name,
      accompaniment_type_name: state.params.accompaniment_type_name,
      order_by: "people.name",
      limit: state.totalPerPage,
      offset: state.offsetFilter,
      paginate: true,
    };
    getForwardingProtocols(params)
      .then((response) => {
        commit("setForwardingProtocolsMutation", response.data.forwarding_protocols);
        commit("setTotalFilterMutation", response.data.paginate.total_count);
        commit("setActualPageFilterMutation", 1);
        if (parseInt(response.data.total, 10) < 1 && from != "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadAccompanimentTypes: ({ commit }) => {
    getAccompanimentTypes()
      .then((response) => {
        commit("setAccompanimentTypesMutation", response.data.accompaniment_types);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadAccompanimentTypeFacilityByType: ({ commit }, payload) => {
    getAccompanimentTypeFacilityByType(payload.id)
      .then((response) => {
        let accompaniment_type_facilities = [];
        if (payload.typeFilter != null && payload.typeFilter == 1) {
          let accompaniment_type_facilities_by_state = [];
          response.data.accompaniment_type_facilities.forEach((element) => {
            if (element.facility.state.id == state.stateFiltered.id) {
              let id = element.id;
              let name = element.facility.name;
              accompaniment_type_facilities_by_state.push({ id: id, name: name });
            }
          });
          accompaniment_type_facilities = accompaniment_type_facilities_by_state;
        }
        if (payload.typeFilter != null && payload.typeFilter == 2) {
          let accompaniment_type_facilities_by_city = [];
          response.data.accompaniment_type_facilities.forEach((element) => {
            if (element.facility.city.id == state.cityFiltered.id) {
              let id = element.id;
              let name = element.facility.name;
              accompaniment_type_facilities_by_city.push({ id: id, name: name });
            }
          });
          accompaniment_type_facilities = accompaniment_type_facilities_by_city;
        }
        if (payload.typeFilter != null && payload.typeFilter == 3) {
          let accompaniment_type_facilities_by_neighborhood = [];
          response.data.accompaniment_type_facilities.forEach((element) => {
            if (element.facility.neighborhood.id == state.neighborhoodFiltered.id) {
              let id = element.id;
              let name = element.facility.name;
              accompaniment_type_facilities_by_neighborhood.push({ id: id, name: name });
            }
          });
          accompaniment_type_facilities = accompaniment_type_facilities_by_neighborhood;
        }
        // response.data.accompaniment_type_facilities.forEach((element) => {
        //   let id = element.id;
        //   let name = element.facility.name;
        //   accompaniment_type_facilities.push({ id: id, name: name });
        // });
        commit("setAccompanimentTypeFacilitiesMutation", accompaniment_type_facilities);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadSourceSystems: ({ commit }) => {
    getSourceSystems()
      .then((response) => {
        commit("setSourceSystemsMutation", response.data.source_systems);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  filterListByNamePerson: ({ commit }, payload) => {
    commit("filterListByNamePerson", payload);
  },
  filterListByFacilityName: ({ commit }, payload) => {
    commit("filterListByFacilityName", payload);
  },
  filterListByAccompanimentType: ({ commit }, payload) => {
    commit("filterListByAccompanimentType", payload);
  },
  toggleModalToggleForwardingProtocols: ({ commit }) => {
    commit("toggleModalToggleForwardingProtocols");
  },
  loadStates: ({ commit }) => {
    getStates()
      .then((resp) => {
        // console.log("Stados", resp);
        commit("setStatesMutation", resp.data.states);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadCities: ({ commit, dispatch }, city_id) => {
    getCitiesByStateId(city_id)
      .then((response) => {
        // console.log("response city", response);
        // console.log("cities", response);
        dispatch("setCity", null);
        dispatch("setNeighborhood", null);
        commit("setCitiesMutation", response.data.cities);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadNeighborhoods: ({ commit, dispatch }, payload) => {
    let id = payload;
    getNeighborhoodsByCityId(id)
      .then((response) => {
        // console.log("neghborhoods", response);
        dispatch("setNeighborhood", null);
        // dispatch("setAccompanimentTypeFacility", null);
        commit("setNeighborhoodsMutation", response.data.neighborhoods);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  setCity: ({ commit, dispatch }, payload) => {
    commit("setCityMutation", payload);
    if (payload != null) {
      dispatch("loadNeighborhoods", payload.id);
    }
  },
  setState: ({ commit, dispatch }, payload) => {
    commit("setStateMutation", payload);
    dispatch("loadCities", payload.id);
    commit("setAccompanimentTypeFacilityMutation", null);
  },
  setNeighborhood: ({ commit }, payload) => {
    commit("setNeighborhoodMutation", payload);
  },
  loadData: ({ commit }, payload) => {
    getForwardingProtocolId(payload.id)
      .then((response) => {
        console.log("response forwarind", response);
        commit("setIdMutation", response.data.forwarding_protocol.id);
        commit("setObservationMutation", response.data.forwarding_protocol.observation);
        commit("setPersonMutation", response.data.forwarding_protocol.person);
        commit("setAccompanimentTypeMutation", response.data.forwarding_protocol.accompaniment_type);
        commit("setAccompanimentTypeFacilityMutation", response.data.forwarding_protocol.facility);
        commit("setSourceSystemMutation", response.data.forwarding_protocol.source_system);
        commit("setForwardingDateMutation", response.data.forwarding_protocol.forwarding_date);
        commit("setStateMutation", response.data.forwarding_protocol.occurrence_state);
        commit("setCityMutation", response.data.forwarding_protocol.occurrence_city);
        commit("setNeighborhoodMutation", response.data.forwarding_protocol.occurrence_neighborhood);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  openViewModal: ({ commit, dispatch }, payload) => {
    dispatch("loadData", payload);
    commit("toggleModalViewForwardingProtocol");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewForwardingProtocol");
    commit("clearFields");
  },
  openEditModal: ({ commit, dispatch }, payload) => {
    dispatch("loadData", payload);
    commit("toggleModalToggleForwardingProtocols");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalToggleForwardingProtocols");
    commit("clearFields");
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  toggleModalDeleteForwardingProtocol: ({ commit }, payload) => {
    commit("toggleModalDeleteForwardingProtocol");
    if (state.modalConfirmDeleteForwardingProtocol) {
      commit("forwardingProtocoForDeletion", payload);
    } else {
      commit("forwardingProtocoForDeletion", null);
    }
  },
  setId: ({ commit }, payload) => {
    commit("setIdMutation", payload);
  },
  setObservation: ({ commit }, payload) => {
    commit("setObservationMutation", payload.value);
  },
  setPerson: ({ commit }, payload) => {
    commit("setPersonMutation", payload);
  },
  setAccompanimentType: ({ commit }, payload) => {
    commit("setAccompanimentTypeMutation", payload);
    commit("setStateMutation", null);
    commit("setCityMutation", null);
    commit("setNeighborhoodMutation", null);
    commit("setAccompanimentTypeFacilityMutation", null);
  },
  setAccompanimentTypeFacility: ({ commit }, payload) => {
    commit("setAccompanimentTypeFacilityMutation", payload);
  },
  setSourceSystem: ({ commit }, payload) => {
    commit("setSourceSystemMutation", payload);
  },
  setForwardingDate: ({ commit }, payload) => {
    commit("setForwardingDateMutation", payload.value);
  },
  save: ({ commit, dispatch }) => {
    console.log("state.forwardingProtocol chegando", state.forwardingProtocol);
    createForwardingProtocol(state.forwardingProtocol)
      .then(() => {
        Vue.$toast.success("Protocolo de Encaminhamento cadastrado com sucesso!");
        commit("toggleModalToggleForwardingProtocols");
        commit("clearFields");
        dispatch("ForwardingProtocols/loadForwardingProtocols", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar o Tipo de Acompanhamento por Equipamento" +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  update: ({ dispatch, commit }) => {
    updateForwardingProtocol(state.forwardingProtocol.id, state.forwardingProtocol)
      .then(() => {
        Vue.$toast.success("Protocolo de Encaminhamento atualizado com sucesso!");
        commit("toggleModalToggleForwardingProtocols");
        dispatch("ForwardingProtocols/updateFilterById", state.forwardingProtocol.id, { root: true });
        //dispatch("ForwardingProtocols/loadForwardingProtocols", null, { root: true });
        commit("clearFields");
        // --
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  updateFilterById: ({ commit }, id) => {
    getForwardingProtocolId(id)
      .then((response) => {
        // console.log(response);
        let forwarding_protocol = response.data.forwarding_protocol;
        let updated = {
          accompaniment_type: {
            id: forwarding_protocol.accompaniment_type.id,
            name: forwarding_protocol.accompaniment_type.name,
          },
          facility: {
            id: forwarding_protocol.facility.id,
            name: forwarding_protocol.facility.name,
          },
          forwarding_date: forwarding_protocol.forwarding_date,
          id: forwarding_protocol.id,
          person: {
            id: forwarding_protocol.person.id,
            name: forwarding_protocol.person.name,
          },
          sectorial: {
            id: forwarding_protocol.sectorial.id,
            name: forwarding_protocol.sectorial.name,
          },
          source_system_name: forwarding_protocol.source_system_name,
        };
        commit("updateFilterByIdMutation", updated);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyForwardingProtocol(state.forwardingProtocolId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Protocolo de Encaminhamento deletado com sucesso");
        commit("toggleModalDeleteForwardingProtocol");
        dispatch("ForwardingProtocols/loadForwardingProtocols", from, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  setOffsetFilter: ({ commit }, payload) => {
    commit("setOffsetFilterMutation", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
