<template>
  <div class="text-purple-classic grid grid-cols-1 min-h-fit">
    <div class="text-xl font-bold justify-self-center">Olá! Bem vindo(a) ao SIGIM</div>
    <div class="justify-self-center">Vamos começar?</div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {},
  methods: {
    ...mapActions({
      getPermits: "AccessControl/getPermits",
      getProfPermits: "AccessControl/getProfilePermits",
    }),
    getTeste() {
      // console.log("TESTE", this.perm);
      // console.log("STORAGE", JSON.parse(localStorage.perms));
    },
  },

  computed: {
    ...mapState({
      perm: (state) => state.AccessControl.perm,
    }),
  },
  created() {
    this.getProfPermits();
    this.$store.dispatch("AccessControl/getPermits");
  },
};
</script>

<style scoped>
.height {
  min-height: -webkit-fill-available;
}
</style>
