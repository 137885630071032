import { getUsers } from "../../service/UsersApi";
import { profiles } from "../../service/AccessControlApi";
import Vue from "vue";

const state = {
  users: [],
  filteredUsers: [],
  ability_profiles: [],
  filterUsers: {
    name: "",
    prifile_id: 0,
  },
  profiles: [],
  modalDeleteUsersConfirmationOpen: false,
  userIdForDeletion: null,
};

const mutations = {
  setUsersMutation: (state, users) => {
    state.users = users;
    state.filteredUsers = users;
  },
  addUserMutation: (state, user) => {
    state.users.push(user);
  },
  filterListMutation: (state, input) => {
    let users = state.users;
    state.filterUsers = users.filter((obj) => {
      let name = obj.name.toLowerCase().indexOf(input.value.toLowerCase());
      let cpf = obj.cpf.toLowerCase().indexOf(input.value.toLowerCase());

      return name > -1 || cpf > -1;
    });
  },
  filterListByNameMutation: (state, input) => {
    state.filterUsers.name = input.value;
  },
  filterListByProfileMutation: (state, input) => {
    state.filterUsers.profile_id = input.value;
  },
  loadProfilesMutation: (state, profiles) => {
    state.profiles = profiles;
  },
  clearQuery: (state) => {
    state.filterList = [];
    state.filteredUsers = [];
    state.ability_profiles = [];
    state.filterUsers.name = "";
    state.filterdUsers.profile_id = 0;
  },
  setRoleMutation: (state, role) => {
    state.ability_profiles = [role];
  },
};

const actions = {
  setUsers: ({ commit }, users) => {
    commit("setUsersMutation", users);
  },
  addUser: ({ commit }, user) => {
    commit("addUserMutation", user);
  },
  filterList: ({ commit }, payload) => {
    commit("filterListMutation", payload);
  },
  filterListByName: ({ commit }, payload) => {
    commit("filterListByNameMutation", payload);
  },
  filterListByProfile: ({ commit }, payload) => {
    commit("filterListByProfileMutation", payload);
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  setRole: ({ commit }, payload) => {
    // console.log("payload", payload);
    commit("setRoleMutation", { ability_profile_id: payload.id, name: payload.name });
  },
  getProfiles({ commit }) {
    // const auth = JSON.parse(localStorage.getItem("userData"));
    profiles().then((response) => {
      commit("loadProfilesMutation", response.data.ability_profiles);
    });
  },
  loadUsers: ({ commit }, from) => {
    const filters = {
      name_user: state.filterUsers.name,
      profile_id: state.filterUsers.profile_id,
    };
    getUsers(filters)
      .then((response) => {
        commit("setUsersMutation", response.data.user);
        if (parseInt(response.data.user.length, 10) <= 0 && from != "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
