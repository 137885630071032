import { getAxios, baseURL } from "./Api";

export async function getDenunciationVictims(filters) {
  const api = getAxios(baseURL["sigim_api"]);
  let url = `denunciation_victims`;
  return api.get(url, { params: filters });
}

export async function getDenunciationVictimById(_id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.get(`denunciation_victims/${_id}`);
}

export async function createDenunciationVictim(denunciation_victim) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.post("/denunciation_victims?many=true", denunciation_victim);
}

export async function destroyDenunciationVictim(id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.delete(`denunciation_victims/${id}`);
}

export async function updateDenunciationVictim(id, denunciation_victim) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.put(`denunciation_victims/${id}`, denunciation_victim);
}
