import { getAxios, baseURL } from "./Api";

export async function getDenunciations(filters) {
  const api = getAxios(baseURL["sigim_api"]);
  let url = `denunciations`;
  return api.get(url, { params: filters });
}

export async function getDenunciationById(_id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.get(`denunciations/${_id}`);
}

export async function createDenunciation(denunciation) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.post("/denunciations", denunciation);
}

export async function destroyDenunciation(id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.delete(`denunciations/${id}`);
}

export async function updateDenunciation(id, denunciation) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.put(`denunciations/${id}`, denunciation);
}
