import { getAxios, baseURL } from "./Api";

export async function getDenunciationAggressorDependencies(filters) {
  const api = getAxios(baseURL["sigim_api"]);
  let url = `denunciation_aggressor_dependencies`;
  return api.get(url, { params: filters });
}

export async function getDenunciationAggressorDependencyById(_id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.get(`denunciation_aggressor_dependencies/${_id}`);
}

export async function createDenunciationAggressorDependency(denunciation_aggressor_dependency) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.post("/denunciation_aggressor_dependencies?many=true", denunciation_aggressor_dependency);
}

export async function destroyDenunciationAggressorDependency(id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.delete(`denunciation_aggressor_dependencies/${id}`);
}

export async function updateDenunciationAggressorDependency(id, denunciation_aggressor_dependency) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.put(`denunciation_aggressor_dependencies/${id}`, denunciation_aggressor_dependency);
}
