<template>
  <div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-center">
      <div class="mx-2 col-span-2">
        <BaseText
          text="Usuários"
          typeText="custom"
          weight="semibold"
          :italic="false"
          size="3xl"
          color="purple"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          id="btn-add-teacher"
          showIcon
          iconName="add"
          text="Novo Registro"
          dispatch="User/openModal"
          typeButton="primary"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 justify-between p-4 items-end">
      <div class="mx-2">
        <BaseInput
          showLabel
          labelText="Pesquisar por nome"
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Digite nome"
          dispatch="Users/filterListByName"
          ref="name_user"
        />
      </div>
      <div class="mx-2">
        <Multiselect
          showLabel
          labelText="Pesquisar por Perfil"
          id="select-role"
          select-placeholder="Selecione Perfil"
          close-on-select
          show-label
          :value="ability_profiles"
          :options="Profiles"
          :dispatch="setRole"
          :multiple-option="false"
          class="mr-4"
          ref="profile_id"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 items-center p-4">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-user"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Users/loadUsers"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="users.length > 0">
        <a h:value="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-facilities"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>

    <div class="p-4">
      <div><UsersCards :data="users" /></div>
    </div>
    <ModalUser :open="toggleModal" />
    <ModalConfirmDeleteUser :open="modalConfirmDeleteUser" />
    <ModalResetUserPassword :open="modalResetPassword" />
    <ModalUserActivation :open="modalActivation" />
  </div>
</template>

<script>
import ModalUser from "./ModalUser.vue";
import UsersCards from "./list/UsersCards.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ModalConfirmDeleteUser from "../views/ModalConfirmDeleteUser.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import ModalResetUserPassword from "./ModalResetUserPassword.vue";
import ModalUserActivation from "../views/ModalUserActivation.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    UsersCards,
    ModalConfirmDeleteUser,
    ModalUserActivation,
    BaseText,
    ModalUser,
    AppButtonColorful,
    ModalResetUserPassword,
    BaseInput,
    Multiselect,
  },
  created() {
    this.getProfiles();
  },
  methods: {
    ...mapActions({
      setRole: "Users/setRole",
      getProfiles: "Users/getProfiles",
    }),
    clearFields() {
      this.$refs["name_user"].value = "";
      this.$refs["profile_id"].value = "";
      this.$store.dispatch("Users/clearQuery");
    },
  },
  computed: {
    ...mapGetters({
      getErrorMessage: "User/getErrorMessage",
    }),
    ...mapState({
      users: (state) => state.Users.filteredUsers,
      ability_profiles: (state) => state.Users.ability_profiles,
      toggleModal: (state) => state.User.modalToggle,
      modalConfirmDeleteUser: (state) => state.User.modalConfirmDeleteUser,
      Profiles: (state) => state.Users.profiles,
      modalResetPassword: (state) => state.User.modalResetPassword,
      modalActivation: (state) => state.User.modalActivation,
    }),
  },
};
</script>
