import Vue from "vue";
import { createUser, updateUser, deleteUSer, resetPassword } from "../../service/UserApi";

const state = {
  user: {
    id: null,
    name: "",
    email: "",
    cpf: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
    active: true,
    roles_attributes: [],
  },
  emptyUser: {
    id: null,
    name: "",
    email: "",
    cpf: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
    active: true,
    roles_attributes: [],
  },
  modalResetPassword: false,
  modalToggle: false,
  modalConfirmDeleteUser: false,
  modalActivation: false,
  errorMessages: [],
  userId: 0,
};

const mutations = {
  toggleModalDeleteConfirmation: (state) => {
    state.modalConfirmDeleteUser = !state.modalConfirmDeleteUser;
  },
  userForDeletion: (state, payload) => {
    // console.log("payload.id", payload);
    state.userId = payload;
  },
  setIdMutation: (state, id) => {
    state.user.id = id;
  },
  setNameMutation: (state, name) => {
    state.user.name = name;
  },
  setEmailMutation: (state, email) => {
    state.user.email = email;
  },
  setPasswordMutation: (state, password) => {
    state.user.password = password;
  },
  setPasswordConfirmationMutation: (state, password_confirmation) => {
    state.user.password_confirmation = password_confirmation;
  },
  setCpfMutation: (state, cpf) => {
    state.user.cpf = cpf;
  },
  setPhoneNumberMutation: (state, phone_number) => {
    state.user.phone_number = phone_number;
  },
  clearUserMutation: (state) => {
    state.user = Object.assign(state.user, state.emptyUser);
  },
  toggleModalMutation: (state) => {
    state.modalToggle = !state.modalToggle;
  },
  toggleModalResetPasswordMutation: (state) => {
    state.modalResetPassword = !state.modalResetPassword;
  },
  toggleModalActivationMutation: (state) => {
    state.modalActivation = !state.modalActivation;
  },
  setUserMutation: (state, user) => {
    state.user = Object.assign(state.user, user);
    state.user.roles_attributes = user.ability_profile;
  },
  setRoleMutation: (state, role) => {
    state.user.roles_attributes = [role];
  },
  setActiveMutation: (state, active) => {
    state.user.active = active;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

const actions = {
  openCloseDeleteUserConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteConfirmation");
    if (state.modalConfirmDeleteUser) {
      commit("userForDeletion", payload.id);
    } else {
      commit("userForDeletion", null);
    }
  },
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setEmail: ({ commit }, payload) => {
    commit("setEmailMutation", payload.value);
  },
  setPassword: ({ commit }, payload) => {
    commit("setPasswordMutation", payload.value);
  },
  setPasswordConfirmation: ({ commit }, payload) => {
    commit("setPasswordConfirmationMutation", payload.value);
  },
  setCpf: ({ commit }, payload) => {
    commit("setCpfMutation", payload.value);
  },
  setPhoneNumber: ({ commit }, payload) => {
    commit("setPhoneNumberMutation", payload.value);
  },
  setActive: ({ commit }, payload) => {
    commit("setActiveMutation", payload.value);
  },
  openModal: ({ commit }) => {
    commit("toggleModalMutation");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalMutation");
    commit("clearUserMutation");
    commit("setErrorMessagesMutation", []);
  },
  openModalActivation: ({ dispatch, commit }, payload) => {
    dispatch("setUser", payload);
    commit("toggleModalActivationMutation");
  },
  closeModalActivation: ({ commit }) => {
    commit("clearUserMutation");
    commit("toggleModalActivationMutation");
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  openEditModal: ({ dispatch }, payload) => {
    dispatch("setUser", payload);
    dispatch("openModal", payload);
  },
  openModalResetPassword: ({ dispatch, commit }, payload) => {
    dispatch("setUser", payload);
    commit("toggleModalResetPasswordMutation");
  },
  closeModalResetPassword: ({ commit }) => {
    commit("toggleModalResetPasswordMutation");
    commit("clearUserMutation");
    commit("setErrorMessagesMutation", []);
  },
  setRole: ({ commit }, payload) => {
    // console.log("payload", payload);
    commit("setRoleMutation", { ability_profile_id: payload.id, name: payload.name });
  },
  save: ({ commit, dispatch }) => {
    const user = state.user;
    const objUser = {
      id: user.id,
      name: user.name,
      email: user.email,
      active: user.active,
      cpf: user.cpf,
      phone_number: user.phone_number,
      password: user.password,
      password_confirmation: user.password_confirmation,
      confirmed_at: new Date(),
      ability_profile_id:
        user.roles_attributes.length > 0
          ? user.roles_attributes[0].ability_profile_id
          : user.ability_profile.id,
    };
    createUser(objUser)
      .then((response) => {
        Vue.$toast.success("Criado com sucesso");
        dispatch("Users/addUser", response.data, { root: true });
        dispatch("closeModal");
        dispatch("Users/loadUsers", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar o usuário! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );
        console.log(e);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  update: ({ dispatch, state }) => {
    const user = state.user;
    let confirmed_at_toSend;
    if (user.active) {
      confirmed_at_toSend = new Date();
    } else {
      confirmed_at_toSend = null;
    }
    const objUser = {
      id: user.id,
      name: user.name,
      email: user.email,
      active: user.active,
      phone_number: user.phone_number,
      // password: user.password,
      // password_confirmation: user.password_confirmation,
      confirmed_at: confirmed_at_toSend,
      ability_profile_id:
        user.roles_attributes.length > 0
          ? user.roles_attributes[0].ability_profile_id
          : user.ability_profile.id,
    };
    updateUser(objUser.id, objUser).then((resp) => {
      if (resp.status === 201 || resp.status === 200) {
        Vue.$toast.success("Atualizado com sucesso");
        if (state.modalActivation) {
          dispatch("User/closeModalActivation", null, { root: true });
        }
        dispatch("Users/loadUsers", null, { root: true });
        if (state.modalToggle) {
          dispatch("closeModal");
        }
      } else {
        Vue.$toast.error(
          "Não foi possivel cadastrar o usuário! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );
      }
    });
  },
  deleteUSer: ({ commit, dispatch, state }) => {
    const userId = state.userId;
    // console.log("user_id", prof_id);
    deleteUSer(userId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Usuário deletado com sucesso");
        dispatch("Users/loadUsers", from, { root: true });
        commit("toggleModalDeleteConfirmation");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        console.log("Erro", e.response);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  resetPassword: ({ commit, dispatch }) => {
    if (state.user.password == "" || state.user.password_confirmation == "") {
      Vue.$toast.error("Campos de senha e confirmação de senha devem ser preenchidos!");
    } else if (state.user.password != state.user.password_confirmation) {
      Vue.$toast.error("Campos de senha e confirmação de senha foram preenchidos com valores diferentes!");
    } else {
      resetPassword(state.user.id, state.user.password, state.user.password_confirmation)
        .then(() => {
          Vue.$toast.success("Senha atualizada com sucesso!");
          dispatch("closeModalResetPassword");
          dispatch("Users/loadUsers", null, { root: true });
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possível atualizar a senha!");
          console.log(e);
          commit("setErrorMessagesMutation", e.response.data.errors);
        });
    }
  },
};

const getters = {
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      switch (error.type) {
        case "blank":
          if (field === "" || field === " " || field === undefined || field === null || field.length === 0) {
            return error.message;
          } else {
            return null;
          }

        case "invalid_cpf":
          return error.message;

        case "invalid":
          return error.message;

        case "taken":
          return error.message;

        default:
          return null;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
