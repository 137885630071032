<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-6 gap-y-6 mb-12">
      <div class="col-span-5">
        <BaseText text="Informações gerais" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-70" />
      </div>
      <div class="col-span-2">
        <AppTextField show-label label-text="Nome" :text="person.name" type-text="topic-details" />
      </div>
      <div class="col-span-2">
        <AppTextField
          show-label
          label-text="Data de nascimento"
          :text="formatDate(person.birth_date)"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField show-label label-text="Cpf" :text="person.cpf" type-text="topic-details" />
      </div>
      <div>
        <AppTextField show-label label-text="RG" :text="person.rg" type-text="topic-details" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Títulod de eleitor"
          :text="person.voter_registration"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Nome social"
          :text="person.social_name"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Apelido"
          :text="person.nickname ? person.nickname : ''"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Gênero"
          :text="person.gender ? person.gender.name : ''"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Carteira de Trabalho"
          :text="person.work_card"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Nis"
          :text="person.nis ? person.nis : ''"
          type-text="topic-details"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Renda Média"
          :text="person.average_income ? person.average_income : ''"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Identidade de gênero"
          :text="person.gender_identity ? person.gender_identity.name : ''"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Orientação Sexual"
          :text="person.sexual_orientation ? person.sexual_orientation.name : ''"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Raça"
          :text="person.breed ? person.breed.name : ''"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Etinia"
          :text="person.ethnicity ? person.ethnicity.name : ''"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Estado Civil"
          :text="person.civil_status ? person.civil_status.name : ''"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Cor da pele"
          :text="person.skin_color ? person.skin_color.name : ''"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Escolaridade"
          :text="person.scholarity ? person.scholarity.name : 'Não informado'"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Possui deficiência?"
          :text="verifyInfor(person.pwd)"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Dependente Químico?"
          :text="verifyInfor(person.chemical_dependent)"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-1">
        <AppTextField
          show-label
          label-text="Problemas Psicológicos?"
          :text="verifyInfor(person.psychological_disorder)"
          type-text="topic-details"
          classe="break-words"
        />
      </div>
      <div class="col-span-5 pt-1 pb-1">
        <div>
          <BaseText text="Deficiêcias informadas" type-text="title-forms" />
          <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        </div>
        <div>
          <div v-if="person.handicap_types.length > 0">
            <div
              class="grid grid-cols-5"
              :key="item.id"
              :data-index="index"
              v-for="(item, index) in person.handicap_types"
            >
              <AppTextField show-label :text="item.name" type-text="topic-details" />
            </div>
          </div>
          <div v-else>
            <div class="col-span-5">
              <AppTextField
                show-label
                label-text="Não há deficiencias informadas"
                type-text="topic-details"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-5">
        <BaseText text="Local de Nascimento" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-70" />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Estado"
          :text="person.place_of_birth_state ? person.place_of_birth_state.name : ''"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Cidade"
          :text="person.place_of_birth ? person.place_of_birth.name : ''"
          type-text="topic-details"
        />
      </div>
      <div>
        <AppTextField
          show-label
          label-text="Bairro"
          :text="person.neighborhood ? person.neighborhood.name : ''"
          type-text="topic-details"
        />
      </div>
    </div>

    <div class="grid grid-cols-5 gap-2 tab_menu">
      <div :class="[{ active_new: contactsData }, 'tab_new', 'p-2']">
        <a class="mx-2" href="javascript:void(0)" @click="openTab(1)"> Contatos </a>
      </div>
      <div :class="[{ active_new: addressData }, 'tab_new', 'p-2']">
        <a class="mx-2" href="javascript:void(0)" @click="openTab(2)"> Endereços </a>
      </div>
      <div :class="[{ active_new: relativesData }, 'tab_new', 'p-2']">
        <a class="mx-2" href="javascript:void(0)" @click="openTab(3)"> Relacionamentos </a>
      </div>
      <div :class="[{ active_new: peopleReportsData }, 'tab_new', 'p-2']">
        <a class="mx-2" href="javascript:void(0)" @click="openTab(4)"> Histórico </a>
      </div>
    </div>

    <div id="contactsData" class="mt-5" v-if="contactsData === true">
      <div class="">
        <BaseText text="Informações de Contatos" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-70" />
      </div>
      <div v-if="person.people_contacts.length > 0">
        <div
          class="grid grid-cols-3 gap-x-1 gap-y-6 mt-5"
          :key="new_contact.id"
          :data-index="index"
          v-for="(new_contact, index) in new_contacts"
        >
          <div>
            <AppTextField
              show-label
              label-text="Tipo de contato"
              :text="new_contact.contact_type.name ? new_contact.contact_type.name : ''"
              type-text="topic-details"
            />
          </div>
          <div>
            <AppTextField
              show-label
              label-text="Contato"
              :text="new_contact.contact"
              type-text="topic-details"
            />
          </div>
          <div>
            <AppTextField
              show-label
              label-text="Observação"
              :text="new_contact.observation"
              type-text="topic-details"
            />
          </div>
        </div>
      </div>
      <div v-else class="mt-5">
        <AppTextField show-label label-text="Sem dados cadastrados" type-text="topic-details" />
      </div>
    </div>

    <div id="addressData" class="mt-5" v-if="addressData === true">
      <div class="">
        <BaseText text="Informações de Endereços informados" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-70" />
      </div>
      <div v-if="person.people_addresses.length > 0">
        <div
          class="grid grid-cols-4 gap-x-1 gap-y-6 mt-5"
          :key="new_address.id"
          :data-index="index"
          v-for="(new_address, index) in new_address"
        >
          <div class="col-span-2">
            <AppTextField
              show-label
              label-text="Rua"
              :text="new_address.street_name"
              type-text="topic-details"
            />
          </div>
          <div class="">
            <AppTextField
              show-label
              label-text="Número"
              :text="new_address.street_number"
              type-text="topic-details"
            />
          </div>
          <div class="">
            <AppTextField
              show-label
              label-text="Complemento"
              :text="new_address.address_complement"
              type-text="topic-details"
            />
          </div>
          <div class="">
            <AppTextField show-label label-text="Cep" :text="new_address.cep" type-text="topic-details" />
          </div>
          <div>
            <AppTextField
              show-label
              label-text="Estado"
              :text="new_address.state ? new_address.state.name : ''"
              type-text="topic-details"
            />
          </div>
          <div>
            <AppTextField
              show-label
              label-text="Cidade"
              :text="new_address.city ? new_address.city.name : ''"
              type-text="topic-details"
            />
          </div>
          <div>
            <AppTextField
              show-label
              label-text="Bairro"
              :text="new_address.neighborhood ? new_address.neighborhood.name : ''"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-4">
            <hr
              v-if="index <= person.people_addresses.length"
              class="border-t-0 border-b-2 border-ashes opacity-70"
            />
          </div>
        </div>
      </div>
      <div v-else class="mt-5">
        <AppTextField show-label label-text="Sem dados cadastrados" type-text="topic-details" />
      </div>
    </div>

    <div id="relativesData" class="mt-5" v-if="relativesData === true">
      <div class="">
        <BaseText text="Informações de Relacionamentos" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-70" />
      </div>
      <div v-if="person.relationships.length > 0">
        <div
          class="grid grid-cols-5 gap-x-1 gap-y-6 mt-5"
          :key="relative.id"
          :data-index="index"
          v-for="(relative, index) in new_relationship"
        >
          <div>
            <AppTextField
              show-label
              label-text="Grau de Parentesco"
              :text="relative.relationship_degree ? relative.relationship_degree.name : ''"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-1 pt-5">
            <AppTextField
              show-label
              label-text="Responsável?"
              :text="relative.responsible === true ? 'SIM' : 'NÂO'"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-3">
            <AppTextField
              show-label
              label-text="Pessoa"
              :text="relative.relationship_person ? relative.relationship_person.name : ''"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-5">
            <AppTextField
              show-label
              label-text="Observação"
              :text="relative.observation"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-5">
            <hr
              v-if="index <= person.relationships.length"
              class="border-t-0 border-b-2 border-ashes opacity-70"
            />
          </div>
        </div>
      </div>
      <div v-else class="mt-5">
        <AppTextField show-label label-text="Sem dados cadastrados" type-text="topic-details" />
      </div>
      <hr v-if="index > 0" class="border-t-0 border-b-2 border-ashes opacity-70" />
    </div>

    <div id="peopleReportsData" class="mt-5" v-if="peopleReportsData === true">
      <div class="col-span-5">
        <BaseText text="Informações de Ocorrências" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-70" />
      </div>
      <div v-if="customReport.length > 0">
        <div class="grid grid-cols-9 items-center sticky top-0 mt-5">
          <div class="justify-self-start ml-4">
            <BaseText
              text="SISTEMA"
              typeText="custom"
              weight="bold"
              :italic="false"
              mode="uppercase"
              size="sm"
              color="ashes-dark"
            />
          </div>
          <div class="justify-self-start ml-4">
            <BaseText
              text="DATA"
              typeText="custom"
              weight="bold"
              :italic="false"
              mode="uppercase"
              size="sm"
              color="ashes-dark"
            />
          </div>
          <div class="justify-self-start col-span-2 ml-2">
            <BaseText
              text="OCORRÊNCIA"
              typeText="custom"
              weight="bold"
              :italic="false"
              mode="uppercase"
              size="sm"
              color="ashes-dark"
            />
          </div>
          <div class="justify-self-start col-span-4">
            <BaseText
              text="DESCRIÇÃO"
              typeText="custom"
              weight="bold"
              :italic="false"
              mode="uppercase"
              size="sm"
              color="ashes-dark"
            />
          </div>
          <div class="justify-self-end"></div>
        </div>
        <div :key="report.id" :data-index="index" v-for="(report, index) in customReport">
          <BaseCard>
            <div class="grid grid-cols-9">
              <div class="justify-self-start place-self-center">
                <BaseText :text="report.source_system_name" size="xs" />
              </div>
              <div class="justify-self-start place-self-center">
                <BaseText :text="formatDate(report.occurrence_date)" size="xs" />
              </div>
              <div class="justify-self-start place-self-center col-span-2">
                <BaseText :text="report.occurrence" size="xs" />
              </div>
              <div class="justify-self-start place-self-center col-span-4 ml-1">
                <BaseText :text="report.description" size="xs" />
              </div>
              <div class="justify-self-end place-self-center ml-1">
                <a
                  href="javascript:void(0)"
                  class="text-ashes-dark hover:text-purple underline"
                  @click="showOccurrence(report)"
                >
                  <AppMaterialIcon icon-name="visibility" icon-size="26" class="align-bottom" />
                </a>
              </div>
            </div>
          </BaseCard>
        </div>
      </div>
      <div v-else class="mt-5">
        <AppTextField show-label label-text="Sem dados cadastrados" type-text="topic-details" />
      </div>
    </div>

    <ModalViewProtectiveMeasure :open="modalViewProtectiveMeasure" />
    <ModalViewIncidentReport :open="modalViewIncidentReport" />
    <ModaViewForwardingProtocol :open="modalViewForwardingProtocol" />
    <ModalViewDenunciation :open="modalViewDenunciation" />
    <ModalViewLawsuit :open="modalViewLawsuit" />
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import BaseCard from "../../components/BaseCard.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";
import ModalViewDenunciation from "../../views/datas/ModalViewDenunciation.vue";
import ModalViewLawsuit from "../../views/datas/ModalViewLawsuit.vue";
import ModaViewForwardingProtocol from "../../views/datas/ModaViewForwardingProtocol.vue";
import ModalViewIncidentReport from "../../views/datas/ModalViewIncidentReport.vue";
import ModalViewProtectiveMeasure from "../../views/datas/ModalViewProtectiveMeasure.vue";
import AppTextField from "../../components/AppTextField.vue";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseText,
    BaseCard,
    AppTextField,
    AppMaterialIcon,
    ModaViewForwardingProtocol,
    ModalViewLawsuit,
    ModalViewDenunciation,
    ModalViewIncidentReport,
    ModalViewProtectiveMeasure,
  },
  data: () => ({
    peopleReportsData: false,
    addressData: false,
    contactsData: true,
    relativesData: false,
  }),
  computed: {
    ...mapState({
      editRelashionship: (state) => state.People.editRelashionship,
      person: (state) => state.People.person,
      states: (state) => state.People.states,
      state: (state) => state.People.state,
      cities: (state) => state.People.cities,
      citie: (state) => state.People.citie,
      neighborhoods: (state) => state.People.neighborhoods,
      neighborhood: (state) => state.People.neighborhood,
      Genders: (state) => state.Genders.list,
      gendersName: (state) => state.People.gender,
      relationship: (state) => state.People.relationship,
      new_relationship: (state) => state.People.new_relationship,
      address: (state) => state.People.address,
      new_address: (state) => state.People.new_address,
      contacts: (state) => state.People.contact,
      new_contacts: (state) => state.People.new_contact,
      contactsTypes: (state) => state.People.contactsTypes,
      relationshipDegrees: (state) => state.RelationshipDegrees.list,
      relationshipDegree: (state) => state.People.relalationship_degree,
      civilStatuses: (state) => state.CivilStatuses.list,
      civilStatus: (state) => state.People.civil_status,
      breeds: (state) => state.Breeds.list,
      breed: (state) => state.People.breed,
      skinColors: (state) => state.SkinColors.list,
      skinColor: (state) => state.People.skin_color,
      scholarities: (state) => state.Escholarities.list,
      scholaritie: (state) => state.People.escholarity,
      ethnicities: (state) => state.Ethnicities.list,
      ethnicitie: (state) => state.People.ethnicity,
      SexualOrientations: (state) => state.SexualOrientations.list,
      // SexualOrientation: (state) => state.People.sexual_orientation,
      gendersIdentidy: (state) => state.GenderIdentidy.list,
      genderIdentidy: (state) => state.People.genderIdentidy,
      arrayProf: (state) => state.AccessControl.profArray,
      profiles: (state) => state.AccessControl.profiles,
      customReport: (state) => state.People.customReport,
      modalViewLawsuit: (state) => state.Lawsuits.modalViewLawsuit,
      modalViewDenunciation: (state) => state.Denunciations.modalViewDenunciation,
      modalViewIncidentReport: (state) => state.IncidentReports.modalViewIncidentReport,
      modalViewForwardingProtocol: (state) => state.ForwardingProtocols.modalViewForwardingProtocol,
      modalViewProtectiveMeasure: (state) => state.ProtectiveMeasures.modalViewProtectiveMeasure,
      modalConfirmDeleteRelativesNew: (state) => state.People.modalConfirmDeleteRelativesNew,
      modalConfirmDeleteAddressNew: (state) => state.People.modalConfirmDeleteAddressNew,
      modalConfirmDeleteContactsNew: (state) => state.People.modalConfirmDeleteContactsNew,
    }),
  },
  methods: {
    verifyInfor(value) {
      let ret = "";
      if (value == true) {
        ret = "Sim";
      } else {
        ret = "Não";
      }
      return ret;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    ...mapActions({
      getPeopleCustomReport: "People/loadPeopleCustomReports",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    servicedByGavv() {
      return this.incidentReport.serviced_by_gavv ? "Sim" : "Não";
    },
    haveAccessFirearm() {
      return this.incidentReport.have_access_firearm ? "Sim" : "Não";
    },
    requestsProtectiveMeasure() {
      return this.incidentReport.requests_protective_measure ? "Sim" : "Não";
    },
    openTab(typeTab) {
      switch (typeTab) {
        case 1:
          this.forwardProtocols = false;
          this.protectiveMeasureData = false;
          this.addressData = false;
          this.contactsData = true;
          this.relativesData = false;
          this.peopleReportsData = false;
          break;
        case 2:
          this.forwardProtocols = false;
          this.protectiveMeasureData = false;
          this.addressData = true;
          this.contactsData = false;
          this.relativesData = false;
          this.peopleReportsData = false;
          break;
        case 3:
          this.forwardProtocols = false;
          this.protectiveMeasureData = false;
          this.addressData = false;
          this.contactsData = false;
          this.relativesData = true;
          this.peopleReportsData = false;
          break;
        case 4:
          this.forwardProtocols = false;
          this.protectiveMeasureData = false;
          this.addressData = false;
          this.contactsData = false;
          this.relativesData = false;
          this.peopleReportsData = true;
          break;
      }
    },
    showOccurrence(obj) {
      console.log("Obj.type", obj);
      switch (obj.type) {
        case "forwarding_protocol":
          this.$store.dispatch("ForwardingProtocols/openViewModal", obj);
          break;
        case "incident_report":
          this.$store.dispatch("IncidentReports/openViewModal", obj);
          break;
        case "protective_measure":
          this.$store.dispatch("ProtectiveMeasures/openViewModal", obj);
          break;
        case "lawsuit":
          this.$store.dispatch("Lawsuits/openViewModal", obj);
          break;
        case "denunciation":
          this.$store.dispatch("Denunciations/openViewModal", obj);
          break;
      }
    },
  },
};
</script>
<style>
.tab_menu {
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
}
.tab_new {
  background-color: #f8f8f8;
  border-right: 1px solid #ffffff;
}
.active_new {
  background-color: #62146a;
  color: #fff;
  font-size: 1.1em;
}
</style>
