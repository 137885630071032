import { getAxios, baseURL } from "./Api";

export async function getDenunciationAggressors(filters) {
  const api = getAxios(baseURL["sigim_api"]);
  let url = `denunciation_agressors`;
  return api.get(url, { params: filters });
}

export async function getDenunciationAggressorById(_id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.get(`denunciation_agressors/${_id}`);
}

export async function createDenunciationAggressor(denunciation_aggressor) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.post("/denunciation_agressors?many=true", denunciation_aggressor);
}

export async function destroyDenunciationAggressor(id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.delete(`denunciation_agressors/${id}`);
}

export async function updateDenunciationAggressor(id, denunciation_aggressor) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.put(`denunciation_agressors/${id}`, denunciation_aggressor);
}
