<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="denunciation.id != null"
            text="Editar Denúncia"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Denúncia"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Denunciations/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div>
          <BaseInput
            id="número"
            input-name="número"
            input-type="text"
            show-label
            required
            label-text="número"
            :value="denunciation.number"
            dispatch="Denunciations/setNumber"
          />
        </div>
        <div>
          <Multiselect
            id="select-source-system"
            select-placeholder="Sistema de Origem"
            label-text="Sistema de Origem"
            close-on-select
            show-label
            required
            :value="source_system"
            :options="source_systems"
            :dispatch="setSourceSystem"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Adicionar Tipo(s) de Crime(s)"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <div
            class="grid grid-cols-1 gap-4 mt-2"
            v-for="(crime, index) in crime_types_attributes"
            :key="index"
          >
            <div class="flex">
              <div class="flex-grow mr-3">
                <Multiselect
                  :close-on-select="true"
                  show-label
                  clear-on-select
                  select-placeholder="Selecione a opção desejada"
                  label-text="Tipos de Crime"
                  :multiple-option="false"
                  :value="crime_type_attributes[index]"
                  :options="mountCrimeTypes(index)"
                  required
                  :dispatch="setCrimeTypes"
                />
              </div>
              <div class="mt-5 flex-none" v-if="crime_types_attributes.length > 1">
                <a href="javascript:void(0)" class="text-purple underline" @click="deleteCrimeType(index)">
                  <AppMaterialIcon icon-name="delete" icon-size="36" class="align-middle" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-span-4 mt-5" v-if="crime_types_attributes.length > 1">
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </div>
        </div>
        <div>
          <a href="javascript:void(0)" class="text-purple" @click="addCrimeType">
            <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
            <BaseText
              text="Adicionar Tipo de Crime"
              type-text="custom"
              weight="medium"
              size="sm"
              class="align-middle mr-2"
            />
          </a>
        </div>
        <div id="loading-screen" v-show="loading">
          <p class="loading-text">Carregando dados, por favor, aguarde<span class="loading-dots"></span></p>
          <div class="loader"></div>
        </div>
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Adicionar Vítima(s)"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <div class="grid grid-cols-1 gap-4 mt-2" v-for="(victim, index) in victims_attributes" :key="index">
            <div class="flex">
              <div class="flex-grow mr-3">
                <BaseInput
                  id="name"
                  input-name="Buscar por Nome"
                  input-type="text"
                  show-label
                  required
                  label-text="Buscar por Nome"
                  :value="params_people_victim[index].name_victim"
                  :payload="{ index }"
                  dispatch="Denunciations/filterVictimByName"
                />
              </div>
              <div class="flex-grow mr-3">
                <BaseInput
                  id="cpf"
                  input-name="Buscar por Cpf"
                  input-type="text"
                  show-label
                  required
                  mask="###.###.###-##"
                  label-text="Buscar por Cpf"
                  :value="params_people_victim[index].cpf_victim"
                  :payload="{ index }"
                  dispatch="Denunciations/filterVictimByCpf"
                />
              </div>
              <div class="flex-none mt-5">
                <a
                  href="javascript:void(0)"
                  class="text-purple underline"
                  @click="loadPeopleFiltered(index, 'victim')"
                >
                  <AppMaterialIcon
                    icon-name="search"
                    icon-size="36"
                    class="align-middle"
                    title="Procurar registro"
                  />
                </a>
              </div>
            </div>
            <div class="flex">
              <div class="flex-grow mr-3">
                <Multiselect
                  id="select-victim"
                  select-placeholder="Vítima"
                  label-text="Vítima"
                  close-on-select
                  show-label
                  required
                  :value="victim_person_attributes[index]"
                  :options="mountPersonsVictims(index)"
                  :dispatch="setVictims"
                  :multiple-option="false"
                  class="mr-4"
                />
              </div>
              <div class="flex-none mt-5" v-if="victim_person_attributes[index].name != null">
                <a
                  href="javascript:void(0)"
                  class="text-purple underline"
                  @click="showVictimData(victim_person_attributes[index])"
                >
                  <AppMaterialIcon
                    icon-name="visibility"
                    icon-size="36"
                    class="align-middle"
                    title="Visualizar todas as informações do registro selecionado"
                  />
                </a>
              </div>
              <div class="flex-none mt-5" v-if="victims_attributes.length > 1">
                <a href="javascript:void(0)" class="text-purple underline" @click="deleteVictim(index)">
                  <AppMaterialIcon
                    icon-name="delete"
                    icon-size="36"
                    class="align-middle"
                    title="Deletar bloco de informações"
                  />
                </a>
              </div>
            </div>
            <div class="flex">
              <div class="col-span-4 mt-5" v-if="victims_attributes.length > 1">
                <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
              </div>
            </div>
          </div>
          <div class="col-span-4 mt-5" v-if="victims_attributes.length > 1">
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </div>
        </div>
        <div>
          <a href="javascript:void(0)" class="text-purple" @click="addVictim">
            <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
            <BaseText
              text="Adicionar Vítima"
              type-text="custom"
              weight="medium"
              size="sm"
              class="align-middle mr-2"
            />
          </a>
        </div>
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Adicionar Agressor(es)"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <div
            class="grid grid-cols-1 gap-4 mt-2"
            v-for="(aggressor, index) in aggressors_attributes"
            :key="index"
          >
            <div class="flex">
              <div class="flex-grow mr-3">
                <BaseInput
                  id="name"
                  input-name="name"
                  input-type="text"
                  show-label
                  required
                  label-text="Nome"
                  :value="aggressors_attributes[index].name"
                  :payload="{ index }"
                  dispatch="Denunciations/setDenunciationAggressorsName"
                />
              </div>
              <div
                class="flex-none mt-5"
                v-if="aggressors_attributes[index].name != null && aggressors_attributes[index].name != ''"
              >
                <a
                  href="javascript:void(0)"
                  class="text-purple underline"
                  @click="openModalDenunciationAggressorsView(index)"
                >
                  <AppMaterialIcon
                    icon-name="arrow_forward"
                    icon-size="36"
                    class="align-middle"
                    title="Informar dados complementares do Agressor"
                  />
                </a>
              </div>
              <div class="flex-none mt-5" v-if="aggressors_attributes.length > 1">
                <a href="javascript:void(0)" class="text-purple underline" @click="deleteAggressor(index)">
                  <AppMaterialIcon
                    icon-name="delete"
                    icon-size="36"
                    class="align-middle"
                    title="Deletar bloco de informações"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="col-span-4 mt-5" v-if="aggressors_attributes.length > 1">
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </div>
        </div>
        <div>
          <a href="javascript:void(0)" class="text-purple" @click="addAggressor">
            <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
            <BaseText
              text="Adicionar Agressor"
              type-text="custom"
              weight="medium"
              size="sm"
              class="align-middle mr-2"
            />
          </a>
        </div>
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Informar Tipos de Violência e Dependência e Motivação"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <Multiselect
            id="select-violence-type"
            select-placeholder="Tipo de Violência"
            label-text="Tipo de Violência"
            close-on-select
            show-label
            required
            :value="violence_type"
            :options="violenceTypes"
            :dispatch="setViolenceType"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div>
          <Multiselect
            id="select-violence-motivation"
            select-placeholder="Motivação"
            label-text="Motivação"
            close-on-select
            show-label
            required
            :value="violence_motivation"
            :options="violenceMotivations"
            :dispatch="setViolenceMotivation"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div>
          <Multiselect
            id="select-dependency"
            select-placeholder="Selecionar"
            label-text="Tipos de Dependência"
            :value="denunciation_aggressor_dependencies"
            close-on-select
            show-label
            required
            :options="dependency_types"
            :dispatch="setDenunciationAggressorDependencies"
            class="mr-5"
          />
        </div>
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Informar Data"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <BaseInput
            id="opening_date"
            input-name="opening_date"
            input-type="date"
            show-label
            required
            label-text="Data de Abertura"
            :value="denunciation.opening_date"
            dispatch="Denunciations/setOpeningDate"
          />
        </div>
        <div>
          <BaseInput
            id="closing_date"
            input-name="closing_date"
            input-type="date"
            show-label
            required
            label-text="Data de Encerramento"
            :value="denunciation.closing_date"
            dispatch="Denunciations/setClosingDate"
          />
        </div>
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Informar Dados de Endereço"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <BaseInput
            id="occurrence_cep"
            input-name="CEP da Ocorrência"
            input-type="text"
            show-label
            required
            mask="#####-###"
            label-text="CEP da Ocorrência"
            :value="denunciation.occurrence_cep"
            dispatch="Denunciations/setOccurrenceCep"
          />
        </div>
        <div>
          <Multiselect
            id="select-state"
            select-placeholder="Selecionar"
            label-text="Estado"
            :value="stateFiltered"
            close-on-select
            show-label
            required
            :multiple-option="false"
            :options="states"
            :dispatch="setState"
            class="mr-5"
          />
        </div>
        <div>
          <Multiselect
            id="select-city"
            select-placeholder="Selecionar"
            label-text="Cidade"
            :value="cityFiltered"
            close-on-select
            show-label
            required
            :multiple-option="false"
            :options="cities"
            :dispatch="setCity"
            class="mr-5"
          />
        </div>
        <div>
          <Multiselect
            id="select-neighborhood"
            select-placeholder="Selecionar"
            label-text="Bairro"
            :value="neighborhood"
            close-on-select
            show-label
            required
            :multiple-option="false"
            :options="neighborhoods"
            :dispatch="setNeighborhood"
            class="mr-5"
          />
        </div>
        <div>
          <BaseInput
            id="occurrence_street_name"
            input-name="Local da ocorrência"
            input-type="text"
            show-label
            required
            label-text="Nome do Local de Ocorrência"
            :value="denunciation.occurrence_place"
            dispatch="Denunciations/setOccurrencePlace"
          />
        </div>
        <div>
          <BaseInput
            id="occurrence_street_name"
            input-name="Nome da Rua de Ocorrência"
            input-type="text"
            show-label
            required
            label-text="Nome da Rua de Ocorrência"
            :value="denunciation.occurrence_street_name"
            dispatch="Denunciations/setOccurrenceStreetName"
          />
        </div>
        <div>
          <BaseInput
            id="occurrence_street_number"
            input-name="Número da Rua de Ocorrência"
            input-type="text"
            show-label
            required
            label-text="Número da Rua de Ocorrência"
            :value="denunciation.occurrence_street_number"
            dispatch="Denunciations/setOccurrenceStreetNumber"
          />
        </div>
        <div>
          <BaseInput
            id="occurrence_address_complement"
            input-name="Complemento do endereço da ocorrência"
            input-type="text"
            show-label
            required
            label-text="Complemento do endereço da ocorrência"
            :value="denunciation.occurrence_address_complement"
            dispatch="Denunciations/setOccurrenceAddressComplement"
          />
        </div>
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Informar Dados Complementares"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <AppRadioButton
            :values="[
              {
                name: 'justfied',
                value: true,
                description: 'Sim',
                checked: denunciation.justified === true,
              },
              {
                name: 'justfied',
                value: false,
                description: 'Não',
                checked: denunciation.justified === false,
              },
            ]"
            labelText="Justificado?"
            showLabel
            dispatch="Denunciations/setJustified"
          />
        </div>
        <div>
          <AppRadioButton
            :values="[
              {
                name: 'serviced_by_gavv',
                value: true,
                description: 'Sim',
                checked: denunciation.serviced_by_gavv === true,
              },
              {
                name: 'serviced_by_gavv',
                value: false,
                description: 'Não',
                checked: denunciation.serviced_by_gavv === false,
              },
            ]"
            labelText="Atendido pelo Gaav?"
            showLabel
            dispatch="Denunciations/setServicedByGavv"
          />
        </div>
        <div>
          <AppRadioButton
            :values="[
              {
                name: 'aggressor_have_access_firearm',
                value: true,
                description: 'Sim',
                checked: denunciation.aggressor_have_access_firearm === true,
              },
              {
                name: 'aggressor_have_access_firearm',
                value: false,
                description: 'Não',
                checked: denunciation.aggressor_have_access_firearm === false,
              },
            ]"
            labelText="Agressor possui acesso à arma de fogo?"
            showLabel
            dispatch="Denunciations/setAggressorHaveAccessFirearm"
          />
        </div>
        <div>
          <AppRadioButton
            :values="[
              {
                name: 'intends_criminally_represent',
                value: true,
                description: 'Sim',
                checked: denunciation.intends_criminally_represent === true,
              },
              {
                name: 'intends_criminally_represent',
                value: false,
                description: 'Não',
                checked: denunciation.intends_criminally_represent === false,
              },
            ]"
            labelText="Pretende representar criminalmente?"
            showLabel
            dispatch="Denunciations/setIntendsCriminallyRepresent"
          />
        </div>
        <div>
          <BaseTextArea
            active
            inputName="descrição"
            labelText="Descrição"
            showLabel
            rows="4"
            :value="denunciation.description"
            dispatch="Denunciations/setDescription"
          />
        </div>
        <div>
          <BaseTextArea
            active
            inputName="observação"
            labelText="Observação"
            showLabel
            rows="4"
            :value="denunciation.observation"
            dispatch="Denunciations/setObservation"
          />
        </div>
      </div>
      <ModalDenunciationAggressorsView :open="modalToggleDenunciationAggressors" />
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Denunciations/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="denunciation.id"
            text="Atualizar"
            typeButton="success"
            dispatch="Denunciations/update"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="Denunciations/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseText from "../components/BaseText.vue";
import BaseTextArea from "../components/forms/BaseTextArea.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppRadioButton from "../components/AppRadioButton.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import ModalDenunciationAggressorsView from "./ModalDenunciationAggressorsView.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    AppButtonIcon,
    AppButtonEmpty,
    AppMaterialIcon,
    AppRadioButton,
    BaseModal,
    BaseInput,
    BaseText,
    BaseTextArea,
    Label,
    ModalDenunciationAggressorsView,
    Multiselect,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("Denunciations/loadSourceSystems");
    this.$store.dispatch("Denunciations/loadDependencyTypes");
    this.$store.dispatch("CrimeTypes/loadCrimeType");
    this.$store.dispatch("ViolenceTypes/loadViolenceTypes");
    this.$store.dispatch("ViolenceMotivations/loadViolenceMotivations");
    this.$store.dispatch("Denunciations/loadStates");
    if (this.stateFiltered) {
      this.$store.dispatch("Denunciations/loadCitiesByStateId", this.stateFiltered.id);
    }
    if (this.cityFiltered) {
      this.$store.dispatch("Denunciations/loadNeighborhoods", this.cityFiltered.id);
    }
  },
  computed: {
    ...mapGetters({
      getErrorMessage: "People/getErrorMessage",
    }),
    ...mapState({
      denunciation: (state) => state.Denunciations.denunciation,
      states: (state) => state.Denunciations.states,
      stateFiltered: (state) => state.Denunciations.stateFiltered,
      cities: (state) => state.Denunciations.cities,
      cityFiltered: (state) => state.Denunciations.cityFiltered,
      neighborhoods: (state) => state.Denunciations.neighborhoods,
      neighborhood: (state) => state.Denunciations.occurrence_neighborhood,
      people: (state) => state.Denunciations.people,
      crimeTypes: (state) => state.CrimeTypes.filterCrimesTypes,
      violenceTypes: (state) => state.ViolenceTypes.list,
      violenceMotivations: (state) => state.ViolenceMotivations.list,
      victim: (state) => state.Denunciations.victim,
      aggressor: (state) => state.Denunciations.aggressor,
      crime_type: (state) => state.Denunciations.crime_type,
      violence_type: (state) => state.Denunciations.violence_type,
      violence_motivation: (state) => state.Denunciations.violence_motivation,
      source_system: (state) => state.Denunciations.source_system,
      source_systems: (state) => state.Denunciations.source_systems,
      dependency_types: (state) => state.Denunciations.dependency_types,
      denunciation_aggressor_dependencies: (state) => state.Denunciations.denunciation_aggressor_dependencies,
      crime_types_attributes: (state) => state.Denunciations.crime_types_attributes,
      crime_type_attributes: (state) => state.Denunciations.crime_type_attributes,
      victims_attributes: (state) => state.Denunciations.victims_attributes,
      victim_person_attributes: (state) => state.Denunciations.victim_person_attributes,
      aggressors_attributes: (state) => state.Denunciations.aggressors_attributes,
      aggressor_person_attributes: (state) => state.Denunciations.aggressor_person_attributes,
      modalToggleDenunciationAggressors: (state) => state.Denunciations.modalToggleDenunciationAggressors,

      params_people_victim: (state) => state.Denunciations.params_people_victim,
      params_people_aggressor: (state) => state.Denunciations.params_people_aggressor,

      victim_all: (state) => state.Denunciations.victim_all,
      aggressor_all: (state) => state.Denunciations.aggressor_all,

      loading: (state) => state.Denunciations.loading,
    }),
  },
  methods: {
    ...mapActions({
      setState: "Denunciations/setState",
      setCity: "Denunciations/setCity",
      setNeighborhood: "Denunciations/setNeighborhood",
      setVictim: "Denunciations/setVictim",
      setAggressor: "Denunciations/setAggressor",
      setCrimeType: "Denunciations/setCrimeType",
      setViolenceType: "Denunciations/setViolenceType",
      setViolenceMotivation: "Denunciations/setViolenceMotivation",
      setSourceSystem: "Denunciations/setSourceSystem",
      setDenunciationAggressorDependencies: "Denunciations/setDenunciationAggressorDependencies",
      setCrimeTypes: "Denunciations/setCrimeTypes",
      setVictims: "Denunciations/setVictims",
      filterVictimByCpf: "Denunciations/filterVictimByCpf",
      setAggressors: "Denunciations/setAggressors",
      filterAggressorByCpf: "Denunciations/filterAggressorByCpf",
    }),
    mountCrimeTypes(index) {
      return this.crimeTypes.map((crime) => {
        return { index, ...crime };
      });
    },
    mountPersonsVictims(index) {
      if (this.victim_all[index].value != null) {
        return this.victim_all[index].value.map((person) => {
          return { index, ...person };
        });
      }
    },
    mountPersonsAggressors(index) {
      if (this.aggressor_all[index].value != null) {
        return this.aggressor_all[index].value.map((person) => {
          return { index, ...person };
        });
      }
    },
    addCrimeType() {
      this.$store.dispatch("Denunciations/addCrimeTypes");
    },
    deleteCrimeType(index) {
      this.$store.dispatch("Denunciations/deleteCrimeTypes", index);
    },
    addVictim() {
      this.$store.dispatch("Denunciations/addVictims");
    },
    deleteVictim(index) {
      this.$store.dispatch("Denunciations/deleteVictims", index);
    },
    addAggressor() {
      this.$store.dispatch("Denunciations/addAggressors");
    },
    deleteAggressor(index) {
      this.$store.dispatch("Denunciations/deleteAggressors", index);
    },
    loadPeopleFiltered(index, value) {
      let payload = {
        index: index,
        value: value,
      };
      this.$store.dispatch("Denunciations/loadPeople", payload);
    },
    showVictimData(payload) {
      this.$store.dispatch("People/toggleModalViewPeople", payload);
    },
    openModalDenunciationAggressorsView(payload) {
      this.$store.dispatch("Denunciations/openModalDenunciationAggressors", payload);
    },
  },
};
</script>
<style>
/* START OF CODE - LOADING SCREEN */
#loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  flex-direction: column;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #62006a;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loading-text {
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.loading-dots::after {
  content: "";
  animation: loading-dots 2s infinite;
}

@keyframes loading-dots {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* END OF CODE - LOADING SCREEN */
</style>
