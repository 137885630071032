<template>
  <BaseCard :class="liClass">
    <div class="grid grid-cols-8 items-center">
      <div class="mt-4">
        <BaseText
          :text="denunciation.source_system_name"
          typeText="custom"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="purple"
          class="mr-10"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="denunciation.number"
          typeText="custom"
          mode="uppercase"
          weight="bold"
          size="x1"
          color="purple"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getCrimeTypes()"
          typeText="custom"
          :italic="false"
          mode="normal-case"
          size="sm"
          color="purple"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="denunciation.violence_type.name"
          typeText="custom"
          :italic="false"
          mode="normal-case"
          size="sm"
          color="purple"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="denunciation.violence_motivation.name"
          typeText="custom"
          :italic="false"
          mode="normal-case"
          size="sm"
          color="purple"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getVictims()"
          typeText="custom"
          :italic="false"
          mode="normal-case"
          size="sm"
          color="purple"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getAggressors()"
          typeText="custom"
          :italic="false"
          mode="normal-case"
          size="sm"
          color="purple"
        />
      </div>
      <div class="mt-4 justify-self-end">
        <AppButtonIcon iconName="visibility" dispatch="Denunciations/openViewModal" :payload="denunciation" />
        <AppButtonIcon iconName="edit" dispatch="Denunciations/openEditModal" :payload="denunciation" />
        <AppButtonIcon
          iconName="delete"
          dispatch="Denunciations/toggleModalDeleteDenunciation"
          :payload="denunciation"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    denunciation: {
      type: Object,
    },
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
    };
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    userAcces() {
      this.canAcces("user_index").then((resp) => {
        this.permit = resp;
      });
    },
    userDelete() {
      this.canAcces("user_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    userChange() {
      this.canAcces("user_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    getVictims() {
      let getFullVictims = "";
      if (this.denunciation.denunciation_victims.length > 0) {
        this.denunciation.denunciation_victims.forEach((item, index) => {
          getFullVictims += item.name;
          if (index !== this.denunciation.denunciation_victims.length - 1) {
            getFullVictims += "; ";
          }
        });
      } else {
        getFullVictims += "Não informado";
      }
      return getFullVictims;
    },
    getAggressors() {
      let getFullAggressors = "";
      if (this.denunciation.denunciation_aggressors.length > 0) {
        this.denunciation.denunciation_aggressors.forEach((item, index) => {
          getFullAggressors += item.name;
          if (index !== this.denunciation.denunciation_aggressors.length - 1) {
            getFullAggressors += "; ";
          }
        });
      } else {
        getFullAggressors += "Não informado";
      }
      return getFullAggressors;
    },
    getCrimeTypes() {
      let getFullCrimeTypes = "";
      if (this.denunciation.denunciation_crime_types.length > 0) {
        this.denunciation.denunciation_crime_types.forEach((item, index) => {
          getFullCrimeTypes += item.name;
          if (index !== this.denunciation.denunciation_crime_types.length - 1) {
            getFullCrimeTypes += "; ";
          }
        });
      } else {
        getFullCrimeTypes += "Não informado";
      }
      return getFullCrimeTypes;
    },
  },

  created() {
    this.userAcces();
    this.userChange();
    this.userDelete();
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-auto": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
        "cursor-pointer": true,
        "bg-white": true,
      };
    },
    iconClass() {
      let css = [];
      css.push("rounded-full");
      css.push("rounded-lg");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
  },
};
</script>

<style scoped>
.grids-cols-custom2 {
  grid-template-columns: 23% 15% 16% 21%;
}
.grid-cols-custom {
  grid-template-columns: 45% 15% 10% 15% 10%;
}
.min-w-1 {
  min-width: 3rem;
}
</style>
