import { getAxios, baseURL } from "./Api";

export async function getHandcaps() {
  const api = getAxios(baseURL["sigim_api"]);
  return api.get(`handicap_types`);
}

export async function createHandcaps(handcaps) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.post(`handicap_people?many=true`, handcaps);
}

export async function getHandcapsPeople() {
  const api = getAxios(baseURL["sigim_api"]);
  return api.get(`handicap_people`);
}

export async function getHandcapsByPerson(personId) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.get(`handicap_people?person_id=${personId}`);
}

export async function destroyHandcapsTypes(id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.delete(`handicap_people/${id}`);
}
