<template>
  <div class="m-5">
    <div id="person-data" class="grid grid-cols-5 gap-x-1 gap-y-6 mb-12">
      <div class="col-span-5">
        <div class="col-span-5">
          <BaseText text="Dados da Denúncia" type-text="title-forms" />
          <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        </div>
        <div class="grid grid-cols-5 gap-x-1 gap-y-6 mt-5">
          <div class="col-span-1">
            <AppTextField
              show-label
              label-text="Nº - Denúncia"
              :text="denunciation.number"
              type-text="topic-details"
            />
          </div>
          <div>
            <AppTextField
              show-label
              label-text="Sistema de Origem"
              :text="source_system.name"
              type-text="topic-details"
            />
          </div>
          <div>
            <AppTextField
              show-label
              label-text="Tipo da violência"
              :text="getViolenceType()"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-2">
            <AppTextField
              show-label
              label-text="Motivação da violência"
              :text="getViolenceMotivation()"
              type-text="topic-details"
            />
          </div>
          <div>
            <AppTextField
              show-label
              label-text="Data de abertura"
              :text="formattedDate(denunciation.opening_date)"
              type-text="topic-details"
            />
          </div>
          <div>
            <AppTextField
              show-label
              label-text="Data de encerramento"
              :text="formattedDate(denunciation.closing_date)"
              type-text="topic-details"
            />
          </div>
          <div class="col-span-5">
            <AppTextField
              show-label
              label-text="Descrição"
              :text="denunciation.description"
              type-text="topic-details"
              classe="break-words"
            />
          </div>
          <div class="col-span-5">
            <AppTextField
              show-label
              label-text="Observação"
              :text="denunciation.observation"
              type-text="topic-details"
              classe="break-words"
            />
          </div>
          <div class="col-span-5 pt-1 pb-1">
            <div>
              <BaseText text="Vítima(s)" type-text="title-forms" />
              <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
            </div>
            <div>
              <div v-if="victim_person_attributes.length > 0">
                <div
                  class="grid grid-cols-2"
                  :key="item.id"
                  :data-index="index"
                  v-for="(item, index) in victim_person_attributes"
                >
                  <div class="mt-4">
                    <AppTextField show-label label-text="Nome" :text="item.name" type-text="topic-details" />
                  </div>
                  <div class="mt-4">
                    <AppTextField
                      show-label
                      label-text="CPF"
                      :text="item.cpf | VMask('###.###.###-##')"
                      mask="###.###.###-##"
                      type-text="topic-details"
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="col-span-5">
                  <AppTextField
                    show-label
                    label-text="Não há vítima(s) associada(s) à essa denúncia."
                    type-text="topic-details"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-5 pt-1 pb-1">
            <div>
              <BaseText text="Agressor(es)" type-text="title-forms" />
              <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
            </div>
            <div>
              <div v-if="aggressors_attributes.length > 0">
                <div
                  class="grid grid-cols-2"
                  :key="item.id"
                  :data-index="index"
                  v-for="(item, index) in aggressors_attributes"
                >
                  <div class="mt-4">
                    <AppTextField show-label label-text="Nome" :text="item.name" type-text="topic-details" />
                  </div>
                  <div class="mt-4">
                    <AppTextField
                      show-label
                      label-text="RG"
                      :text="item.rg"
                      mask="###.###.###-##"
                      type-text="topic-details"
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="col-span-5">
                  <AppTextField
                    show-label
                    label-text="Não há vítima(s) associada(s) à essa denúncia."
                    type-text="topic-details"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-5 pt-1 pb-1">
            <div>
              <BaseText text="Tipo(s) de Crime" type-text="title-forms" />
              <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
            </div>
            <div>
              <div v-if="crime_type_attributes.length > 0">
                <div
                  class="grid grid-cols-1"
                  :key="item.id"
                  :data-index="index"
                  v-for="(item, index) in crime_type_attributes"
                >
                  <AppTextField show-label :text="item.name" type-text="topic-details" />
                </div>
              </div>
              <div v-else>
                <div class="col-span-5">
                  <AppTextField
                    show-label
                    label-text="Não há tipos de crime associado à essa denúncia"
                    type-text="topic-details"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-5 pt-1 pb-1">
            <div>
              <BaseText text="Tipo(s) de dependência associada" type-text="title-forms" />
              <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
            </div>
            <div>
              <div v-if="denunciation_aggressor_dependencies.length > 0">
                <div
                  class="grid grid-cols-5"
                  :key="item.id"
                  :data-index="index"
                  v-for="(item, index) in denunciation_aggressor_dependencies"
                >
                  <AppTextField show-label :text="item.name" type-text="topic-details" />
                </div>
              </div>
              <div v-else>
                <div class="col-span-5">
                  <AppTextField
                    show-label
                    label-text="Não há tipos de dependência associada à essa denúncia"
                    type-text="topic-details"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-3 gap-2 tab_menu">
      <div :class="[{ active_new: addressData }, 'tab_new', 'p-2']">
        <a href="javascript:void(0)" @click="openTab(1)"> Local Ocorrência </a>
      </div>
      <div :class="[{ active_new: additionalData }, 'tab_new', 'p-2']">
        <a href="javascript:void(0)" @click="openTab(2)"> Informações Adicionais </a>
      </div>
    </div>
    <div class="col-span-5 mt-5" v-if="this.addressData">
      <div class="col-span-5">
        <BaseText text="Informações de Endereço" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="grid grid-cols-5 gap-x-1 gap-y-6 mt-5">
        <div>
          <AppTextField show-label label-text="Estado" :text="getState()" type-text="topic-details" />
        </div>
        <div>
          <AppTextField show-label label-text="Cidade" :text="getCity()" type-text="topic-details" />
        </div>
        <div>
          <AppTextField show-label label-text="Bairro" :text="getNeighborhood()" type-text="topic-details" />
        </div>
        <div class="col-span-2">
          <AppTextField
            show-label
            label-text="Local Ocorrência"
            :text="denunciation.occurrence_place"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Nome da rua"
            :text="getStreetName()"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Número da rua"
            :text="getNumberStreetName()"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Complemento do Endereço"
            :text="getAddressComplement()"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="CEP"
            :text="getCep() | VMask('#####-###')"
            type-text="topic-details"
          />
        </div>
      </div>
    </div>

    <div class="col-span-5 mt-5" v-if="this.additionalData">
      <div class="col-span-5">
        <BaseText text="Informações Adicionais" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="grid grid-cols-4 gap-x-1 gap-y-6 mt-5">
        <div>
          <AppTextField show-label label-text="Justificado?" :text="justified()" type-text="topic-details" />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Atendido pelo Gaav?"
            :text="servicedByGavv()"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Agressor possui acesso à arma de fogo?"
            :text="aggressorHaveAccessFirearm()"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Pretende representar criminalmente?"
            :text="intendsCriminallyRepresent()"
            type-text="topic-details"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppTextField from "../../components/AppTextField.vue";
// import AppButtonColorful from "../../components/AppButtonColorful.vue";
// import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseText,
    // AppButtonColorful,
    // AppButtonEmpty,
    AppTextField,
  },
  data: () => ({
    addressData: true,
    additionalData: false,
  }),
  computed: {
    ...mapState({
      denunciation: (state) => state.Denunciations.denunciation,
      victim: (state) => state.Denunciations.victim,
      aggressor: (state) => state.Denunciations.aggressor,
      crime_type: (state) => state.Denunciations.crime_type,
      violence_type: (state) => state.Denunciations.violence_type,
      violence_motivation: (state) => state.Denunciations.violence_motivation,
      neighborhood: (state) => state.Denunciations.occurrence_neighborhood,
      cityFiltered: (state) => state.Denunciations.cityFiltered,
      stateFiltered: (state) => state.Denunciations.stateFiltered,
      protective_measures: (state) => state.Denunciations.protective_measures,
      source_system: (state) => state.Denunciations.source_system,
      denunciation_aggressor_dependencies: (state) => state.Denunciations.denunciation_aggressor_dependencies,
      victim_person_attributes: (state) => state.Denunciations.victim_person_attributes,
      aggressors_attributes: (state) => state.Denunciations.aggressors_attributes,
      crime_type_attributes: (state) => state.Denunciations.crime_type_attributes,
    }),
  },
  methods: {
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    justified() {
      return this.denunciation.justified ? "Sim" : "Não";
    },
    servicedByGavv() {
      return this.denunciation.serviced_by_gavv ? "Sim" : "Não";
    },
    aggressorHaveAccessFirearm() {
      return this.denunciation.aggressor_have_access_firearm ? "Sim" : "Não";
    },
    intendsCriminallyRepresent() {
      return this.denunciation.intends_criminally_represent ? "Sim" : "Não";
    },
    getNeighborhood() {
      return this.neighborhood ? this.neighborhood.name : "Não informado";
    },
    getCity() {
      return this.cityFiltered ? this.cityFiltered.name : "Não informado";
    },
    getState() {
      return this.stateFiltered ? this.stateFiltered.name : "Não informado";
    },
    getViolenceType() {
      return this.violence_type ? this.violence_type.name : "Não informado";
    },
    getViolenceMotivation() {
      return this.violence_motivation ? this.violence_motivation.name : "Não informado";
    },
    getStreetName() {
      return this.denunciation.occurrence_street_name
        ? this.denunciation.occurrence_street_name
        : "Não informado";
    },
    getNumberStreetName() {
      return this.denunciation.occurrence_street_number
        ? this.denunciation.occurrence_street_number
        : "Não informado";
    },
    getAddressComplement() {
      return this.denunciation.occurrence_address_complement
        ? this.denunciation.occurrence_address_complement
        : "Não informado";
    },
    getCep() {
      return this.denunciation.occurrence_cep ? this.denunciation.occurrence_cep : "Não informado";
    },
    openTab(typeTab) {
      switch (typeTab) {
        case 1:
          this.addressData = true;
          this.additionalData = false;
          break;
        case 2:
          this.addressData = false;
          this.additionalData = true;
          break;
      }
    },
  },
};
</script>
<style>
.tab_menu {
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
}
.tab_new {
  background-color: #f8f8f8;
  border-right: 1px solid #ffffff;
}
.active_new {
  background-color: #62146a;
  color: #fff;
  font-size: 1.1em;
}
</style>
