<template>
  <div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-center">
      <div class="mx-2 col-span-2">
        <BaseText
          text="Denúncias"
          typeText="custom"
          weight="semibold"
          :italic="false"
          size="3xl"
          color="purple"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          id="btn-add-denunciations"
          showIcon
          iconName="add"
          text="Novo Registro"
          dispatch="Denunciations/toggleModalDenunciations"
          typeButton="primary"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-4 justify-between p-4 items-end">
      <div class="mx-2">
        <BaseLabel
          text="Filtrar por número da Denúncia"
          typeText="custom"
          weight="normal"
          :italic="false"
          size="xs"
        />
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Digite número da Denúncia"
          dispatch="Denunciations/filterListByNumber"
          :value="number"
        />
      </div>
      <div class="mx-2">
        <BaseLabel
          text="Filtrar por nome da vítima"
          typeText="custom"
          weight="normal"
          :italic="false"
          size="xs"
        />
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Digite nome da vítima"
          dispatch="Denunciations/filterListByNameVictim"
          :value="victim_name"
        />
      </div>
      <div class="mx-2">
        <BaseLabel
          text="Filtrar por nome do agressor"
          typeText="custom"
          weight="normal"
          :italic="false"
          size="xs"
        />
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Digite nome do agressor"
          dispatch="Denunciations/filterListByNameAggressor"
          :value="aggressor_name"
        />
      </div>
      <div class="mx-2">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          label-text="Filtrar por Tipo de Sistema de Origem"
          select-placeholder="Selecione uma opção"
          :multiple-option="false"
          :value="source_system"
          :options="source_systems"
          :dispatch="filterListBySourceSystem"
        />
      </div>
      <div class="mx-2 pt-4">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          label-text="Filtrar por Tipo de Crime"
          select-placeholder="Selecione uma opção"
          :multiple-option="false"
          :value="crime_type"
          :options="crimeTypes"
          :dispatch="filterListByCrimeType"
        />
      </div>
      <div class="mx-2 pt-4">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          label-text="Filtrar por Tipo de Violência"
          select-placeholder="Selecione uma opção"
          :multiple-option="false"
          :value="violence_type"
          :options="violenceTypes || []"
          :dispatch="filterListByViolenceType"
        />
      </div>
      <div class="mx-2 pt-4">
        <Multiselect
          :close-on-select="true"
          show-label
          clear-on-select
          label-text="Filtrar por Tipo de Motivação da Violência"
          select-placeholder="Selecione uma opção"
          :multiple-option="false"
          :value="violence_motivation"
          :options="violenceMotivations || []"
          :dispatch="filterListByViolenceMotivation"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 items-center p-4">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-denunciations"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Denunciations/loadDenunciations"
          typeButton="primary"
        />
      </div>
      <!-- <div class="mx-2 items-center justify-self-end" v-if="Denunciations.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-denunciations"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div> -->
    </div>
    <div class="p-4" v-if="Denunciations.length > 0">
      <div class="mx-2"><DenunciationsCards :data="Denunciations" /></div>
      <div class="mx-2" v-if="totalFilter > 0">
        <div class="m-6">Total de registros encontrados: {{ totalFilter }}</div>
        <div class="grid grid-cols-3 items-center">
          <div class="justify-self-end items-center">
            <AppButtonColorful
              v-if="actualPageFilter > 1"
              id="btn-next"
              typeButton="primary"
              showIcon
              right
              iconName="arrow_back_ios"
              :text="actualPageFilter - 1"
              :payload="pagesFilter[actualPageFilter - 2]"
              dispatch="Denunciations/getPageFilter"
            />
          </div>
          <div class="justify-self-center items-center">
            <Multiselect
              id="select-page"
              select-placeholder="Páginas"
              close-on-select
              :value="pagesFilter[actualPageFilter - 1]"
              :options="pagesFilter"
              :dispatch="getPageFilter"
              :multiple-option="false"
              class="mr-4"
            />
          </div>
          <div class="justify-self-start items-center">
            <AppButtonColorful
              v-if="actualPageFilter < totalFilterPages"
              id="btn-next"
              typeButton="primary"
              showIcon
              iconName="arrow_forward_ios"
              :text="actualPageFilter + 1"
              :payload="pagesFilter[actualPageFilter]"
              dispatch="Denunciations/getPageFilter"
            />
          </div>
        </div>
      </div>
    </div>
    <ModalDenunciations :open="modalToggleDenunciations" />
    <ModalConfirmDeleteDenunciation :open="modalConfirmDeleteDenunciation" />
    <ModalViewDenunciation :open="modalViewDenunciation" />
    <ModalViewPeople :open="togglePeopleViewModal" />
  </div>
</template>

<script>
import ModalDenunciations from "./ModalDenunciations.vue";
import ModalViewDenunciation from "./datas/ModalViewDenunciation.vue";
import ModalViewPeople from "./ModalViewPeople.vue";
import ModalConfirmDeleteDenunciation from "../views/alerts/ModalConfirmDeleteDenunciation.vue";
import DenunciationsCards from "./list/DenunciationsCards.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseLabel from "../components/BaseLabel.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    DenunciationsCards,
    ModalViewDenunciation,
    BaseText,
    ModalDenunciations,
    ModalConfirmDeleteDenunciation,
    AppButtonColorful,
    BaseInput,
    BaseLabel,
    ModalViewPeople,
    Multiselect,
  },
  computed: {
    ...mapState({
      modalToggleDenunciations: (state) => state.Denunciations.modalToggleDenunciations,
      modalViewDenunciation: (state) => state.Denunciations.modalViewDenunciation,
      modalConfirmDeleteDenunciation: (state) => state.Denunciations.modalConfirmDeleteDenunciation,
      number: (state) => state.Denunciations.params.number,
      victim_name: (state) => state.Denunciations.params.victim_name,
      aggressor_name: (state) => state.Denunciations.params.aggressor_name,
      source_system: (state) => state.Denunciations.params.source_system,
      crime_type: (state) => state.Denunciations.params.crime_type,
      violence_type: (state) => state.Denunciations.params.violence_type,
      violence_motivation: (state) => state.Denunciations.params.violence_motivation,
      Denunciations: (state) => state.Denunciations.filterDenunciations,
      totalFilter: (state) => state.Denunciations.totalFilter,
      totalPerPage: (state) => state.Denunciations.totalPerPage,
      offsetFilter: (state) => state.Denunciations.offsetFilter,
      pagesFilter: (state) => state.Denunciations.pagesFilter,
      actualPageFilter: (state) => state.Denunciations.actualPageFilter,
      totalFilterPages: (state) => state.Denunciations.totalFilterPages,
      togglePeopleViewModal: (state) => state.People.togglePeopleViewModal,
      source_systems: (state) => state.Denunciations.source_systems,
      crimeTypes: (state) => state.CrimeTypes.filterCrimesTypes,
      violenceTypes: (state) => state.ViolenceTypes.list,
      violenceMotivations: (state) => state.ViolenceMotivations.list,
    }),
  },
  async created() {
    this.$store.dispatch("Denunciations/loadSourceSystems");
    this.$store.dispatch("CrimeTypes/loadCrimeType");
    this.$store.dispatch("ViolenceTypes/loadViolenceTypes");
    this.$store.dispatch("ViolenceMotivations/loadViolenceMotivations");
  },
  methods: {
    ...mapActions({
      clearFields() {
        this.$store.dispatch("Denunciations/clearQuery");
      },
      getPageFilter: "Denunciations/getPageFilter",
      filterListBySourceSystem: "Denunciations/filterListBySourceSystem",
      filterListByCrimeType: "Denunciations/filterListByCrimeType",
      filterListByViolenceType: "Denunciations/filterListByViolenceType",
      filterListByViolenceMotivation: "Denunciations/filterListByViolenceMotivation",
    }),
    getNextPage() {
      let next = this.offsetFilter + this.totalPerPage;
      if (next < 0) {
        next = 0;
      }
      if (next > this.totalFilter) {
        next = this.totalFilter - this.totalPerPage;
      }
      this.$store.dispatch("Denunciations/setOffsetFilter", next);
    },
  },
};
</script>
