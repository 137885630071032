<template>
  <div>
    <label class="typo__label" for="ajax">Pessoa</label>
    <multiselect
      v-model="selectedPeople"
      id="ajax"
      label="name"
      track-by="id"
      placeholder="Digite parte do nome"
      open-direction="bottom"
      selectLabel=""
      :options="peopleAll"
      :multiple="false"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="true"
      :options-limit="300"
      :showLabels="false"
      :limit="1"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
      @search-change="searchPeople"
      @input="callBack(selectedPeople)"
    >
      <template slot="tag" slot-scope="{ option, remove }"
        ><span class="custom__tag"
          ><span>{{ option.name }}</span
          ><span class="font-extrabold text-lg text-purple" @click="remove(option)"> X </span></span
        ></template
      >
      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          v-if="selectedPeople.length"
          @mousedown.prevent.stop="clearAll(props.search)"
        ></div> </template
      ><span slot="noResult">Oops! Nenhum resiltado encontrado!.</span>
    </multiselect>
    <!-- <pre class="language-json"><code>{{ selectedPeople  }}</code></pre> -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
// import { ajaxFindCountry } from "./countriesApi";
import { mapActions } from "vuex";
import { getPeople } from "@/service/PeopleApi";

export default {
  components: {
    Multiselect,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    source: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedPeople: [],
      peopleAll: [],
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      setPersonKinship: "People/setPersonKinship",
    }),
    limitText(count) {
      return `e ${count} outras pessoas`;
    },
    searchPeople(query) {
      this.peopleAll = [];
      const params = {
        name: query,
        paginate: true,
        per_page: 5,
      };

      getPeople(params).then((response) => {
        // console.log("jhkhkj kjh kj k");
        // console.log("response People", response);
        this.peopleAll = response.data.people;
      });
    },
    clearAll() {
      this.selectedPeople = [];
    },
    callBack(payload) {
      // console.log(" TIPO ", typeof payload);
      var dispatch = "";
      // console.log("index", this.index);
      // console.log("payload", payload);
      // console.log("this.source", this.source);
      if (this.source == 1) {
        dispatch = "People/setPersonKinshipNew";
      }
      if (this.source == 2) {
        dispatch = "People/setPersonKinship";
      }
      // console.log("dispatch", dispatch);
      let data = {
        index: this.index,
        id: payload.id,
        name: payload.name,
      };
      // console.log("data", data);
      this.$store.dispatch(dispatch, data);
    },
  },
};
</script>
