import {
  createDenunciation,
  getDenunciations,
  getDenunciationById,
  updateDenunciation,
  destroyDenunciation,
} from "../../service/DenunciationApi";
import { getPeople } from "../../service/PeopleApi";
import { getStates } from "../../service/StatesApi";
import { getCitiesByStateId } from "../../service/CityApi";
import { getNeighborhoodsByCityId } from "../../service/NeighborhoodsApi";
import { getSourceSystems } from "../../service/SourceSystemsApi";
import { getDependencyTypes } from "../../service/DependencyTypesApi";
import {
  createDenunciationAggressorDependency,
  destroyDenunciationAggressorDependency,
} from "../../service/DenunciationAggressorDependenciesApi";
import {
  createDenunciationCrimeType,
  updateDenunciationCrimeType,
} from "../../service/DenunciationCrimeTypesApi";
import { createDenunciationVictim, updateDenunciationVictim } from "../../service/DenunciationVictimsApi";
import {
  createDenunciationAggressor,
  updateDenunciationAggressor,
} from "../../service/DenunciationAggressorsApi";

import Vue from "vue";

const state = {
  denunciation: {
    id: null,
    victim_id: null,
    aggressor_id: null,
    crime_type_id: null,
    violence_type_id: null,
    violence_motivation_id: null,
    source_system_id: null,
    number: "",
    description: "",
    observation: "",
    opening_date: "",
    closing_date: "",
    occurrence_neighborhood_id: null,
    occurrence_street_name: "",
    occurrence_place: "",
    occurrence_street_number: "",
    occurrence_address_complement: "",
    occurrence_cep: "",
    serviced_by_gavv: false,
    justified: false,
    aggressor_have_access_firearm: false,
    intends_criminally_represent: false,
  },
  params: {
    number: null,
    victim_name: null,
    aggressor_name: null,
    source_system: null,
    crime_type: null,
    violence_type: null,
    violence_motivation: null,
    order_by: "",
    limit: "",
    offset: "",
    page: "",
    paginate: true,
  },
  states: [],
  cities: [],
  neighborhoods: [],
  dependecy_types: [],
  denunciation_aggressor_dependencies: [],
  denunciation_aggressor_dependencies_to_edit: [],
  stateFiltered: null,
  cityFiltered: null,
  cityFilteredToShow: null,
  stateFilteredToShow: null,
  victim: null,
  aggressor: null,
  crime_type: null,
  violence_type: null,
  violence_motivation: null,
  source_system: null,
  occurrence_neighborhood: null,
  people: [],
  source_systems: [],
  filterDenunciations: [],
  denunciationId: 0,
  modalToggleDenunciations: false,
  modalConfirmDeleteDenunciation: false,
  modalViewDenunciation: false,
  modalToggleDenunciationAggressors: false,
  loading: false,
  crime_type_attributes: [
    {
      id: null,
      name: null,
    },
  ],
  crime_types_attributes: [
    {
      id: null,
      denunciation_id: null,
      crime_type_id: null,
    },
  ],
  //VICTIMS ATRIBUTS PARAMETERS
  victims_attributes: [
    {
      id: null,
      denunciation_id: null,
      person_id: null,
    },
  ],
  victim_person_attributes: [
    {
      id: null,
      name: null,
      cpf: null,
    },
  ],
  victim_all: [
    {
      value: null,
    },
  ],
  params_people_victim: [
    {
      cpf_victim: null,
      name_victim: null,
    },
  ],

  //AGRESSORS ATRIBUTS PARAMETERS
  aggressors_attributes_index: null,
  aggressors_attributes: [
    {
      id: null,
      denunciation_id: null,
      name: null,
      fonetical: null,
      nickname: null,
      rg: null,
      date_of_birth: null,
      mothers_name: null,
      naturalness: null,
      address: null,
      district: null,
      city: null,
      gender: null,
      sexual_orientation: null,
      gender_identity: null,
      number: null,
      cep: null,
      address_complement: null,
      neighborhood: null,
    },
  ],
  aggressor_person_attributes: [
    {
      id: null,
      name: null,
    },
  ],
  aggressor_all: [
    {
      value: null,
    },
  ],
  params_people_aggressor: [
    {
      cpf_aggressor: null,
      name_aggressor: null,
    },
  ],
  // - PARA PAGINACAO
  totalFilter: 0,
  totalPerPage: 10,
  offsetFilter: 0,
  totalFilterPages: 0,
  pagesFilter: [],
  actualPageFilter: 1,
};

const mutations = {
  // - PARA PAGINACAO --->
  setTotalFilterMutation: (state, total) => {
    state.totalFilter = total;
    let nrPages = total / state.totalPerPage;
    if (total % state.totalPerPage > 0) {
      nrPages = parseInt(nrPages) + 1;
    }
    state.totalFilterPages = nrPages;
    let offset = 0;
    state.pagesFilter = [];
    for (let n = 1; n <= nrPages; n++) {
      let pagina = {
        id: n - 1,
        name: "Página " + n + " de " + nrPages,
        number: n,
        offset: offset,
        page: n,
      };
      state.pagesFilter.push(pagina);
      offset += state.totalPerPage;
    }
  },
  setActualPageFilterMutation: (state, page) => {
    state.actualPageFilter = page;
  },
  setOffsetFilterMutation: (state, offset) => {
    state.offsetFilter = offset;
  },
  // <--- PARA PAGINACAO - FIM

  updateFilterByIdMutation: (state, denunciation) => {
    let key = state.filterDenunciations.findIndex((item) => {
      return denunciation.id === item.id;
    });
    state.filterDenunciations.splice(key, 1);
    state.filterDenunciations.splice(key, 0, denunciation);
  },
  setDenunciationsMutation: (state, denunciations) => {
    state.denunciations = denunciations;
    state.filterDenunciations = denunciations;
  },
  filterListByNumber: (state, input) => {
    state.params.number = input.value;
  },
  filterListByNameVictim: (state, input) => {
    state.params.victim_name = input.value;
  },
  filterListByNameAggressor: (state, input) => {
    state.params.aggressor_name = input.value;
  },
  filterListBySourceSystem: (state, input) => {
    state.params.source_system = input;
  },
  filterListByCrimeType: (state, input) => {
    state.params.crime_type = input;
  },
  filterListByViolenceType: (state, input) => {
    state.params.violence_type = input;
  },
  filterListByViolenceMotivation: (state, input) => {
    state.params.violence_motivation = input;
  },
  setDenunciationMutation: (state, denunciation) => {
    state.denunciation = denunciation;
  },
  setPeopleMutation: (state, people) => {
    state.people = people;
  },
  setSourceSystemsMutation: (state, source_systems) => {
    state.source_systems = source_systems;
  },
  setDependencyTypesMutation: (state, dependency_types) => {
    state.dependency_types = dependency_types;
  },
  toggleModalDenunciations: (state) => {
    state.modalToggleDenunciations = !state.modalToggleDenunciations;
  },
  toggleModalViewDenunciation: (state) => {
    state.modalViewDenunciation = !state.modalViewDenunciation;
  },
  toggleModalDeleteDenunciation: (state) => {
    state.modalConfirmDeleteDenunciation = !state.modalConfirmDeleteDenunciation;
  },
  denunciationForDeletion: (state, payload) => {
    state.denunciationId = payload.id;
  },
  setStatesMutation: (state, states) => {
    state.states = states;
  },
  setStateMutation: (state, stateFiltered) => {
    state.stateFiltered = stateFiltered;
  },
  setCitiesMutation: (state, cities) => {
    state.cities = cities;
  },
  setCityMutation: (state, cityFiltered) => {
    state.cityFiltered = cityFiltered;
  },
  setNeighborhoodsMutation: (state, neighborhoods) => {
    state.neighborhoods = neighborhoods;
  },
  setIdMutation: (state, id) => {
    state.denunciation.id = id;
  },
  setNumberMutation: (state, number) => {
    state.denunciation.number = number;
  },
  setDescriptionMutation: (state, description) => {
    state.denunciation.description = description;
  },
  setObservationMutation: (state, observation) => {
    state.denunciation.observation = observation;
  },
  setOpeningDateMutation: (state, opening_date) => {
    state.denunciation.opening_date = opening_date;
  },
  setClosingDateMutation: (state, closing_date) => {
    state.denunciation.closing_date = closing_date;
  },
  setOccurrencePlaceMutation: (state, occurrence_place) => {
    state.denunciation.occurrence_place = occurrence_place;
  },
  setOccurrenceStreetNameMutation: (state, occurrence_street_name) => {
    state.denunciation.occurrence_street_name = occurrence_street_name;
  },
  setOccurrenceStreetNumberMutation: (state, occurrence_street_number) => {
    state.denunciation.occurrence_street_number = occurrence_street_number;
  },
  setOccurrenceAddressComplementMutation: (state, occurrence_address_complement) => {
    state.denunciation.occurrence_address_complement = occurrence_address_complement;
  },
  setOccurrenceCepMutation: (state, occurrence_cep) => {
    state.denunciation.occurrence_cep = occurrence_cep;
  },
  setServicedByGavvMutation: (state, serviced_by_gavv) => {
    state.denunciation.serviced_by_gavv = serviced_by_gavv;
  },
  setJustifiedMutation: (state, justified) => {
    state.denunciation.justified = justified;
  },
  setIntendsCriminallyRepresentMutation: (state, intends_criminally_represent) => {
    state.denunciation.intends_criminally_represent = intends_criminally_represent;
  },
  setDenunciationAggressorDependenciesMutation: (state, denunciation_aggressor_dependencies) => {
    state.denunciation_aggressor_dependencies = denunciation_aggressor_dependencies;
  },
  setAggressorHaveAccessFirearmMutation: (state, aggressor_have_access_firearm) => {
    state.denunciation.aggressor_have_access_firearm = aggressor_have_access_firearm;
  },
  setCityFilteredToShowMutation: (state, cityFilteredToShow) => {
    state.cityFilteredToShow = cityFilteredToShow;
  },
  setStateFilteredToShowMutation: (state, stateFilteredToShow) => {
    state.stateFilteredToShow = stateFilteredToShow;
  },
  setDenunciationAggressorDependenciesToEditMutation: (state, payload) => {
    state.denunciation_aggressor_dependencies_to_edit.push(payload);
  },
  setVictimMutation: (state, victim) => {
    state.denunciation.victim_id = victim.id;
    state.victim = victim;
  },
  setAggressorMutation: (state, aggressor) => {
    state.denunciation.aggressor_id = aggressor.id;
    state.aggressor = aggressor;
  },
  setNeighborhoodMutation: (state, neighborhood) => {
    if (neighborhood != null) {
      state.denunciation.occurrence_neighborhood_id = neighborhood.id;
      state.occurrence_neighborhood = neighborhood;
    } else {
      state.denunciation.occurrence_neighborhood_id = null;
      state.occurrence_neighborhood = null;
    }
  },
  setCrimeTypeMutation: (state, crime_type) => {
    state.denunciation.crime_type_id = crime_type.id;
    state.crime_type = crime_type;
  },
  setViolenceTypeMutation: (state, violence_type) => {
    if (violence_type) {
      state.denunciation.violence_type_id = violence_type.id;
    }
    state.violence_type = violence_type;
  },
  setViolenceMotivationMutation: (state, violence_motivation) => {
    if (violence_motivation) {
      state.denunciation.violence_motivation_id = violence_motivation.id;
    }
    state.violence_motivation = violence_motivation;
  },
  setSourceSystemMutation: (state, source_system) => {
    if (source_system) {
      state.denunciation.source_system_id = source_system.id;
    }
    state.source_system = source_system;
  },
  setLoadingMutation: (state, loading) => {
    state.loading = loading;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE DENUNCIATION_CRIME_TYPES <--
  setCrimeTypesMutation: (state, payload) => {
    let index = payload.index;
    let crime_type = payload;

    state.crime_types_attributes.forEach((crime, i) => {
      if (index === i) {
        crime.crime_type_id = crime_type.id;
      }
    });

    state.crime_type_attributes.forEach((crime, i) => {
      if (index === i) {
        crime.id = crime_type.id;
        crime.name = crime_type.name;
      }
    });
  },
  setCrimeTypesIdMutation: (state, payload) => {
    let index = payload.index;
    let crime_type = payload;

    state.crime_types_attributes.forEach((item, i) => {
      if (index === i) {
        item.id = crime_type.id_crime_type;
      }
    });
  },
  setCrimeTypesDenunciationIdMutation: (state, payload) => {
    let index = payload.index;
    let crime_type = payload;

    state.crime_types_attributes.forEach((item, i) => {
      if (index === i) {
        item.denunciation_id = crime_type.denunciation_id;
      }
    });
  },
  addCrimeTypes: (state) => {
    state.crime_types_attributes.push({
      id: null,
      denunciation_id: null,
      crime_type_id: null,
    });
  },
  deleteCrimeTypes: (state, index) => {
    state.crime_types_attributes.splice(index, 1);
  },
  addCrimeType: (state) => {
    state.crime_type_attributes.push({
      id: null,
      name: null,
    });
  },
  deleteCrimeType: (state, index) => {
    state.crime_type_attributes.splice(index, 1);
  },

  // --> MUTATIONS - FIELDS TO THE TABLE DENUNCIATION_VICTIMS <--
  setVictimsMutation: (state, payload) => {
    let index = payload.index;
    let victim = payload;

    state.victims_attributes.forEach((item, i) => {
      if (index === i) {
        item.person_id = victim.id;
      }
    });

    state.victim_person_attributes.forEach((iterator, i) => {
      if (index === i) {
        iterator.id = victim.id;
        iterator.name = victim.name;
        iterator.cpf = victim.cpf;
      }
    });
  },
  setVictimsIdMutation: (state, payload) => {
    let index = payload.index;
    let victim = payload;

    state.victims_attributes.forEach((item, i) => {
      if (index === i) {
        item.id = victim.id_denunciation_victim;
      }
    });
  },
  setVictimsDenunciationIdMutation: (state, payload) => {
    let index = payload.index;
    let victim = payload;

    state.victims_attributes.forEach((item, i) => {
      if (index === i) {
        item.denunciation_id = victim.denunciation_id;
      }
    });
  },
  addVictims: (state) => {
    state.victims_attributes.push({
      id: null,
      denunciation_id: null,
      person_id: null,
    });
  },
  deleteVictims: (state, index) => {
    state.victims_attributes.splice(index, 1);
  },
  addVictimPerson: (state) => {
    state.victim_person_attributes.push({
      id: null,
      name: null,
      cpf: null,
    });
  },
  deleteVictimPerson: (state, index) => {
    state.victim_person_attributes.splice(index, 1);
  },

  // --> MUTATIONS - FIELDS TO THE TABLE DENUNCIATION_VICTIMS <--
  filterVictimByCpfMutation: (state, payload) => {
    let index = payload.index;
    let victim = payload.value;

    state.params_people_victim.forEach((item, i) => {
      if (index === i) {
        item.cpf_victim = victim;
      }
    });
  },

  filterVictimByNameMutation: (state, payload) => {
    let index = payload.index;
    let victim = payload.value;

    state.params_people_victim.forEach((item, i) => {
      if (index === i) {
        item.name_victim = victim;
      }
    });
  },
  addFilterVictimByCpf: (state) => {
    state.params_people_victim.push({
      cpf_victim: null,
      name_victim: null,
    });
  },
  deleteFilterVictimByCpf: (state, index) => {
    state.params_people_victim.splice(index, 1);
  },

  // --> MUTATIONS - FIELDS TO THE TABLE DENUNCIATION_VICTIMS <--
  loadAllVictimsMutation: (state, payload) => {
    let index = payload.index;
    let victim = payload.people;

    state.victim_all.forEach((iterator, i) => {
      if (index === i) {
        iterator.value = victim;
      }
    });
  },
  addVictimAll: (state) => {
    state.victim_all.push({
      value: null,
    });
  },
  deleteVictimAll: (state, index) => {
    state.victim_all.splice(index, 1);
  },

  // --> MUTATIONS - FIELDS TO THE TABLE DENUNCIATION_AGGRESSORS <--
  setDenunciationAggressorsIndexMutation: (state, index) => {
    state.aggressors_attributes_index = index;
  },
  setAggressorsMutation: (state, payload) => {
    let index = payload.index;
    let aggressor = payload;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.id = aggressor.id;
        item.denunciation_id = aggressor.denunciation_id;
        item.name = aggressor.name;
        item.fonetical = aggressor.fonetical;
        item.nickname = aggressor.nickname;
        item.rg = aggressor.rg;
        item.date_of_birth = aggressor.date_of_birth;
        item.mothers_name = aggressor.mothers_name;
        item.naturalness = aggressor.naturalness;
        item.address = aggressor.address;
        item.district = aggressor.district;
        item.city = aggressor.city;
        item.gender = aggressor.gender;
        item.sexual_orientation = aggressor.sexual_orientation;
        item.gender_identity = aggressor.gender_identity;
        item.number = aggressor.number;
        item.cep = aggressor.cep;
        item.address_complement = aggressor.address_complement;
        item.neighborhood = aggressor.neighborhood;
      }
    });
  },
  setDenunciationAggressorsNameMutation: (state, payload) => {
    let index = payload.index;
    let name = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.name = name;
      }
    });
  },
  setDenunciationAggressorsFoneticalMutation: (state, payload) => {
    let index = payload.index;
    let fonetical = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.fonetical = fonetical;
      }
    });
  },
  setDenunciationAggressorsNicknameMutation: (state, payload) => {
    let index = payload.index;
    let nickname = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.nickname = nickname;
      }
    });
  },
  setDenunciationAggressorsRgMutation: (state, payload) => {
    let index = payload.index;
    let rg = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.rg = rg;
      }
    });
  },
  setDenunciationAggressorsDateOfBirthMutation: (state, payload) => {
    let index = payload.index;
    let date_of_birth = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.date_of_birth = date_of_birth;
      }
    });
  },
  setDenunciationAggressorsMothersNameMutation: (state, payload) => {
    let index = payload.index;
    let mothers_name = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.mothers_name = mothers_name;
      }
    });
  },
  setDenunciationAggressorsNaturalnessMutation: (state, payload) => {
    let index = payload.index;
    let naturalness = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.naturalness = naturalness;
      }
    });
  },
  setDenunciationAggressorsAddressMutation: (state, payload) => {
    let index = payload.index;
    let address = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.address = address;
      }
    });
  },
  setDenunciationAggressorsDistrictMutation: (state, payload) => {
    let index = payload.index;
    let district = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.district = district;
      }
    });
  },
  setDenunciationAggressorsCityMutation: (state, payload) => {
    let index = payload.index;
    let city = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.city = city;
      }
    });
  },
  setDenunciationAggressorsGenderMutation: (state, payload) => {
    let index = payload.index;
    let gender = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.gender = gender;
      }
    });
  },
  setDenunciationAggressorsSexualOrientationMutation: (state, payload) => {
    let index = payload.index;
    let sexual_orientation = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.sexual_orientation = sexual_orientation;
      }
    });
  },
  setDenunciationAggressorsGenderIdentityMutation: (state, payload) => {
    let index = payload.index;
    let gender_identity = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.gender_identity = gender_identity;
      }
    });
  },
  setDenunciationAggressorsNumberMutation: (state, payload) => {
    let index = payload.index;
    let number = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.number = number;
      }
    });
  },
  setDenunciationAggressorsCepMutation: (state, payload) => {
    let index = payload.index;
    let cep = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.cep = cep;
      }
    });
  },
  setDenunciationAggressorsAddressComplementMutation: (state, payload) => {
    let index = payload.index;
    let address_complement = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.address_complement = address_complement;
      }
    });
  },
  setDenunciationAggressorsNeighborhoodMutation: (state, payload) => {
    let index = payload.index;
    let neighborhood = payload.value;

    state.aggressors_attributes.forEach((item, i) => {
      if (index === i) {
        item.neighborhood = neighborhood;
      }
    });
  },
  addAggressors: (state) => {
    state.aggressors_attributes.push({
      id: null,
      denunciation_id: null,
      name: null,
      fonetical: null,
      nickname: null,
      rg: null,
      date_of_birth: null,
      mothers_name: null,
      naturalness: null,
      address: null,
      district: null,
      city: null,
      gender: null,
      sexual_orientation: null,
      gender_identity: null,
      number: null,
      cep: null,
      address_complement: null,
      neighborhood: null,
    });
  },
  deleteAggressors: (state, index) => {
    state.aggressors_attributes.splice(index, 1);
  },
  toggleModalDenunciationAggressors: (state) => {
    state.modalToggleDenunciationAggressors = !state.modalToggleDenunciationAggressors;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE DENUNCIATION_VICTIMS <--
  filterAggressorByCpfMutation: (state, payload) => {
    let index = payload.index;
    let aggressor = payload.value;

    state.params_people_aggressor.forEach((item, i) => {
      if (index === i) {
        item.cpf_aggressor = aggressor;
      }
    });
  },

  filterAggressorByNameMutation: (state, payload) => {
    let index = payload.index;
    let aggressor = payload.value;

    state.params_people_aggressor.forEach((item, i) => {
      if (index === i) {
        item.name_aggressor = aggressor;
      }
    });
  },
  addFilterAggressorByCpf: (state) => {
    state.params_people_aggressor.push({
      cpf_aggressor: null,
      name_aggressor: null,
    });
  },
  // deleteFilterAggressorByCpf: (state) => {
  //   state.params_people_aggressor.pop({
  //     cpf_aggressor: null,
  //     name_aggressor: null,
  //   });
  // },

  // --> MUTATIONS - FIELDS TO THE TABLE DENUNCIATION_VICTIMS <--
  loadAllAggressorsMutation: (state, payload) => {
    let index = payload.index;
    let aggressor = payload.people;

    state.aggressor_all.forEach((iterator, i) => {
      if (index === i) {
        iterator.value = aggressor;
      }
    });
  },
  addAggressorAll: (state) => {
    state.aggressor_all.push({
      value: null,
    });
  },
  deleteAggressorAll: (state) => {
    state.aggressor_all.pop({
      value: null,
    });
  },

  clearQuery: (state) => {
    state.denunciations = [];
    state.filterDenunciations = [];
    state.params.number = null;
    state.params.victim_name = "";
    state.params.aggressor_name = "";

    state.params.order_by = "";
    state.params.limit = "";
    state.params.offset = "";

    state.params.page = "";

    state.totalFilter = 0;
    state.totalPerPage = 10;
    state.offsetFilter = 0;
    state.totalFilterPages = 0;
    state.pagesFilter = [];
    state.actualPageFilter = 1;
  },
  clearFields: (state) => {
    state.victim = null;
    state.aggressor = null;
    state.crime_type = null;
    state.violence_type = null;
    state.violence_motivation = null;
    state.occurrence_neighborhood = null;
    state.cityFiltered = null;
    state.stateFiltered = null;
    state.source_system = null;
    state.denunciation.id = null;
    state.denunciation.number = "";
    state.denunciation.description = "";
    state.denunciation.observation = "";
    state.denunciation.opening_date = "";
    state.denunciation.closing_date = "";
    state.denunciation.occurrence_street_name = "";
    state.denunciation.occurrence_place = "";
    state.denunciation.occurrence_street_number = "";
    state.denunciation.occurrence_address_complement = "";
    state.denunciation.occurrence_cep = "";
    state.denunciation.serviced_by_gavv = false;
    state.denunciation.justified = false;
    state.denunciation.intends_criminally_represent = false;
    state.denunciation.aggressor_have_access_firearm = false;
    state.denunciation_aggressor_dependencies = [];
    state.denunciation_aggressor_dependencies_to_edit = [];
  },
  clearFieldsCrimeTypes: (state) => {
    state.crime_types_attributes = [
      {
        id: null,
        denunciation_id: null,
        crime_type_id: null,
      },
    ];
    state.crime_type_attributes = [
      {
        id: null,
        name: null,
      },
    ];
  },
  clearFieldsVictims: (state) => {
    state.victims_attributes = [
      {
        id: null,
        denunciation_id: null,
        person_id: null,
      },
    ];
    state.victim_person_attributes = [
      {
        id: null,
        name: null,
        cpf: null,
      },
    ];
    state.victim_all = [
      {
        value: null,
      },
    ];
    state.params_people_victim = [
      {
        cpf_victim: null,
        name_victim: null,
      },
    ];
  },
  clearFieldsAggressors: (state) => {
    state.aggressors_attributes = [
      {
        id: null,
        denunciation_id: null,
        name: null,
        fonetical: null,
        nickname: null,
        rg: null,
        date_of_birth: null,
        mothers_name: null,
        naturalness: null,
        address: null,
        district: null,
        city: null,
        gender: null,
        sexual_orientation: null,
        gender_identity: null,
        number: null,
        cep: null,
        address_complement: null,
        neighborhood: null,
      },
    ];
  },
};

const actions = {
  // --- PAGINAÇÃO >>>>>>>>>
  getPageFilter: ({ state, commit }, payload) => {
    if (payload != null) {
      const params = {
        number: state.params.number,
        victim_name: state.params.victim_name,
        aggressor_name: state.params.aggressor_name,
        source_system_id: state.params.source_system ? state.params.source_system.id : null,
        crime_type_name: state.params.crime_type ? state.params.crime_type.name : null,
        violence_type_id: state.params.violence_type ? state.params.violence_type.id : null,
        violence_motivation_id: state.params.violence_motivation ? state.params.violence_motivation.id : null,
        order_by: "number DESC",
        limit: state.totalPerPage,
        offset: payload.offset,
        page: payload.page,
        paginate: true,
      };
      getDenunciations(params)
        .then((response) => {
          commit("setDenunciationsMutation", response.data.denunciations);
          commit("setTotalFilterMutation", response.data.paginate.total_count);
          commit("setActualPageFilterMutation", payload.number);
        })
        .catch((e) => {
          console.log("error", e.response); //TODO: tratar esse error
        });
    }
  },
  setOffsetFilter: ({ commit }, payload) => {
    commit("setOffsetFilterMutation", payload);
  },
  // --- PAGINAÇÃO <<<<<<<<<
  loadDenunciations: ({ commit }, from) => {
    commit("setOffsetFilterMutation", 0);
    const params = {
      number: state.params.number,
      victim_name: state.params.victim_name,
      aggressor_name: state.params.aggressor_name,
      source_system_id: state.params.source_system ? state.params.source_system.id : null,
      crime_type_name: state.params.crime_type ? state.params.crime_type.name : null,
      violence_type_id: state.params.violence_type ? state.params.violence_type.id : null,
      violence_motivation_id: state.params.violence_motivation ? state.params.violence_motivation.id : null,
      order_by: "number DESC",
      limit: state.totalPerPage,
      offset: state.offsetFilter,
      paginate: true,
    };
    getDenunciations(params)
      .then((response) => {
        commit("setDenunciationsMutation", response.data.denunciations);
        commit("setTotalFilterMutation", response.data.paginate.total_count);
        commit("setActualPageFilterMutation", 1);
        if (parseInt(response.data.total, 10) < 1 && from != "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadPeople: ({ commit }, payload) => {
    commit("setLoadingMutation", true);
    const params = {
      cpf: null,
      name: null,
      order_by: "people.name ASC",
      limit: state.totalPerPage,
      offset: state.offsetFilter,
      paginate: true,
    };

    if (payload.value === "victim" && state.params_people_victim[payload.index] != null) {
      params.cpf = state.params_people_victim[payload.index].cpf_victim;
      params.name = state.params_people_victim[payload.index].name_victim;
    } else if (payload.value === "aggressor" && state.params_people_aggressor[payload.index] != null) {
      params.cpf = state.params_people_aggressor[payload.index].cpf_aggressor;
      params.name = state.params_people_aggressor[payload.index].name_aggressor;
    }
    getPeople(params)
      .then((response) => {
        if (response.data.people.length == 0) {
          commit("setLoadingMutation", false);
          Vue.$toast.warning("Nenhum registro encontrado para os filtros informados!");
        } else if (response.data.people.length == 1) {
          let objetoEncontrado = false;
          response.data.people = response.data.people.filter((person) => {
            const found_victim = state.victims_attributes.find((victim) => victim.person_id === person.id);
            if (found_victim) {
              objetoEncontrado = true;
              return false;
            }
            return true;
          });

          if (objetoEncontrado) {
            Vue.$toast.warning(
              "Os filtros informados retornaram como resultado apenas um registro, que já está sendo utilizado na criação dessa denúncia!"
            );
            commit("setLoadingMutation", false);
          } else {
            response.data.index = payload.index;
            if (payload.value === "victim") {
              commit("loadAllVictimsMutation", response.data);
            } else {
              commit("loadAllAggressorsMutation", response.data);
            }
            commit("setLoadingMutation", false);
            Vue.$toast.success("Dados carregados com sucesso, selecione a opção desejada!");
          }
        } else {
          response.data.people = response.data.people.filter((person) => {
            const found_victim = state.victims_attributes.find((victim) => victim.person_id === person.id);
            if (found_victim) {
              return false;
            }
            return true;
          });

          if (response.data.people.length === 0) {
            Vue.$toast.warning(
              "Os filtros informados retornaram como resultados apenas registros que já estão sendo utulizados na criação dessa denúncia!"
            );
            commit("setLoadingMutation", false);
          } else {
            response.data.index = payload.index;
            if (payload.value === "victim") {
              commit("loadAllVictimsMutation", response.data);
            } else {
              commit("loadAllAggressorsMutation", response.data);
            }
            commit("setLoadingMutation", false);
            Vue.$toast.success("Dados carregados com sucesso, selecione a opção desejada!");
          }
        }
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadSourceSystems: ({ commit }) => {
    getSourceSystems()
      .then((response) => {
        commit("setSourceSystemsMutation", response.data.source_systems);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadDependencyTypes: ({ commit }) => {
    getDependencyTypes()
      .then((response) => {
        commit("setDependencyTypesMutation", response.data.dependency_types);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  updateFilterById: ({ commit }, id) => {
    getDenunciationById(id)
      .then((response) => {
        let denunciation = response.data.denunciation;
        let updated = {
          aggressor: {
            name: denunciation.aggressor.name,
          },
          crime_type: {
            name: denunciation.crime_type.name,
          },
          id: denunciation.id,
          number: denunciation.number,
          protective_measure_count: denunciation.protective_measure_count,
          source_system_name: denunciation.source_system.name,
          victim: {
            name: denunciation.victim.name,
          },
          violence_motivation: {
            name: denunciation.violence_motivation.name,
          },
          violence_type: {
            name: denunciation.violence_type.name,
          },
        };
        commit("updateFilterByIdMutation", updated);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadStates: ({ commit }) => {
    getStates()
      .then((resp) => {
        commit("setStatesMutation", resp.data.states);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadCities: ({ commit, dispatch }, city_id) => {
    getCitiesByStateId(city_id)
      .then((response) => {
        dispatch("setCity", null);
        dispatch("setNeighborhood", null);
        commit("setCitiesMutation", response.data.cities);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadNeighborhoods: ({ commit, dispatch }, payload) => {
    let id = payload;
    getNeighborhoodsByCityId(id)
      .then((response) => {
        dispatch("setNeighborhood", null);
        commit("setNeighborhoodsMutation", response.data.neighborhoods);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  setCity: ({ commit, dispatch }, payload) => {
    commit("setCityMutation", payload);
    if (payload != null) {
      dispatch("loadNeighborhoods", payload.id);
    }
  },
  setState: ({ commit, dispatch }, payload) => {
    commit("setStateMutation", payload);
    dispatch("loadCities", payload.id);
  },
  setDenunciation: ({ commit }, payload) => {
    commit("setDenunciationMutation", payload);
  },
  loadData: async ({ commit, dispatch }, payload) => {
    await getDenunciationById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.denunciation.id);
        commit("setNumberMutation", response.data.denunciation.number);
        commit("setDescriptionMutation", response.data.denunciation.description);
        commit("setObservationMutation", response.data.denunciation.observation);
        commit("setViolenceTypeMutation", response.data.denunciation.violence_type);
        commit("setViolenceMotivationMutation", response.data.denunciation.violence_motivation);
        commit("setOpeningDateMutation", response.data.denunciation.opening_date);
        commit("setClosingDateMutation", response.data.denunciation.closing_date);
        commit("setDenunciationAggressorDependenciesMutation", response.data.denunciation.dependency_types);
        commit("setOccurrencePlaceMutation", response.data.denunciation.occurrence_place);
        commit("setNeighborhoodMutation", response.data.denunciation.occurrence_neighborhood);
        commit("setCityMutation", response.data.denunciation.occurrence_city);
        commit("setStateMutation", response.data.denunciation.occurrence_state);
        commit("setOccurrenceStreetNameMutation", response.data.denunciation.occurrence_street_name);
        commit("setOccurrenceStreetNumberMutation", response.data.denunciation.occurrence_street_number);
        commit(
          "setOccurrenceAddressComplementMutation",
          response.data.denunciation.occurrence_address_complement
        );
        commit("setOccurrenceCepMutation", response.data.denunciation.occurrence_cep);

        response.data.denunciation.serviced_by_gavv = response.data.denunciation.serviced_by_gavv
          ? response.data.denunciation.serviced_by_gavv
          : false;
        commit("setServicedByGavvMutation", response.data.denunciation.serviced_by_gavv);

        response.data.denunciation.justified = response.data.denunciation.justified
          ? response.data.denunciation.justified
          : false;
        commit("setJustifiedMutation", response.data.denunciation.justified);

        response.data.denunciation.aggressor_have_access_firearm = response.data.denunciation
          .aggressor_have_access_firearm
          ? response.data.denunciation.aggressor_have_access_firearm
          : false;
        commit(
          "setAggressorHaveAccessFirearmMutation",
          response.data.denunciation.aggressor_have_access_firearm
        );

        response.data.denunciation.intends_criminally_represent = response.data.denunciation
          .intends_criminally_represent
          ? response.data.denunciation.intends_criminally_represent
          : false;
        commit(
          "setIntendsCriminallyRepresentMutation",
          response.data.denunciation.intends_criminally_represent
        );

        commit("setSourceSystemMutation", response.data.denunciation.source_system);
        if (response.data.denunciation.denunciation_aggressor_dependencies.length > 0) {
          response.data.denunciation.denunciation_aggressor_dependencies.forEach((item) => {
            commit("setDenunciationAggressorDependenciesToEditMutation", item);
          });
        }
        if (response.data.denunciation.denunciation_victims.length > 0) {
          response.data.denunciation.denunciation_victims.forEach((item, i) => {
            item.index = i;
            item.denunciation_id = response.data.denunciation.id;
            if (i > 0) {
              dispatch("addVictims");
            }
            commit("setVictimsIdMutation", item);
            commit("setVictimsDenunciationIdMutation", item);
            dispatch("setVictims", item);
          });
        }
        if (response.data.denunciation.denunciation_aggressors.length > 0) {
          response.data.denunciation.denunciation_aggressors.forEach((item, i) => {
            item.index = i;
            item.denunciation_id = response.data.denunciation.id;
            if (i > 0) {
              dispatch("addAggressors");
            }
            commit("setAggressorsMutation", item);
          });
        }
        if (response.data.denunciation.denunciation_crime_types.length > 0) {
          response.data.denunciation.denunciation_crime_types.forEach((item, i) => {
            item.index = i;
            item.denunciation_id = response.data.denunciation.id;
            if (i > 0) {
              dispatch("addCrimeTypes");
            }
            commit("setCrimeTypesIdMutation", item);
            commit("setCrimeTypesDenunciationIdMutation", item);
            dispatch("setCrimeTypes", item);
          });
        }
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  filterListByNumber: ({ commit }, payload) => {
    commit("filterListByNumber", payload);
  },
  filterListByNameVictim: ({ commit }, payload) => {
    commit("filterListByNameVictim", payload);
  },
  filterListByNameAggressor: ({ commit }, payload) => {
    commit("filterListByNameAggressor", payload);
  },
  filterListBySourceSystem: ({ commit }, payload) => {
    commit("filterListBySourceSystem", payload);
  },
  filterListByCrimeType: ({ commit }, payload) => {
    commit("filterListByCrimeType", payload);
  },
  filterListByViolenceType: ({ commit }, payload) => {
    commit("filterListByViolenceType", payload);
  },
  filterListByViolenceMotivation: ({ commit }, payload) => {
    commit("filterListByViolenceMotivation", payload);
  },
  toggleModalDenunciations: ({ commit }) => {
    commit("toggleModalDenunciations");
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewDenunciation");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewDenunciation");
    commit("clearFields");
    commit("clearFieldsCrimeTypes");
    commit("clearFieldsVictims");
    commit("clearFieldsAggressors");
  },
  openEditModal: async ({ dispatch, commit }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalDenunciations");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalDenunciations");
    commit("clearFields");
    commit("clearFieldsCrimeTypes");
    commit("clearFieldsVictims");
    commit("clearFieldsAggressors");
  },
  toggleModalDeleteDenunciation: ({ commit }, payload) => {
    commit("toggleModalDeleteDenunciation");
    if (state.modalConfirmDeleteDenunciation) {
      commit("denunciationForDeletion", payload);
    } else {
      commit("denunciationForDeletion", null);
    }
  },
  setNumber: ({ commit }, payload) => {
    commit("setNumberMutation", payload.value);
  },
  setDescription: ({ commit }, payload) => {
    commit("setDescriptionMutation", payload.value);
  },
  setObservation: ({ commit }, payload) => {
    commit("setObservationMutation", payload.value);
  },
  setVictim: ({ commit }, payload) => {
    commit("setVictimMutation", payload);
  },
  setAggressor: ({ commit }, payload) => {
    commit("setAggressorMutation", payload);
  },
  setCrimeType: ({ commit }, payload) => {
    commit("setCrimeTypeMutation", payload);
  },
  setViolenceType: ({ commit }, payload) => {
    commit("setViolenceTypeMutation", payload);
  },
  setViolenceMotivation: ({ commit }, payload) => {
    commit("setViolenceMotivationMutation", payload);
  },
  setDenunciationAggressorDependencies: ({ commit }, payload) => {
    commit("setDenunciationAggressorDependenciesMutation", payload);
  },
  setOpeningDate: ({ commit }, payload) => {
    commit("setOpeningDateMutation", payload.value);
  },
  setClosingDate: ({ commit }, payload) => {
    commit("setClosingDateMutation", payload.value);
  },
  setOccurrencePlace: ({ commit }, payload) => {
    commit("setOccurrencePlaceMutation", payload.value);
  },
  setOccurrenceStreetName: ({ commit }, payload) => {
    commit("setOccurrenceStreetNameMutation", payload.value);
  },
  setOccurrenceStreetNumber: ({ commit }, payload) => {
    commit("setOccurrenceStreetNumberMutation", payload.value);
  },
  setOccurrenceAddressComplement: ({ commit }, payload) => {
    commit("setOccurrenceAddressComplementMutation", payload.value);
  },
  setOccurrenceCep: ({ commit }, payload) => {
    commit("setOccurrenceCepMutation", payload.value);
  },
  setServicedByGavv: ({ commit }, payload) => {
    commit("setServicedByGavvMutation", payload.value);
  },
  setJustified: ({ commit }, payload) => {
    commit("setJustifiedMutation", payload.value);
  },
  setIntendsCriminallyRepresent: ({ commit }, payload) => {
    commit("setIntendsCriminallyRepresentMutation", payload.value);
  },
  setAggressorHaveAccessFirearm: ({ commit }, payload) => {
    commit("setAggressorHaveAccessFirearmMutation", payload.value);
  },
  setSourceSystem: ({ commit }, payload) => {
    commit("setSourceSystemMutation", payload);
  },
  setNeighborhood: ({ commit }, payload) => {
    commit("setNeighborhoodMutation", payload);
  },

  // --> ACTIONS - FIELDS TO THE DENUNCIATION_CRIME_TYPES <--
  setCrimeTypes: ({ commit }, payload) => {
    commit("setCrimeTypesMutation", payload);
  },
  addCrimeTypes: ({ commit }) => {
    commit("addCrimeTypes");
    commit("addCrimeType");
  },
  deleteCrimeTypes: ({ commit }, index) => {
    commit("deleteCrimeTypes", index);
    commit("deleteCrimeType", index);
  },

  // --> ACTIONS - FIELDS TO THE DENUNCIATION_VICTIMS <--
  setVictims: ({ commit }, payload) => {
    // if (payload.name) {
    //   payload.value = payload.name;
    //   commit("filterVictimByNameMutation", payload);
    // }
    // if (payload.cpf) {
    //   payload.value = payload.cpf;
    //   commit("filterVictimByCpfMutation", payload);
    // }
    commit("setVictimsMutation", payload);
  },
  addVictims: ({ commit }) => {
    commit("addVictims");
    commit("addVictimPerson");
    commit("addVictimAll");
    commit("addFilterVictimByCpf");
  },
  deleteVictims: ({ commit }, index) => {
    commit("deleteVictims", index);
    commit("deleteVictimPerson", index);
    commit("deleteVictimAll", index);
    commit("deleteFilterVictimByCpf", index);
  },
  filterVictimByCpf: ({ commit }, payload) => {
    commit("filterVictimByCpfMutation", payload);
  },
  filterVictimByName: ({ commit }, payload) => {
    commit("filterVictimByNameMutation", payload);
  },

  // --> ACTIONS - FIELDS TO THE DENUNCIATION_AGGRESSORS <--
  setAggressors: ({ commit }, payload) => {
    if (payload.name) {
      payload.value = payload.name;
      commit("filterAggressorByNameMutation", payload);
    }
    if (payload.cpf) {
      payload.value = payload.cpf;
      commit("filterAggressorByCpfMutation", payload);
    }
    commit("setAggressorsMutation", payload);
  },
  setDenunciationAggressorsName: ({ commit }, payload) => {
    commit("setDenunciationAggressorsNameMutation", payload);
  },
  setDenunciationAggressorsNickname: ({ commit }, payload) => {
    commit("setDenunciationAggressorsNicknameMutation", payload);
  },
  setDenunciationAggressorsFonetical: ({ commit }, payload) => {
    commit("setDenunciationAggressorsFoneticalMutation", payload);
  },
  setDenunciationAggressorsRg: ({ commit }, payload) => {
    commit("setDenunciationAggressorsRgMutation", payload);
  },
  setDenunciationAggressorsDateOfBirth: ({ commit }, payload) => {
    commit("setDenunciationAggressorsDateOfBirthMutation", payload);
  },
  setDenunciationAggressorsMothersName: ({ commit }, payload) => {
    commit("setDenunciationAggressorsMothersNameMutation", payload);
  },
  setDenunciationAggressorsNaturalness: ({ commit }, payload) => {
    commit("setDenunciationAggressorsNaturalnessMutation", payload);
  },
  setDenunciationAggressorsAddress: ({ commit }, payload) => {
    commit("setDenunciationAggressorsAddressMutation", payload);
  },
  setDenunciationAggressorsDistrict: ({ commit }, payload) => {
    commit("setDenunciationAggressorsDistrictMutation", payload);
  },
  setDenunciationAggressorsCity: ({ commit }, payload) => {
    commit("setDenunciationAggressorsCityMutation", payload);
  },
  setDenunciationAggressorsGender: ({ commit }, payload) => {
    commit("setDenunciationAggressorsGenderMutation", payload);
  },
  setDenunciationAggressorsSexualOrientation: ({ commit }, payload) => {
    commit("setDenunciationAggressorsSexualOrientationMutation", payload);
  },
  setDenunciationAggressorsGenderIdentity: ({ commit }, payload) => {
    commit("setDenunciationAggressorsGenderIdentityMutation", payload);
  },
  setDenunciationAggressorsNumber: ({ commit }, payload) => {
    commit("setDenunciationAggressorsNumberMutation", payload);
  },
  setDenunciationAggressorsCep: ({ commit }, payload) => {
    commit("setDenunciationAggressorsCepMutation", payload);
  },
  setDenunciationAggressorsAddressComplement: ({ commit }, payload) => {
    commit("setDenunciationAggressorsAddressComplementMutation", payload);
  },
  setDenunciationAggressorsNeighborhood: ({ commit }, payload) => {
    commit("setDenunciationAggressorsNeighborhoodMutation", payload);
  },
  addAggressors: ({ commit }) => {
    commit("addAggressors");
  },
  deleteAggressors: ({ commit }, index) => {
    commit("deleteAggressors", index);
  },
  openModalDenunciationAggressors: ({ commit }, payload) => {
    commit("setDenunciationAggressorsIndexMutation", payload);
    commit("toggleModalDenunciationAggressors");
  },
  closeModalDenunciationAggressors: ({ commit }) => {
    commit("toggleModalDenunciationAggressors");
  },

  updateDenunciationAggressorDependencies: ({ commit }, payload) => {
    let denunciation_aggressor_dependencies_to_edit = [];
    payload.dependenciesArrayToEdit.forEach((item) => {
      let array = {
        id: item.id,
        denunciation_id: item.denunciation_id,
        dependency_type_id: item.dependency_type_id,
      };
      denunciation_aggressor_dependencies_to_edit.push(array);
    });

    let denunciation_aggressor_dependencies = [];
    payload.dependenciesArray.forEach((item) => {
      let array = {
        dependency_type_id: item.id,
        denunciation_id: payload.denunciationId,
      };
      denunciation_aggressor_dependencies.push(array);
    });

    const objCreate = denunciation_aggressor_dependencies.filter(
      (edit) =>
        !denunciation_aggressor_dependencies_to_edit.find(
          (depen) => depen.dependency_type_id === edit.dependency_type_id
        )
    );

    const objDestroy = denunciation_aggressor_dependencies_to_edit.filter(
      (edit) =>
        !denunciation_aggressor_dependencies.find(
          (depen) => depen.dependency_type_id === edit.dependency_type_id
        )
    );

    if (objCreate.length > 0) {
      let objToSend = [];
      objCreate.forEach((item) => {
        let array = {
          denunciation_id: item.denunciation_id,
          dependency_type_id: item.dependency_type_id,
        };
        objToSend.push(array);
      });

      createDenunciationAggressorDependency(objToSend)
        .then(() => {})
        .catch((e) => {
          console.log(e);
        })
        .catch((e) => {
          Vue.$toast.error(e.response.data.error + "!");
        });
    }

    if (objDestroy.length > 0) {
      objDestroy.forEach((item) => {
        destroyDenunciationAggressorDependency(item.id)
          .then(() => {})
          .catch((e) => {
            console.log(e);
          })
          .catch((e) => {
            Vue.$toast.error(e.response.data.error + "!");
          });
      });
    }
    commit("toggleModalDenunciations");
  },
  save: ({ commit, dispatch }) => {
    createDenunciation(state.denunciation)
      .then((response) => {
        let denunciation_aggressor_dependencies = [];
        if (state.denunciation_aggressor_dependencies.length > 0) {
          state.denunciation_aggressor_dependencies.forEach((item) => {
            let obj = {
              denunciation_id: response.data.id,
              dependency_type_id: item.id,
            };
            denunciation_aggressor_dependencies.push(obj);
          });

          createDenunciationAggressorDependency(denunciation_aggressor_dependencies)
            .then(() => {})
            .catch((e) => {
              Vue.$toast.error("Não foi possivel cadastrar a denúncia! " + e);
              console.log(e);
            });
        }

        const allCrimeTypeIdsNotNull = state.crime_types_attributes.every((element) => {
          return element.crime_type_id !== null;
        });
        let denunciation_crime_types = [];
        if (state.crime_types_attributes.length > 0 && allCrimeTypeIdsNotNull) {
          state.crime_types_attributes.forEach((item) => {
            let obj = {
              denunciation_id: response.data.id,
              crime_type_id: item.crime_type_id,
            };
            denunciation_crime_types.push(obj);
          });

          createDenunciationCrimeType(denunciation_crime_types)
            .then(() => {})
            .catch((e) => {
              console.log(e);
            });
        }

        const allPersonIdsNotNull = state.victims_attributes.every((element) => {
          return element.person_id !== null;
        });
        let denunciation_victims = [];
        if (state.victims_attributes.length > 0 && allPersonIdsNotNull) {
          state.victims_attributes.forEach((item) => {
            let obj = {
              denunciation_id: response.data.id,
              person_id: item.person_id,
            };
            denunciation_victims.push(obj);
          });

          createDenunciationVictim(denunciation_victims)
            .then(() => {})
            .catch((e) => {
              console.log(e);
            });
        }

        const allNameAggressorIsNotNull = state.aggressors_attributes.every((element) => {
          return element.name !== null;
        });
        let denunciation_aggressors = [];
        if (state.aggressors_attributes.length > 0 && allNameAggressorIsNotNull) {
          state.aggressors_attributes.forEach((item) => {
            let obj = {
              denunciation_id: response.data.id,
              name: item.name,
              fonetical: item.fonetical,
              nickname: item.nickname,
              rg: item.rg,
              date_of_birth: item.date_of_birth,
              mothers_name: item.mothers_name,
              naturalness: item.naturalness,
              address: item.address,
              district: item.district,
              city: item.city,
              gender: item.gender,
              sexual_orientation: item.sexual_orientation,
              gender_identity: item.gender_identity,
              number: item.number,
              cep: item.cep,
              address_complement: item.address_complement,
              neighborhood: item.neighborhood,
            };
            denunciation_aggressors.push(obj);
          });

          createDenunciationAggressor(denunciation_aggressors)
            .then(() => {})
            .catch((e) => {
              console.log(e);
            });
        }

        Vue.$toast.success("Denúncia cadastrada com sucesso!");
        commit("toggleModalDenunciations");
        dispatch("Denunciations/loadDenunciations", null, { root: true });
        commit("clearFields");
        commit("clearFieldsCrimeTypes");
        commit("clearFieldsVictims");
        commit("clearFieldsAggressors");
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar a denúncia! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );
        console.log(e);
      });
  },
  update: ({ dispatch, commit }) => {
    updateDenunciation(state.denunciation.id, state.denunciation)
      .then((response) => {
        let payload = {
          dependenciesArrayToEdit: state.denunciation_aggressor_dependencies_to_edit,
          dependenciesArray: state.denunciation_aggressor_dependencies,
          denunciationId: state.denunciation.id,
        };
        dispatch("updateDenunciationAggressorDependencies", payload);

        if (state.crime_types_attributes.length > 0) {
          state.crime_types_attributes.forEach((item) => {
            if (item.id) {
              updateDenunciationCrimeType(item.id, item)
                .then(() => {})
                .catch((e) => {
                  Vue.$toast.error("Não foi possivel atualizar a denúncia! " + e.response.data.error);
                  console.log(e);
                });
            } else {
              let denunciation_crime_types = [];
              let obj = {
                id: item.id,
                denunciation_id: response.data.id,
                crime_type_id: item.crime_type_id,
              };
              denunciation_crime_types.push(obj);
              createDenunciationCrimeType(denunciation_crime_types)
                .then(() => {})
                .catch((e) => {
                  console.log(e);
                });
            }
          });
        }

        if (state.aggressors_attributes.length > 0) {
          state.aggressors_attributes.forEach((item) => {
            if (item.id) {
              updateDenunciationAggressor(item.id, item)
                .then(() => {})
                .catch((e) => {
                  Vue.$toast.error("Não foi possivel atualizar a denúncia! " + e.response.data.error);
                  console.log(e);
                });
            } else {
              let denunciation_aggressors = [];
              let obj = {
                id: item.id,
                denunciation_id: response.data.id,
                name: item.name,
                fonetical: item.fonetical,
                nickname: item.nickname,
                rg: item.rg,
                date_of_birth: item.date_of_birth,
                mothers_name: item.mothers_name,
                naturalness: item.naturalness,
                address: item.address,
                district: item.district,
                city: item.city,
                gender: item.gender,
                sexual_orientation: item.sexual_orientation,
                gender_identity: item.gender_identity,
                number: item.number,
                cep: item.cep,
                address_complement: item.address_complement,
                neighborhood: item.neighborhood,
              };
              denunciation_aggressors.push(obj);
              createDenunciationAggressor(denunciation_aggressors)
                .then(() => {})
                .catch((e) => {
                  console.log(e);
                });
            }
          });
        }

        if (state.victims_attributes.length > 0) {
          state.victims_attributes.forEach((item) => {
            if (item.id) {
              updateDenunciationVictim(item.id, item)
                .then(() => {})
                .catch((e) => {
                  Vue.$toast.error("Não foi possivel atualizar a denúncia! " + e.response.data.error);
                  console.log(e);
                });
            } else {
              let denunciation_victims = [];
              let obj = {
                id: item.id,
                denunciation_id: response.data.id,
                person_id: item.person_id,
              };
              denunciation_victims.push(obj);
              createDenunciationVictim(denunciation_victims)
                .then(() => {})
                .catch((e) => {
                  console.log(e);
                });
            }
          });
        }

        Vue.$toast.success("Denúncia atualizada com sucesso!");
        dispatch("Denunciations/updateFilterById", state.denunciation.id, { root: true });
        dispatch("Denunciations/loadDenunciations", null, { root: true });
        commit("clearFields");
        commit("clearFieldsCrimeTypes");
        commit("clearFieldsVictims");
        commit("clearFieldsAggressors");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyDenunciation(state.denunciationId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Denúncia deletada com sucesso!");
        dispatch("Denunciations/loadDenunciations", from, { root: true });
        commit("toggleModalDeleteDenunciation");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
