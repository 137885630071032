<template>
  <div class="view-body">
    <div id="sidebar" class="view-sidebar" v-if="open"><SideBar /></div>
    <div id="topbar" class="view-topbar">
      <a
        href="javascript:void(0)"
        id="btnopenclose"
        class="view-open-close mx-4 text-purple text-lg items-center"
        @click="openCloseMenu()"
        >&#9776;</a
      >
      <TopBar />
    </div>
    <div id="container" class="view-container">
      <router-view class="view"></router-view>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar.vue";
import TopBar from "./TopBar.vue";

export default {
  components: {
    SideBar,
    TopBar,
  },
  data() {
    return {
      open: true,
    };
  },
  methods: {
    openCloseMenu() {
      this.open = !this.open;
      if (this.open) {
        document.getElementById("container").style.marginLeft = "288px";
        document.getElementById("topbar").style.marginLeft = "288px";
        // document.getElementById("btnopenclose").style.left = "288px";
      } else {
        document.getElementById("container").style.marginLeft = "0px";
        document.getElementById("topbar").style.marginLeft = "0px";
        // document.getElementById("btnopenclose").style.left = "2px";
      }
    },
  },
};
</script>

<style>
.view-body {
  background-color: #f8f8f8;
  height: 100%;
  width: 100%;
  /* position: absolute; */
}

.view-container {
  margin-left: 288px;
  /* height: -webkit-fill-available;
  width: -webkit-fill-available;
  position: absolute; */
  /* background-color: #f8f8f8; */
}

.view-topbar {
  margin-left: 288px;
}

.view-open-close {
  position: absolute;
}
</style>
