<template>
  <div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-center">
      <div class="mx-2 col-span-2">
        <BaseText
          text="Perfis"
          typeText="custom"
          weight="semibold"
          :italic="false"
          size="3xl"
          color="purple"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permit"
          id="btn-add-profile"
          showIcon
          iconName="add"
          text="Novo Registro"
          dispatch="AccessControl/modalProfileOpen"
          typeButton="primary"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 items-center p-4">
      <div class="mx-2">
        <BaseInput
          showLabel
          labelText="Pesquisar por nome do perfil"
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Digite nome do perfil"
          dispatch="AccessControl/filterListByName"
          ref="name_profile"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 items-center p-4">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-user"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="AccessControl/getProfiles"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="profiles.length > 0">
        <a h:value="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-facilities"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>

    <div id="list-profiles">
      <div class="mx-12">
        <ProfilesCards :data="profiles" />
      </div>
    </div>
    <ModalFormProfileVue :open="modalProfileOpen" />
    <PermissionsViewVue :open="showModalPermissions" />
    <ModalConfirmDeleteProfileViewVue :open="modalConfirmDeleteProfile" />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ModalConfirmDeleteProfileViewVue from "../views/ModalConfirmDeleteProfileView.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ProfilesCards from "./list/ProfilesCards.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import PermissionsViewVue from "../views/PermissionsView.vue";
import ModalFormProfileVue from "./ModalFormProfile.vue";
import BaseText from "../components/BaseText.vue";

export default {
  name: "perfis",
  components: {
    AppButtonColorful,
    ProfilesCards,
    BaseInput,
    PermissionsViewVue,
    ModalFormProfileVue,
    ModalConfirmDeleteProfileViewVue,
    BaseText,
  },
  data() {
    return {
      permit: false,
    };
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    profileCreate() {
      this.canAcces("ability_profile_create").then((resp) => {
        this.permit = resp;
      });
    },
    clearFields() {
      this.$refs["name_profile"].value = "";
      this.$store.dispatch("AccessControl/clearQuery");
    },
    getTeste() {
      // console.log("TESTE", this.profiles);
      // console.log("permissions", this.permissions);
    },
    printPerm() {
      // console.log("permissions", this.perm);
    },
    ...mapActions({
      // getProfiles: "AccessControl/getProfiles",
      // getPermits: "AccessControl/getPermits",
      getProfPermits: "AccessControl/getProfilePermits",
    }),
    // ...mapGetters({
    //   getPermits: "AccessControl/getPermits",
    //   getProfPermits: "AccessControl/getProfilePermits",
    // }),
  },
  computed: {
    ...mapState({
      profiles: (state) => state.AccessControl.profiles,
      permissions: (state) => state.AccessControl.arrPermProf,
      permits: (state) => state.AccessControl.arrPermits,
      permiss: (state) => state.AccessControl.arrPermTmp,
      perm: (state) => state.AccessControl.perm,
      showModalPermissions: (state) => state.AccessControl.modalPerrmissionsOpen,
      modalProfileOpen: (state) => state.AccessControl.modalProfileOpen,
      modalConfirmDeleteProfile: (state) => state.AccessControl.modalConfirmDeleteProfile,
    }),
  },
  created() {
    // this.$store.dispatch("AccessControl/getPermits");
    // this.$store.dispatch("AccessControl/getProfilePermits");
    //console.log("Permissions", this.permissions);
    this.profileCreate();
    // this.$store.dispatch("AccessControl/getProfiles");
  },
  mounted() {
    //this.printPerm();
  },
};
</script>
<style lang=""></style>
