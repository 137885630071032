<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="forwardingProtocol.id != null"
            text="Editar Protocolo de Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Protocolo de Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="ForwardingProtocols/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div>
          <Multiselect
            id="select-person"
            select-placeholder="Selecione a Pessoa"
            label-text="Pessoa"
            close-on-select
            show-label
            required
            label="name"
            :value="person"
            :options="people"
            :dispatch="setPerson"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div>
          <Multiselect
            id="select-source-system"
            select-placeholder="Sistema de Origem"
            label-text="Sistema de Origem"
            close-on-select
            show-label
            required
            :value="source_system"
            :options="source_systems"
            :dispatch="setSourceSystem"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Selecionar Equipamento e Informar Data"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <Multiselect
            id="select-incident-report"
            select-placeholder="Selecione o tipo de acompanhamento"
            label-text="Tipo de Acompanhamento"
            close-on-select
            show-label
            required
            label="name"
            :value="accompanimentType"
            :options="accompanimentTypes"
            :dispatch="setAccompanimentType"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div>
          <Multiselect
            id="select-state"
            select-placeholder="Selecionar"
            label-text="Estado"
            :value="stateFiltered"
            close-on-select
            show-label
            required
            :multiple-option="false"
            :options="states"
            :dispatch="setState"
            class="mr-5"
          />
        </div>
        <div>
          <Multiselect
            id="select-city"
            select-placeholder="Selecionar"
            label-text="Cidade"
            :value="cityFiltered"
            close-on-select
            show-label
            required
            :multiple-option="false"
            :options="cities"
            :dispatch="setCity"
            class="mr-5"
          />
        </div>
        <div>
          <Multiselect
            id="select-neighborhood"
            select-placeholder="Selecionar"
            label-text="Bairro"
            :value="neighborhood"
            close-on-select
            show-label
            required
            :multiple-option="false"
            :options="neighborhoods"
            :dispatch="setNeighborhood"
            class="mr-5"
          />
        </div>
        <div>
          <Multiselect
            id="select-facilitie"
            :select-placeholder="placeholderEquipment"
            :label-text="labelEquipment"
            close-on-select
            show-label
            required
            label="name"
            :value="accompaniment_type_facility"
            :options="accompaniment_types_facilities"
            :dispatch="setAccompanimentTypeFacility"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div>
          <BaseInput
            id="forwarding_date"
            input-name="forwarding_date"
            input-type="date"
            show-label
            required
            label-text="Data de Encaminhamento"
            :value="forwardingProtocol.forwarding_date"
            dispatch="ForwardingProtocols/setForwardingDate"
          />
        </div>
        <div class="col-span-2 text-purple pb-2">
          <Label
            text="Informação Adicional"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            inputName="observação"
            labelText="Observação"
            showLabel
            rows="4"
            :value="forwardingProtocol.observation"
            dispatch="ForwardingProtocols/setObservation"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="ForwardingProtocols/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="forwardingProtocol.id != null"
            text="Atualizar"
            typeButton="success"
            dispatch="ForwardingProtocols/update"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="ForwardingProtocols/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import BaseTextArea from "../components/forms/BaseTextArea.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseTextArea,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
    Multiselect,
  },
  data: () => ({
    labelEquipment: "Equipamento",
    placeholderEquipment: "Selecione o Equipamento",
  }),
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("People/loadPeople");
    this.$store.dispatch("ForwardingProtocols/loadSourceSystems");
    // this.$store.dispatch("ForwardingProtocols/loadAccompanimentTypes");
    // if (this.accompanimentType) {
    //   let payload = {
    //     id: this.accompanimentType.id,
    //     typeFilter: 0,
    //   };
    //   this.$store.dispatch("ForwardingProtocols/loadAccompanimentTypeFacilityByType", payload);
    // }
    this.$store.dispatch("ForwardingProtocols/loadStates");
    if (this.stateFiltered) {
      this.$store.dispatch("ForwardingProtocols/loadCitiesByStateId", this.stateFiltered.id);
    }
    if (this.cityFiltered) {
      this.$store.dispatch("ForwardingProtocols/loadNeighborhoods", this.cityFiltered.id);
    }
  },
  computed: {
    ...mapGetters({
      getErrorMessage: "People/getErrorMessage",
    }),
    ...mapState({
      forwardingProtocol: (state) => state.ForwardingProtocols.forwardingProtocol,
      accompanimentTypes: (state) => state.ForwardingProtocols.accompaniment_types,
      accompanimentType: (state) => state.ForwardingProtocols.accompaniment_type,
      accompaniment_types_facilities: (state) => state.ForwardingProtocols.accompaniment_types_facilities,
      accompaniment_type_facility: (state) => state.ForwardingProtocols.accompaniment_type_facility,
      people: (state) => state.People.people,
      person: (state) => state.ForwardingProtocols.person,
      source_system: (state) => state.ForwardingProtocols.source_system,
      source_systems: (state) => state.ForwardingProtocols.source_systems,
      states: (state) => state.ForwardingProtocols.states,
      stateFiltered: (state) => state.ForwardingProtocols.stateFiltered,
      cities: (state) => state.ForwardingProtocols.cities,
      cityFiltered: (state) => state.ForwardingProtocols.cityFiltered,
      neighborhoods: (state) => state.ForwardingProtocols.neighborhoods,
      neighborhood: (state) => state.ForwardingProtocols.neighborhoodFiltered,
    }),
  },

  methods: {
    ...mapActions({
      setAccompanimentType: "ForwardingProtocols/setAccompanimentType",
      setAccompanimentTypeFacility: "ForwardingProtocols/setAccompanimentTypeFacility",
      setPerson: "ForwardingProtocols/setPerson",
      setSourceSystem: "ForwardingProtocols/setSourceSystem",
      setState: "ForwardingProtocols/setState",
      setCity: "ForwardingProtocols/setCity",
      setNeighborhood: "ForwardingProtocols/setNeighborhood",
    }),
    onChangeSelect(payload) {
      console.log("MUDANDO ", payload);
    },
  },
  watch: {
    // accompanimentType: function () {
    //   if (this.accompanimentType != null) {
    //     let payload = {
    //       id: this.accompanimentType.id,
    //       typeFilter: null,
    //     };
    //     this.$store.dispatch("ForwardingProtocols/loadAccompanimentTypeFacilityByType", payload);
    //   }
    // },
    stateFiltered: function () {
      let payload = {
        id: this.accompanimentType.id,
        typeFilter: 1,
      };
      if (this.accompanimentType != null) {
        this.$store.dispatch("ForwardingProtocols/loadAccompanimentTypeFacilityByType", payload);
      }
      this.labelEquipment = "Equipamento - Por Estado";
      this.placeholderEquipment = "Selecione o Equipamento - Por Estado";
    },
    cityFiltered: function () {
      let payload = {
        id: this.accompanimentType.id,
        typeFilter: 2,
      };
      if (this.accompanimentType != null) {
        this.$store.dispatch("ForwardingProtocols/loadAccompanimentTypeFacilityByType", payload);
      }
      this.labelEquipment = "Equipamento - Por Cidade";
      this.placeholderEquipment = "Selecione o Equipamento - Por Cidade";
    },
    neighborhood: function () {
      if (this.accompanimentType != null) {
        let payload = {
          id: this.accompanimentType.id,
          typeFilter: 3,
        };
        this.$store.dispatch("ForwardingProtocols/loadAccompanimentTypeFacilityByType", payload);
      }
      this.labelEquipment = "Equipamento - Por Bairro";
      this.placeholderEquipment = "Selecione o Equipamento - Por Bairro";
    },
  },
};
</script>
