<template>
  <div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-center">
      <div class="mx-2 col-span-2">
        <BaseText
          text="Equipamentos"
          typeText="custom"
          weight="semibold"
          :italic="false"
          size="3xl"
          color="purple"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          id="btn-add-incident_reports"
          showIcon
          iconName="add"
          text="Novo Registro"
          dispatch="Facilities/toggleModalFacilities"
          typeButton="primary"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 justify-between p-4 items-end">
      <div class="mx-2">
        <BaseLabel
          text="Filtrar por equipamento"
          typeText="custom"
          weight="normal"
          :italic="false"
          size="xs"
        />
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          label="Equipamento"
          showIcon
          input-placeholder="Digite Nome Equip."
          dispatch="Facilities/filterListByNameEquipment"
          :value="name"
        />
      </div>
      <div class="mx-2">
        <BaseLabel
          text="Filtrar por Tipo de Equipamento"
          typeText="custom"
          weight="normal"
          :italic="false"
          size="xs"
        />
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          label="Equipamento"
          showIcon
          input-placeholder="Digite Tipo Equip."
          dispatch="Facilities/filterListByTypeNameEquipment"
          :value="facility_type_name"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 justify-between p-4">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-facilities"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Facilities/loadFacilities"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="Facilities.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-facilities"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>

    <div class="p-4">
      <div class="mx-2"><FacilitiesCards :data="Facilities" /></div>
      <div class="mx-2" v-if="totalFilter > 0">
        <div class="m-6">Total de registros encontrados: {{ totalFilter }}</div>
        <div class="grid grid-cols-3 items-center">
          <div class="justify-self-end items-center">
            <AppButtonColorful
              v-if="actualPageFilter > 1"
              id="btn-next"
              typeButton="primary"
              showIcon
              right
              iconName="arrow_back_ios"
              :text="actualPageFilter - 1"
              :payload="pagesFilter[actualPageFilter - 2]"
              dispatch="Facilities/getPageFilter"
            />
          </div>
          <div class="justify-self-center items-center">
            <Multiselect
              id="select-page"
              select-placeholder="Páginas"
              close-on-select
              :value="pagesFilter[actualPageFilter - 1]"
              :options="pagesFilter"
              :dispatch="getPageFilter"
              :multiple-option="false"
              class="mr-4"
            />
          </div>
          <div class="justify-self-start items-center">
            <AppButtonColorful
              v-if="actualPageFilter < totalFilterPages"
              id="btn-next"
              typeButton="primary"
              showIcon
              iconName="arrow_forward_ios"
              :text="actualPageFilter + 1"
              :payload="pagesFilter[actualPageFilter]"
              dispatch="Facilities/getPageFilter"
            />
          </div>
        </div>
      </div>
    </div>
    <ModalFacilities :open="modalToggleFacilities" />
    <ModalConfirmDeleteFacilitie :open="modalConfirmDeleteFacilitie" />
    <ModalViewFacilitie :open="modalViewFacilitie" />
  </div>
</template>

<script>
import ModalFacilities from "./ModalFacilities.vue";
import ModalViewFacilitie from "./datas/ModalViewFacilitie.vue";
import FacilitiesCards from "./list/FacilitiesCards.vue";
import ModalConfirmDeleteFacilitie from "../views/alerts/ModalConfirmDeleteFacilitie.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseLabel from "../components/BaseLabel.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    FacilitiesCards,
    BaseText,
    ModalFacilities,
    ModalConfirmDeleteFacilitie,
    ModalViewFacilitie,
    AppButtonColorful,
    BaseInput,
    BaseLabel,
    Multiselect,
  },
  // beforeCreate() {
  //   this.$store.dispatch("Facilities/loadFacilities");
  // },
  data() {
    return {
      permit: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      getPageFilter: "Facilities/getPageFilter",
    }),
    facilityAcces() {
      this.canAcces("facility_create").then((resp) => {
        this.permit = resp;
      });
    },
    clearFields() {
      // this.$refs["name_facility"].value = "";
      // this.$refs["type_facility"].value = "";
      this.$store.dispatch("Facilities/clearQuery");
    },
    getNextPage() {
      // console.log(this.totalPerPage);
      // console.log(this.offsetFilter);
      let next = this.offsetFilter + this.totalPerPage;
      if (next < 0) {
        next = 0;
      }
      if (next > this.totalFilter) {
        next = this.totalFilter - this.totalPerPage;
      }
      //console.log(next);
      this.$store.dispatch("Facilities/setOffsetFilter", next);
    },
  },
  created() {
    // this.facilityCreate();
  },
  computed: {
    ...mapState({
      modalToggleFacilities: (state) => state.Facilities.modalToggleFacilities,
      modalConfirmDeleteFacilitie: (state) => state.Facilities.modalConfirmDeleteFacilitie,
      Facilities: (state) => state.Facilities.filterFacilities,
      modalViewFacilitie: (state) => state.Facilities.modalViewFacilitie,

      name: (state) => state.Facilities.params.name,
      facility_type_name: (state) => state.Facilities.params.facility_type_name,

      totalFilter: (state) => state.Facilities.totalFilter,
      totalPerPage: (state) => state.Facilities.totalPerPage,
      offsetFilter: (state) => state.Facilities.offsetFilter,
      pagesFilter: (state) => state.Facilities.pagesFilter,
      actualPageFilter: (state) => state.Facilities.actualPageFilter,
      totalFilterPages: (state) => state.Facilities.totalFilterPages,
    }),
  },
};
</script>
