import { getAxios, baseURL } from "./Api";

export async function getDenunciationCrimeTypes(filters) {
  const api = getAxios(baseURL["sigim_api"]);
  let url = `denunciation_victims`;
  return api.get(url, { params: filters });
}

export async function getDenunciationCrimeTypeId(_id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.get(`denunciation_victims/${_id}`);
}

export async function createDenunciationCrimeType(denunciation_crime_types) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.post("/denunciation_crime_types?many=true", denunciation_crime_types);
}

export async function destroyDenunciationCrimeType(id) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.delete(`denunciation_crime_types/${id}`);
}

export async function updateDenunciationCrimeType(id, denunciation_crime_type) {
  const api = getAxios(baseURL["sigim_api"]);
  return api.put(`denunciation_crime_types/${id}`, denunciation_crime_type);
}
