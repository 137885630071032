<template>
  <div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-center">
      <div class="mx-2 col-span-2">
        <BaseText
          text="Pessoas"
          typeText="custom"
          weight="semibold"
          :italic="false"
          size="3xl"
          color="purple"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          id="btn-add-incident_reports"
          showIcon
          iconName="add"
          text="Novo Registro"
          dispatch="People/openModal"
          typeButton="primary"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 justify-between p-4 items-end">
      <div class="mx-2">
        <Multiselect
          labelText="Pesquisar por Sistema"
          id="select-gender"
          select-placeholder="Pesquisar por sistema"
          close-on-select
          show-label
          :options="source_systems || []"
          :dispatch="filterListBySourceSystem"
          :multiple-option="false"
          :value="source_system || []"
        />
      </div>
      <div class="mx-2">
        <Multiselect
          showLabel
          labelText="Pesquisar por Nível de risco"
          id="select-risk"
          select-placeholder="Selecione Nível de risco"
          close-on-select
          show-label
          :options="risk_levels || []"
          :dispatch="filterListByRiskLevel"
          :multiple-option="false"
          :value="risk_level || []"
        />
      </div>
      <div class="mx-2">
        <BaseInput
          showLabel
          labelText="Pesquisar por Nome"
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Digite o nome"
          dispatch="People/filterListByName"
          :value="name"
        />
      </div>
      <div class="mx-2 mt-2">
        <BaseInput
          showLabel
          labelText="Pesquisar por CPF"
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Digite o CPF"
          dispatch="People/filterListByCpf"
          :value="cpf"
        />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center p-4">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-people"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="People/loadPeople"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="People.length > 0">
        <a h:value="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-facilities"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>

    <div class="p-4">
      <div class="mx-2"><PeopleCards :data="People" /></div>
      <div class="mx-2" v-if="totalFilter > 0">
        <div class="m-6">Total de registros encontrados: {{ totalFilter }}</div>
        <div class="grid grid-cols-3 items-center">
          <div class="justify-self-end items-center">
            <AppButtonColorful
              v-if="actualPageFilter > 1"
              id="btn-next"
              typeButton="primary"
              showIcon
              right
              iconName="arrow_back_ios"
              :text="actualPageFilter - 1"
              :payload="pagesFilter[actualPageFilter - 2]"
              dispatch="People/getPageFilter"
            />
          </div>
          <div class="justify-self-center items-center">
            <Multiselect
              id="select-page"
              select-placeholder="Páginas"
              close-on-select
              :value="pagesFilter[actualPageFilter - 1] || []"
              :options="pagesFilter || []"
              :dispatch="getPageFilter"
              :multiple-option="false"
              class="mr-4"
            />
          </div>
          <div class="justify-self-start items-center">
            <AppButtonColorful
              v-if="actualPageFilter < totalFilterPages"
              id="btn-next"
              typeButton="primary"
              showIcon
              iconName="arrow_forward_ios"
              :text="actualPageFilter + 1"
              :payload="pagesFilter[actualPageFilter]"
              dispatch="People/getPageFilter"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <pagination
      v-if="paginate.total_count"
      :offset="offset"
      :total="total"
      :limit="limit"
      @change-page="changePage"
    /> -->
    <ModalViewPeople :open="togglePeopleViewModal" />
    <ModalPeople :open="toggleModal" />
    <ModalConfirmDeletePeople :open="modalConfirmDeletePeople" />
  </div>
</template>

<script>
import ModalPeople from "./ModalPeople.vue";
import ModalViewPeople from "./ModalViewPeople.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import PeopleCards from "./list/PeopleCards.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ModalConfirmDeletePeople from "./ModalConfirmDeletePeople.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    PeopleCards,
    ModalViewPeople,
    ModalConfirmDeletePeople,
    BaseText,
    ModalPeople,
    Multiselect,
    AppButtonColorful,
    // Pagination,
    BaseInput,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
      risk_levels: [
        { id: 1, name: "NÃO CLASSIFICADO" },
        { id: 2, name: "SUPERADO" },
        { id: 3, name: "BAIXO" },
        { id: 4, name: "MÉDIO" },
        { id: 5, name: "ALTO" },
        { id: 6, name: "SUPER ALTO" },
      ],
    };
  },
  // beforeCreate() {
  //   this.$store.dispatch("People/loadPeople", this.offset);
  // },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      filterListByGenderIdentidy: "People/filterListByGenderIdentidy",
      filterListByRiskLevel: "People/filterListByRiskLevel",
      filterListBySourceSystem: "People/filterListBySourceSystem",
      loadSystems: "SourceSystems/loadSourceSystems",
      getPageFilter: "People/getPageFilter",
    }),
    peopleAcces() {
      this.canAcces("person_index").then((resp) => {
        this.permit = resp;
      });
    },
    peopleDelete() {
      this.canAcces("person_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    peopleChange() {
      this.canAcces("person_update").then((resp) => {
        // console.log("person_update", resp);
        this.permitUp = resp;
      });
    },
    changePage(value) {
      this.offset = value;
      this.$store.dispatch("People/loadPeople", value);
    },
    clearFields() {
      // this.$refs["name"].value = "";
      // this.$refs["gender_id"].value = "";
      // this.$refs["risk_level_id"].value = "";
      this.$store.dispatch("People/clearQuery");
    },
    getNextPage() {
      // console.log(this.totalPerPage);
      // console.log(this.offsetFilter);
      let next = this.offsetFilter + this.totalPerPage;
      if (next < 0) {
        next = 0;
      }
      if (next > this.totalFilter) {
        next = this.totalFilter - this.totalPerPage;
      }
      //console.log(next);
      this.$store.dispatch("People/setOffsetFilter", next);
    },
  },
  computed: {
    ...mapState({
      People: (state) => state.People.filterPeople,
      filter_people: (state) => state.People.filter_people,
      paginate: (state) => state.People.paginate,
      offset: (state) => state.People.offset,
      total: (state) => state.People.total,
      toggleModal: (state) => state.People.modalToggle,
      modalConfirmDeletePeople: (state) => state.People.modalConfirmDeletePerson,
      togglePeopleViewModal: (state) => state.People.togglePeopleViewModal,
      gendersIdentidy: (state) => state.GenderIdentidy.list,

      name: (state) => state.People.params.name,
      cpf: (state) => state.People.params.cpf,
      risk_level: (state) => state.People.params.risk_level,
      source_systems: (state) => state.SourceSystems.list,
      source_system: (state) => state.People.params.source_system,
      totalFilter: (state) => state.People.totalFilter,
      totalPerPage: (state) => state.People.totalPerPage,
      offsetFilter: (state) => state.People.offsetFilter,
      pagesFilter: (state) => state.People.pagesFilter,
      actualPageFilter: (state) => state.People.actualPageFilter,
      totalFilterPages: (state) => state.People.totalFilterPages,
    }),
  },
  created() {
    // console.log("people info", this.people);
    this.$store.dispatch("GenderIdentidy/loadGendersIdentidy");
    this.peopleAcces();
    this.peopleChange();
    this.peopleDelete();
    this.loadSystems();
  },
};
</script>
