<template>
  <div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between p-4 items-center">
      <div class="mx-2 col-span-2">
        <BaseText
          text="Medidas Protetivas Requeridas"
          typeText="custom"
          weight="semibold"
          :italic="false"
          size="3xl"
          color="purple"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          id="btn-add-incident_reports"
          showIcon
          iconName="add"
          text="Novo Registro"
          dispatch="ProtectiveMeasuresRequesteds/toggleModalProtectiveMeasuresRequesteds"
          typeButton="primary"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 justify-between p-4 items-end">
      <div class="mx-2">
        <BaseLabel
          text="Filtrar por número da Medida Protetiva"
          typeText="custom"
          weight="normal"
          :italic="false"
          size="xs"
        />
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Digite Número da M.P"
          dispatch="ProtectiveMeasuresRequesteds/filterListByNumberProtectiveMeasure"
          :value="protective_measure_number"
        />
      </div>
      <div class="mx-2">
        <BaseLabel
          text="Filtrar por número do Boletim de Ocorrência"
          typeText="custom"
          weight="normal"
          :italic="false"
          size="xs"
        />
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          input-placeholder="Digite Número do B.O"
          dispatch="ProtectiveMeasuresRequesteds/filterListByNumberIncidentReport"
          :value="incident_report_number"
        />
      </div>
    </div>

    <div id="tabs-button" class="grid grid-cols-2 items-center p-4">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-incident_reports"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="ProtectiveMeasuresRequesteds/loadProtectiveMeasuresRequesteds"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="ProtectiveMeasuresRequesteds.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-facilities"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>

    <div class="p-4">
      <div class="mx-2"><ProtectiveMeasuresRequestedsCards :data="ProtectiveMeasuresRequesteds" /></div>
      <div class="mx-2" v-if="totalFilter > 0">
        <div class="m-6">Total de registros encontrados: {{ totalFilter }}</div>
        <div class="grid grid-cols-3 items-center">
          <div class="justify-self-end items-center">
            <AppButtonColorful
              v-if="actualPageFilter > 1"
              id="btn-next"
              typeButton="primary"
              showIcon
              right
              iconName="arrow_back_ios"
              :text="actualPageFilter - 1"
              :payload="pagesFilter[actualPageFilter - 2]"
              dispatch="ProtectiveMeasuresRequesteds/getPageFilter"
            />
          </div>
          <div class="justify-self-center items-center">
            <Multiselect
              id="select-page"
              select-placeholder="Páginas"
              close-on-select
              :value="pagesFilter[actualPageFilter - 1]"
              :options="pagesFilter"
              :dispatch="getPageFilter"
              :multiple-option="false"
              class="mr-4"
            />
          </div>
          <div class="justify-self-start items-center">
            <AppButtonColorful
              v-if="actualPageFilter < totalFilterPages"
              id="btn-next"
              typeButton="primary"
              showIcon
              iconName="arrow_forward_ios"
              :text="actualPageFilter + 1"
              :payload="pagesFilter[actualPageFilter]"
              dispatch="ProtectiveMeasuresRequesteds/getPageFilter"
            />
          </div>
        </div>
      </div>
    </div>
    <ModalProtectiveMeasuresRequesteds :open="modalToggleProtectiveMeasuresRequesteds" />
    <ModalConfirmDeleteProtectiveMeasureRequested :open="modalConfirmDeleteProtectiveMeasureRequested" />
    <ModalEditProtectiveMeasureRequired :open="modalToggleEditProtectiveMeasuresRequesteds" />
  </div>
</template>

<script>
import ModalProtectiveMeasuresRequesteds from "./ModalProtectiveMeasuresRequesteds.vue";
import ModalEditProtectiveMeasureRequired from "./ModalEditProtectiveMeasureRequired.vue";
import ProtectiveMeasuresRequestedsCards from "./list/ProtectiveMeasuresRequestedsCards.vue";
import ModalConfirmDeleteProtectiveMeasureRequested from "./alerts/ModalConfirmDeleteProtectiveMeasureRequested.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseLabel from "../components/BaseLabel.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    ProtectiveMeasuresRequestedsCards,
    BaseText,
    ModalProtectiveMeasuresRequesteds,
    ModalEditProtectiveMeasureRequired,
    ModalConfirmDeleteProtectiveMeasureRequested,
    AppButtonColorful,
    BaseInput,
    BaseLabel,
    Multiselect,
  },
  data() {
    return {
      permit: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      getPageFilter: "ProtectiveMeasuresRequesteds/getPageFilter",
    }),
    protectiveMeasureRequestedsCreate() {
      this.canAcces("protective_measure_requested_create").then((resp) => {
        this.permit = resp;
      });
    },
    clearFields() {
      // this.$refs["protective_measure_number"].value = null;
      // this.$refs["number_incident_report"].value = null;
      this.$store.dispatch("ProtectiveMeasuresRequesteds/clearQuery");
    },
    getNextPage() {
      // console.log(this.totalPerPage);
      // console.log(this.offsetFilter);
      let next = this.offsetFilter + this.totalPerPage;
      if (next < 0) {
        next = 0;
      }
      if (next > this.totalFilter) {
        next = this.totalFilter - this.totalPerPage;
      }
      //console.log(next);
      this.$store.dispatch("ProtectiveMeasuresRequesteds/setOffsetFilter", next);
    },
  },
  created() {
    this.protectiveMeasureRequestedsCreate();
  },
  // beforeCreate() {
  //   this.$store.dispatch("ProtectiveMeasuresRequesteds/loadProtectiveMeasuresRequesteds");
  // },
  computed: {
    ...mapState({
      modalToggleProtectiveMeasuresRequesteds: (state) =>
        state.ProtectiveMeasuresRequesteds.modalToggleProtectiveMeasuresRequesteds,
      modalConfirmDeleteProtectiveMeasureRequested: (state) =>
        state.ProtectiveMeasuresRequesteds.modalConfirmDeleteProtectiveMeasureRequested,
      modalToggleEditProtectiveMeasuresRequesteds: (state) =>
        state.ProtectiveMeasuresRequesteds.modalToggleEditProtectiveMeasuresRequesteds,
      ProtectiveMeasuresRequesteds: (state) =>
        state.ProtectiveMeasuresRequesteds.filterProtectiveMeasuresRequesteds,

      protective_measure_number: (state) =>
        state.ProtectiveMeasuresRequesteds.params.protective_measure_number,
      incident_report_number: (state) => state.ProtectiveMeasuresRequesteds.params.incident_report_number,

      totalFilter: (state) => state.ProtectiveMeasuresRequesteds.totalFilter,
      totalPerPage: (state) => state.ProtectiveMeasuresRequesteds.totalPerPage,
      offsetFilter: (state) => state.ProtectiveMeasuresRequesteds.offsetFilter,
      pagesFilter: (state) => state.ProtectiveMeasuresRequesteds.pagesFilter,
      actualPageFilter: (state) => state.ProtectiveMeasuresRequesteds.actualPageFilter,
      totalFilterPages: (state) => state.ProtectiveMeasuresRequesteds.totalFilterPages,
    }),
  },
};
</script>
