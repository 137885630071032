import {
  getFacilities,
  getFacilitieById,
  createFacilitie,
  updateFacilitie,
  destroyFacilitie,
} from "../../service/FacilitiesApi";
import { getStates } from "../../service/StatesApi";
import { getCitiesByStateId } from "../../service/CityApi";
import { getNeighborhoodsByCityId } from "../../service/NeighborhoodsApi";
import { getCommunities } from "../../service/CommunitiesApi";
import { getSectorials } from "../../service/SectorialsApi";
import { getFacilitiesTypes } from "../../service/FacilitiesTypesApi";
import { getAccompanimentTypes } from "../../service/AccompanimentTypesApi";
import {
  createAccompanimentTypeFacility,
  destroyAccompanimentTypeFacility,
} from "../../service/AccompanimentTypeFacilitiesApi";
import Vue from "vue";

const state = {
  facilitie: {
    id: null,
    community_id: null,
    sectorial_id: 0,
    neighborhood_id: 0,
    facility_type_id: 0,
    name: "",
    street_name: "",
    street_number: "",
    address_complement: "",
    cep: "",
    telephone_01: "",
    telephone_02: "",
    whatsapp: "",
    email: "",
    business_hours: "",
    accompaniment_types: [],
  },
  states: [],
  cities: [],
  neighborhoods: [],
  stateFiltered: null,
  cityFiltered: null,
  stateFilteredToShow: null,
  cityFilteredToShow: null,
  community: null,
  sectorial: null,
  neighborhood: null,
  facility_type: null,
  accompaniment_type: null,
  accompaniment_type_facility: [],
  accompaniment_types_facilities: [],
  accompaniment_types_facilities_to_edit: [],
  accompaniment_types: [],
  facilities: [],
  communities: [],
  sectorials: [],
  facilities_types: [],
  filterFacilities: [],
  facilitieId: 0,
  modalToggleFacilities: false,
  modalConfirmDeleteFacilitie: false,
  modalViewFacilitie: false,
  params: {
    name: "",
    facility_type_name: "",
    order_by: "",
    limit: "",
    offset: "",
    page: "",
    paginate: true,
  },
  // - PARA PAGINACAO
  totalFilter: 0,
  totalPerPage: 10,
  offsetFilter: 0,
  totalFilterPages: 0,
  pagesFilter: [],
  actualPageFilter: 1,
};

const mutations = {
  // - PARA PAGINACAO --->
  setTotalFilterMutation: (state, total) => {
    state.totalFilter = total;
    let nrPages = total / state.totalPerPage;
    if (total % state.totalPerPage > 0) {
      nrPages = parseInt(nrPages) + 1;
    }
    state.totalFilterPages = nrPages;
    let offset = 0;
    state.pagesFilter = [];
    for (let n = 1; n <= nrPages; n++) {
      let pagina = {
        id: n - 1,
        name: "Página " + n + " de " + nrPages,
        number: n,
        offset: offset,
        page: n,
      };
      state.pagesFilter.push(pagina);
      offset += state.totalPerPage;
    }
  },
  setActualPageFilterMutation: (state, page) => {
    state.actualPageFilter = page;
  },
  setOffsetFilterMutation: (state, offset) => {
    state.offsetFilter = offset;
  },
  // <--- PARA PAGINACAO - FIM
  setFacilitiesMutation: (state, facilities) => {
    state.facilities = facilities;
    state.filterFacilities = facilities;
  },
  filterListByNameEquipment: (state, input) => {
    // let facilities = state.facilities;
    // state.filterFacilities = facilities.filter((obj) => {
    //   let name = obj.name.toLowerCase().indexOf(input.value.toLowerCase());
    //   return name > -1;
    // });
    state.params.name = input.value;
    // console.log("state.filters.name_facility", state.filters.name_facility);
  },
  filterListByTypeNameEquipment: (state, input) => {
    // let facilities = state.facilities;
    // state.filterFacilities = facilities.filter((obj) => {
    //   let name = obj.facility_type.name.toLowerCase().indexOf(input.value.toLowerCase());
    //   return name > -1;
    // });
    state.params.facility_type_name = input.value;
  },
  toggleModalFacilities: (state) => {
    state.modalToggleFacilities = !state.modalToggleFacilities;
  },
  toggleModalDeleteFacilitie: (state) => {
    state.modalConfirmDeleteFacilitie = !state.modalConfirmDeleteFacilitie;
  },
  toggleModalViewFacilitie: (state) => {
    state.modalViewFacilitie = !state.modalViewFacilitie;
  },
  facilitieForDeletion: (state, payload) => {
    state.facilitieId = payload.id;
  },
  setStatesMutation: (state, states) => {
    state.states = states;
  },
  setStateMutation: (state, stateFiltered) => {
    state.stateFiltered = stateFiltered;
  },
  setAccompanimentTypesMutation: (state, accompaniment_types) => {
    state.accompaniment_types = accompaniment_types;
  },
  setAccompanimentTypesFacilitiesMutation: (state, accompaniment_types_facilities) => {
    state.accompaniment_types_facilities = accompaniment_types_facilities;
    //console.log("setAccompanimentTypesFacilitiesMutation", state.accompaniment_types_facilities);
  },
  setCitiesMutation: (state, cities) => {
    state.cities = cities;
  },
  setCityMutation: (state, cityFiltered) => {
    state.cityFiltered = cityFiltered;
  },
  setCityFilteredToShowMutation: (state, cityFilteredToShow) => {
    state.cityFilteredToShow = cityFilteredToShow;
  },
  setStateFilteredToShowMutation: (state, stateFilteredToShow) => {
    state.stateFilteredToShow = stateFilteredToShow;
  },
  setNeighborhoodsMutation: (state, neighborhoods) => {
    state.neighborhoods = neighborhoods;
  },
  setCommunitiesMutation: (state, communities) => {
    state.communities = communities;
  },
  setSectorialsMutation: (state, sectorials) => {
    state.sectorials = sectorials;
  },
  setFacilitiesTypesMutation: (state, facilities_types) => {
    state.facilities_types = facilities_types;
  },
  setIdMutation: (state, id) => {
    state.facilitie.id = id;
  },
  setNameMutation: (state, name) => {
    state.facilitie.name = name;
  },
  setStreetNameMutation: (state, street_name) => {
    state.facilitie.street_name = street_name;
  },
  setStreetNumberMutation: (state, street_number) => {
    state.facilitie.street_number = street_number;
  },
  setAddressComplementMutation: (state, address_complement) => {
    state.facilitie.address_complement = address_complement;
  },
  setCepMutation: (state, cep) => {
    state.facilitie.cep = cep;
  },
  setTelephone01Mutation: (state, telephone_01) => {
    state.facilitie.telephone_01 = telephone_01;
  },
  setTelephone02Mutation: (state, telephone_02) => {
    state.facilitie.telephone_02 = telephone_02;
  },
  setWhatsappMutation: (state, whatsapp) => {
    state.facilitie.whatsapp = whatsapp;
  },
  setEmailMutation: (state, email) => {
    state.facilitie.email = email;
  },
  setBusinessHoursMutation: (state, business_hours) => {
    state.facilitie.business_hours = business_hours;
  },
  setCommunityMutation: (state, community) => {
    if (community) {
      state.facilitie.community_id = community.id;
    }
    state.community = community;
  },
  setSectorialMutation: (state, sectorial) => {
    if (sectorial) {
      state.facilitie.sectorial_id = sectorial.id;
    }
    state.sectorial = sectorial;
  },
  setNeighborhoodMutation: (state, neighborhood) => {
    if (neighborhood != null) {
      state.facilitie.neighborhood_id = neighborhood.id;
      state.neighborhood = neighborhood;
    } else {
      state.facilitie.neighborhood_id = 0;
      state.neighborhood = null;
    }
  },
  setFacilityTypeMutation: (state, facility_type) => {
    if (facility_type) {
      state.facilitie.facility_type_id = facility_type.id;
    }
    state.facility_type = facility_type;
  },
  setAccompanimentTypeFacilityToEditMutation: (state, payload) => {
    state.accompaniment_types_facilities_to_edit.push(payload);
  },
  setAccompanimentTypeFacilityArrayMutation: (state, accompaniment_type_facility) => {
    state.accompaniment_type_facility = accompaniment_type_facility;
  },

  clearQuery: (state) => {
    state.facilities = [];
    state.filterFacilities = [];
    state.params.name = "";
    state.params.facility_type_name = "";

    state.params.order_by = "";
    state.params.limit = "";
    state.params.offset = "";

    state.params.page = "";

    state.totalFilter = 0;
    state.totalPerPage = 10;
    state.offsetFilter = 0;
    state.totalFilterPages = 0;
    state.pagesFilter = [];
    state.actualPageFilter = 1;
  },
  clearFields: (state) => {
    state.facilitie.id = null;
    state.community = null;
    state.sectorial = null;
    state.neighborhood = null;
    state.facility_type = null;
    state.violence_motivation = null;
    state.facilitie.name = "";
    state.stateFiltered = null;
    state.cityFiltered = null;
    state.accompaniment_types_facilities = [];
    state.accompaniment_type_facility = [];
    state.accompaniment_types_facilities_to_edit = [];
    state.facilitie.street_name = "";
    state.facilitie.street_number = "";
    state.facilitie.address_complement = "";
    state.facilitie.cep = "";
    state.facilitie.telephone_01 = "";
    state.facilitie.telephone_02 = "";
    state.facilitie.whatsapp = "";
    state.facilitie.email = "";
    state.facilitie.business_hours = "";
  },
};

const actions = {
  // --- PAGINAÇÃO >>>>>>>>>
  getPageFilter: ({ state, commit }, payload) => {
    if (payload != null) {
      const params = {
        name: state.params.name,
        facility_type_name: state.params.facility_type_name,
        order_by: "name ASC",
        limit: state.totalPerPage,
        offset: payload.offset,
        page: payload.page,
        paginate: true,
      };
      getFacilities(params)
        .then((response) => {
          // console.log(response.data.paginate);
          commit("setFacilitiesMutation", response.data.facilities);
          commit("setTotalFilterMutation", response.data.paginate.total_count);
          commit("setActualPageFilterMutation", payload.number);
        })
        .catch((e) => {
          console.log("error", e.response); //TODO: tratar esse error
        });
    }
  },
  setOffsetFilter: ({ commit }, payload) => {
    commit("setOffsetFilterMutation", payload);
  },
  // --- PAGINAÇÃO <<<<<<<<<
  loadFacilities: ({ commit }, from) => {
    // const filters = {
    //   name_facility: state.filters.name_facility,
    //   type_facility: state.filters.type_facility,
    // };
    commit("setOffsetFilterMutation", 0);
    const params = {
      name: state.params.name,
      facility_type_name: state.params.facility_type_name,
      order_by: "name ASC",
      limit: state.totalPerPage,
      offset: state.offsetFilter,
      paginate: true,
    };
    getFacilities(params)
      .then((response) => {
        commit("setFacilitiesMutation", response.data.facilities);
        commit("setTotalFilterMutation", response.data.paginate.total_count);
        commit("setActualPageFilterMutation", 1);
        if (parseInt(response.data.total, 10) < 1 && from != "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadCommunities: ({ commit }) => {
    getCommunities()
      .then((response) => {
        // console.log("lloadCommunities", response);
        commit("setCommunitiesMutation", response.data.communities);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadSectorials: ({ commit }) => {
    getSectorials()
      .then((response) => {
        // console.log("loadSectorials", response);
        commit("setSectorialsMutation", response.data.sectorials);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadFacilitiesTypes: ({ commit }) => {
    getFacilitiesTypes()
      .then((response) => {
        // console.log("loadFacilitiesTypes", response);
        commit("setFacilitiesTypesMutation", response.data.facility_types);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadStates: ({ commit }) => {
    getStates()
      .then((resp) => {
        // console.log("Stados", resp);
        commit("setStatesMutation", resp.data.states);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadCities: ({ commit, dispatch }, city_id) => {
    // console.log("city_id", city_id);
    getCitiesByStateId(city_id)
      .then((response) => {
        // console.log("cities", response);
        dispatch("setCity", null);
        dispatch("setNeighborhood", null);
        commit("setCitiesMutation", response.data.cities);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadNeighborhoods: ({ commit, dispatch }, payload) => {
    let id = payload;
    getNeighborhoodsByCityId(id)
      .then((response) => {
        // console.log("neghborhoods", response);
        dispatch("setNeighborhood", null);
        commit("setNeighborhoodsMutation", response.data.neighborhoods);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadAccompanimentTypes: ({ commit }) => {
    getAccompanimentTypes()
      .then((response) => {
        //console.log("getAccompanimentTypeFacilities", response);
        commit("setAccompanimentTypesMutation", response.data.accompaniment_types);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  setCity: ({ commit, dispatch }, payload) => {
    commit("setCityMutation", payload);
    if (payload != null) {
      dispatch("loadNeighborhoods", payload.id);
    }
  },
  setState: ({ commit, dispatch }, payload) => {
    commit("setStateMutation", payload);
    dispatch("loadCities", payload.id);
  },
  loadData: async ({ commit }, payload) => {
    await getFacilitieById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.facility.id);
        commit("setNameMutation", response.data.facility.name);
        commit("setStreetNameMutation", response.data.facility.street_name);
        commit("setStreetNumberMutation", response.data.facility.street_number);
        commit("setAddressComplementMutation", response.data.facility.address_complement);
        commit("setCepMutation", response.data.facility.cep);
        commit("setCityMutation", response.data.facility.city);
        commit("setStateMutation", response.data.facility.state);
        commit("setTelephone01Mutation", response.data.facility.telephone_01);
        commit("setTelephone02Mutation", response.data.facility.telephone_02);
        commit("setWhatsappMutation", response.data.facility.whatsapp);
        commit("setEmailMutation", response.data.facility.email);
        commit("setBusinessHoursMutation", response.data.facility.business_hours);
        if (response.data.facility.community) {
          commit("setCommunityMutation", response.data.facility.community);
        }
        commit("setSectorialMutation", response.data.facility.sectorial);
        commit("setNeighborhoodMutation", response.data.facility.neighborhood);
        commit("setFacilityTypeMutation", response.data.facility.facility_type);
        commit("setAccompanimentTypesFacilitiesMutation", response.data.facility.accompaniment_types);
        commit("setAccompanimentTypeFacilityArrayMutation", response.data.facility.accompaniment_types);
        if (response.data.facility.accompaniment_type_facilities.length > 0) {
          response.data.facility.accompaniment_type_facilities.forEach((item) => {
            commit("setAccompanimentTypeFacilityToEditMutation", item);
          });
        }
      })
      .catch((e) => {
        console.log("e", e);
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  filterListByNameEquipment: ({ commit }, payload) => {
    commit("filterListByNameEquipment", payload);
  },
  filterListByTypeNameEquipment: ({ commit }, payload) => {
    commit("filterListByTypeNameEquipment", payload);
  },
  toggleModalFacilities: ({ commit }) => {
    commit("toggleModalFacilities");
  },
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  openEditModal: ({ commit, dispatch }, payload) => {
    dispatch("loadData", payload);
    commit("toggleModalFacilities");
  },
  closeEditModal: ({ commit }) => {
    commit("toggleModalFacilities");
    commit("clearFields");
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await getFacilitieById(payload.id)
      .then((response) => {
        dispatch("loadData", response.data.facility);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
    commit("toggleModalViewFacilitie");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewFacilitie");
    commit("clearFields");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalFacilities");
    commit("clearFields");
  },
  toggleModalDeleteFacilitie: ({ commit }, payload) => {
    commit("toggleModalDeleteFacilitie");
    if (state.modalConfirmDeleteFacilitie) {
      commit("facilitieForDeletion", payload);
    } else {
      commit("facilitieForDeletion", null);
    }
  },
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setStreetName: ({ commit }, payload) => {
    commit("setStreetNameMutation", payload.value);
  },
  setStreetNumber: ({ commit }, payload) => {
    commit("setStreetNumberMutation", payload.value);
  },
  setAddressComplement: ({ commit }, payload) => {
    commit("setAddressComplementMutation", payload.value);
  },
  setCep: ({ commit }, payload) => {
    commit("setCepMutation", payload.value);
  },
  setTelephone01: ({ commit }, payload) => {
    commit("setTelephone01Mutation", payload.value);
  },
  setTelephone02: ({ commit }, payload) => {
    commit("setTelephone02Mutation", payload.value);
  },
  setWhatsapp: ({ commit }, payload) => {
    commit("setWhatsappMutation", payload.value);
  },
  setEmail: ({ commit }, payload) => {
    commit("setEmailMutation", payload.value);
  },
  setBusinessHours: ({ commit }, payload) => {
    commit("setBusinessHoursMutation", payload.value);
  },
  setCommunity: ({ commit }, payload) => {
    commit("setCommunityMutation", payload);
  },
  setSectorial: ({ commit }, payload) => {
    commit("setSectorialMutation", payload);
  },
  setNeighborhood: ({ commit }, payload) => {
    commit("setNeighborhoodMutation", payload);
  },
  setFacilityType: ({ commit }, payload) => {
    commit("setFacilityTypeMutation", payload);
  },
  setAccompanimentTypeFacilityArray: ({ commit }, payload) => {
    commit("setAccompanimentTypeFacilityArrayMutation", payload);
  },
  updateAccompanimentTypeFacility: ({ commit }, payload) => {
    let accompaniment_types_facilities_to_edit = [];
    payload.accompanimentTypesArrayToEdit.forEach((item) => {
      let array = {
        id: item.id,
        accompaniment_type_id: item.accompaniment_type_id,
        facility_id: item.facility_id,
      };
      accompaniment_types_facilities_to_edit.push(array);
    });

    let accompaniment_type_facility = [];
    payload.accompanimentTypes.forEach((item) => {
      let array = {
        accompaniment_type_id: item.id,
        facility_id: payload.facilityId,
      };
      accompaniment_type_facility.push(array);
    });

    const objCreate = accompaniment_type_facility.filter(
      (edit) =>
        !accompaniment_types_facilities_to_edit.find(
          (accomp) => accomp.accompaniment_type_id === edit.accompaniment_type_id
        )
    );

    const objDestroy = accompaniment_types_facilities_to_edit.filter(
      (edit) =>
        !accompaniment_type_facility.find(
          (accomp) => accomp.accompaniment_type_id === edit.accompaniment_type_id
        )
    );

    if (objCreate.length > 0) {
      let objToSend = [];
      objCreate.forEach((item) => {
        let array = {
          accompaniment_type_id: item.accompaniment_type_id,
          facility_id: item.facility_id,
        };
        objToSend.push(array);
      });
      createAccompanimentTypeFacility(objToSend)
        .then(() => {})
        .catch((e) => {
          console.log(e);
        })
        .catch((e) => {
          Vue.$toast.error(e.response.data.error + "!");
        });
    }

    if (objDestroy.length > 0) {
      objDestroy.forEach((item) => {
        destroyAccompanimentTypeFacility(item.id)
          .then(() => {})
          .catch((e) => {
            console.log(e);
          })
          .catch((e) => {
            Vue.$toast.error(e.response.data.error + "!");
          });
      });
    }
    commit("toggleModalFacilities");
  },
  save: ({ commit, dispatch }) => {
    createFacilitie(state.facilitie)
      .then((response) => {
        let accompaniment_type_facility = [];
        state.accompaniment_type_facility.forEach((item) => {
          let array = {
            accompaniment_type_id: item.id,
            facility_id: response.data.id,
          };
          accompaniment_type_facility.push(array);
        });
        createAccompanimentTypeFacility(accompaniment_type_facility)
          .then(() => {
            Vue.$toast.success("Equipamento cadastrado com sucesso!");
            commit("toggleModalFacilities");
            commit("clearFields");
            dispatch("Facilities/loadFacilities", null, { root: true });
            dispatch("AccompanimentTypeFacilities/loadAccompanimentTypeFacilities", null, { root: true });
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar o equipamento! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );
        console.log(e);
      });
  },
  update: ({ dispatch, commit }) => {
    updateFacilitie(state.facilitie.id, state.facilitie)
      .then(() => {
        let payload = {
          accompanimentTypesArrayToEdit: state.accompaniment_types_facilities_to_edit,
          accompanimentTypes: state.accompaniment_type_facility,
          facilityId: state.facilitie.id,
        };
        dispatch("updateAccompanimentTypeFacility", payload);

        Vue.$toast.success("Equipamento atualizado com sucesso!");
        dispatch("Facilities/loadFacilities", null, { root: true });
        commit("clearFields");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyFacilitie(state.facilitieId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Equipamento deletado com sucesso!");
        commit("toggleModalDeleteFacilitie");
        dispatch("Facilities/loadFacilities", from, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
